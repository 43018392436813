import Box from "@mui/material/Box";
import makeStyles from '@mui/styles/makeStyles';
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";

const useStyles = makeStyles({
    cubeContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    cube: {
        width: 20,
        height: 20,
        borderRadius: 4,
        fontSize: '12px',
        fontWeight: 600,
        cursor: 'pointer',
        transition: 'backgroundColor 2s ease-in-out'
    }
});

export default function Answers({
    index, answers = [], questions = [], onAnswerClick, ...props
}) {
    const classes = useStyles();
    const currentQuestion = useStore((state) => state.currentQuestion);

    const handleClick = (index) => onAnswerClick(index);

    return (
        <Box className={classes.cubeContainer} {...props}>
            <Box display='flex' flexWrap='wrap' gap={1}>
                {questions?.map((question, index) => {
                    const answer = answers?.[index];
                    const score = answer?.analysis?.score;
                    const correct = (answer && score && score !== 0);
                    const active = currentQuestion === index;
                    const color = correct ? '#41D7A1' : '#E98862';
                    return (
                        <CenterFlexBox
                            key={index}
                            className={classes.cube}
                            color={active ? '#ffffff' : color}
                            border={`1.5px solid ${color}`}
                            backgroundColor={active ? color : 'transparent'}
                            onClick={() => handleClick(index)}
                        >
                            {index + 1 || -1}
                        </CenterFlexBox>
                    );
                })}
            </Box>
        </Box>
    )
}