import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: 8, borderRadius: 4, margin: "3px 0",
    boxShadow: "0 0px 1px 1px #f2f2f2", width: "100%"
  },
  selectedOption: {
    color: 'white', background: '#02569D', boxShadow: "0 0px 2px 1px #02569Ddd",
  }
}));

function SCQ({ active, currentQuestion, question, index, ...props }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState(-1);

  React.useEffect(() => {
    const v = question?.answer?.value;
    setSelected(v === undefined ? -1 : v);
  }, [currentQuestion]);

  const handleChange = (e, v) => {
    setSelected(+v);
    question.answer.value = +v;
  };

  return active ? (
    <Box width="100%" {...props} maxHeight={'45vh'} overflow="auto">
      <Box display="flex">
        <Typography mt={1} fontSize={14} fontWeight={700} pl={1}>
          {index + 1}.
        </Typography>
        <Typography mt={1} fontSize={14} fontWeight={700} px={1}
          dangerouslySetInnerHTML={{
            __html: (question?.content || question?.question)
          }}
        />
      </Box>
      <RadioGroup
        name="radio-buttons-group"
        sx={{ px: 1, mt: 1 }}
        onChange={handleChange}
        value={selected}
      >
        {question?.options?.map((option, i) => (
          <FormControlLabel
            key={i}
            value={i}
            control={<Radio color="white" size="small" />}
            componentsProps={{ typography: { fontSize: 12 } }}
            label={option}
            classes={{
              label: classes.formControlLabel,
              root: classes.formControl
            }}
            className={selected === i ? classes.selectedOption : ''}
          />
        ))}
      </RadioGroup>
    </Box >
  ) : null;
}

export default SCQ;