import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import { Table } from "components/Table";
import Title from "../Title";

const Glossaries = [
    {
        word: 'Dylexic Ability',
        meaning: 'The ability to produce and understand the words of a language. Lexical competence is an aspect of both linguistic competence and communicative competence.'
    },
    {
        word: 'Conciseness',
        meaning: 'The quality of being short and clear, and expressing what needs to be said without unnecessary words'
    },
    {
        word: 'Punctuation',
        meaning: 'In simple terms, punctuation marks are a symbol to create and support meaning within a sentence or to break it up.'
    },
    {
        word: 'Grammar',
        meaning: 'the whole system and structure of a language or of languages in general, usually taken as consisting of syntax and morphology (including inflections) and sometimes also phonology and semantics.'
    },
    {
        word: 'Vocablary',
        meaning: 'Vocabulary is the all the language and words either used or understood by a person or group of people.'
    },
    {
        word: 'Coherence',
        meaning: 'The definition of coherence is something logical or consistent and something that makes sense as a whole.'
    },
];

const sectionScaleCols = ['Skill', 'Score(0-30)', 'Proficiency Level'];

const cefrLevelCols = ['CEFR Level', 'TOEFL Score'];

const sectionScaleRows = [
    {
        skill: 'Reading',
        score: <Box> 0-2<br />4-17<br />18-23<br />24-30</Box>,
        level: <Box>Below Low-Intermediate<br />Low-Intermediate<br />High-Intermediate<br />Advanced</Box>
    },
    {
        skill: 'Listening',
        score: <Box>0-8<br />9-16<br />17-21<br />22-30</Box>,
        level: <Box>Below Low-Intermediate<br />Low-Intermediate<br />High-Intermediate<br />Advanced</Box>
    },
    {
        skill: 'Writing',
        score: <Box>0-11<br />12-16<br />17-23<br />24-30</Box>,
        level: <Box>Below Low-Intermediate<br />Low-Intermediate<br />High-Intermediate<br />Advanced</Box>
    },
    {
        skill: 'Speaking',
        score: <Box>0-15<br />16-19<br />20-24<br />25-30</Box>,
        level: <Box>Below Low-Intermediate<br />Low-Intermediate<br />High-Intermediate<br />Advanced</Box>
    },
];

const cefrLevelRows = [
    {
        level: 'C2',
        score: '115-120'
    },
    {
        level: 'C1',
        score: '94-114'
    },
    {
        level: 'B2',
        score: '46-93'
    },
    {
        level: 'B1',
        score: '32-45'
    },
    {
        level: 'A1-B1',
        score: '0-31'
    },
];

function Glossary() {
    return (
        <Box >
            <Box
                backgroundColor='#02569D'
                color='#ffffff'
                borderRadius='6px'
                py={1.5} pl={2}
                fontFamily='inter'
                fontSize='16px'
                fontWeight={600}
            >
                Report Glossary
            </Box>
            {
                Glossaries.map((glossary, index) => (
                    <Box key={index}>
                        <CenterFlexBox
                            justifyContent='space-between'
                            alignItems='flex-start'
                            my={4}
                        >
                            <Typography
                                fontFamily='inter'
                                fontSize='16px' ml={2}
                                fontWeight={500} width='40%'
                            >
                                {glossary.word}
                            </Typography>
                            <Typography
                                fontFamily='inter'
                                fontSize='15px'
                                fontWeight={400}
                                color='rgba(0, 0, 0, 0.6)' width='60%'
                            >
                                {glossary.meaning}
                            </Typography>
                        </CenterFlexBox>
                        <Divider variant="fullWidth" />
                    </Box>
                ))
            }
        </Box>
    )
}

function CEFRLevelTable() {

    return (
        <>
            <Table columns={cefrLevelCols} rows={cefrLevelRows} />
        </>
    );
}

function TOEFLLevelTable() {

    return (
        <>
            <Table columns={sectionScaleCols} rows={sectionScaleRows} />
        </>
    );
}

export default function Descriptors({ active }) {

    return (
        (active) ? (
            <>
                <Title title='How we provides scores!!' />
                <br />
                <TOEFLLevelTable />
                <br /> <br />
                <CEFRLevelTable />
                <br /> <br />
                <Glossary />
            </>
        ) : null
    );
}