import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import makeStyles from '@mui/styles/makeStyles';
import classNames from "classnames";

const useStyles = makeStyles({
  infoIcon: {
    fontSize: '2.5vmax',
  },
  infoMessageRoot: {
    boxShadow: "0px 8px 16px rgba(143,23,1,0.1)",
    borderRadius: "8px",
    padding: 8,
    color: "green"
  },
  infoVariant: {
    background: "#F2FAFF",
    boxShadow: "0px 8px 16px rgba(1,64,128,0.1)",
    color: "#2DABFF",
  },
  successVariant: {
    background: "#EDFFF7",
    boxShadow: "0px 8px 16px rgba(0,130,91,0.1)",
    color: "#35E9A8",
  },
  errorVariant: {
    backgroundColor: "#FFF0F0",
    boxShadow: "0px 8px 16px rgba(143, 23, 1, 0.1)",
    color: "#F55446",
  },
});

const InfoMessage = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const { children, variant = 'info' } = props;

  return (
    <Box
      ref={ref}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classNames(
        classes.infoMessageRoot,
        variant === 'info' ? classes.infoVariant : {},
        variant === 'error' ? classes.errorVariant : {},
        variant === 'success' ? classes.successVariant : {},
      )}
      p={2}
      width="fit-content"
    >
      <ErrorOutlineIcon
        color="inherit"
        fontSize="small"
        className={classes.infoIcon}
      />
      <Typography
        variant="body2"
        component="div"
        color={"inherit"}
        fontWeight="bold"
        p={1}
      >
        {children}
      </Typography>
    </Box>
  );
});

export default InfoMessage;