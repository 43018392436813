import React from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  React.useEffect(() => {
    window.scroll({ top: 0, behavior: 'auto' });
  }, [location]);
  return (<>{children}</>);
};

export default ScrollToTop;