import React from 'react';
import AutoComplete from 'components/AutoComplete';
import { createFilterOptions } from '@mui/material/Autocomplete';
import countries from 'assets/countries';

const filterOptions = createFilterOptions({
  matchFrom: 'any', trim: true, stringify: (option) => option.label,
});

function CountryCodeSelect({ onSelected }) {
  const [value, setValue] = React.useState({
    "id": "in", "code": "91", "label": "+91 (India)"
  });

  const onSelect = (e, option) => {
    setValue(option);
    if (onSelected) onSelected(option.code);
  }

  return (
    <AutoComplete
      id="countryCode"
      name="countryCode"
      disableClearable
      filterOptions={filterOptions}
      componentsProps={{ paper: { sx: { width: 280 } } }}
      style={{ width: 40 }}
      containerProps={{ width: 40 }}
      getOptionLabel={(option) => `+${option.code}`}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      value={value}
      options={countries}
      onSelect={onSelect}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
}

export default CountryCodeSelect;