import Session from "../utils/Session";
import Client from "./_client";

export const restoreSession = async () => {
  const result = await new Client({
    path: "/auth/restore"
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to restore you session. Please sign in.');
  } else {
    if (!result.data.user.email) result.data.guest = true;
    Session.onRestoreSession(result.data);
    return result;
  }
};

export const verifyToken = async (token) => {
  const result = await new Client({
    path: "/signin/verify-token",
    payload: { token }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}

export const signUp = async ({ name, email, city, guid, attemptId }) => {
  const result = await new Client({
    path: "/signup?withPhone=true",
    payload: {
      name, email, city, guid, attemptId,
      guestId: localStorage.getItem("guestId"),
      referralCode: localStorage.getItem('rc') || "",
    }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    if (result?.data?.verified) {
      Session.onCreateSession(result.data);
    }

    return result?.data;
  }
}

export const signIn = async ({ googleId, contact, attemptId }) => {
  const result = await new Client({
    path: "/signin?withPhone=true",
    payload: { contact, googleId, role: "MOCK_USER", attemptId }
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to log you in.');
  } else {
    Session.onCreateSession(result.data);
    return result;
  }
}

export const signOut = async () => {
  Session.clearSession();
};

export const createGuestSession = async () => {
  let url = "/auth/guest-session?";

  const guestId = localStorage.getItem("guestId");

  if (guestId) url += `userId=${guestId}`;

  const result = await new Client({ path: url }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(result.message || 'Uh Oh! Unable to get guest session. Please try again!');
  } else {
    result.data.user = { _id: result.data.userId };

    result.data.guest = true;

    Session.onCreateSession(result.data);

    return result;
  }
};

export const isContactRegistered = async (contact) => {
  const result = await new Client({
    path: `/auth/is-contact-registered?contact=${encodeURIComponent(contact)}`
  }).get();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || 'Uh Oh! Something went wrong please try again.'
    );
  }

  return result?.data?.isRegistered;
}
