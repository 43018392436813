import Box from "@mui/material/Box";

export function Title({ title, id }) {
  return (
    <Box
      backgroundColor='#D7E5FF'
      color='#02569D'
      borderRadius='6px'
      py={2}
      pl={2}
      fontFamily='inter'
      fontSize='18px'
      fontWeight={600}
      id={id}
    >
      {title}
    </Box>
  );
}

export default Title;