import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: 8, borderRadius: 4, margin: "3px 0",
    boxShadow: "0 0px 1px 1px #f2f2f2", width: "100%"
  },
  selectedOption: {
    color: 'white', background: '#02569D', boxShadow: "0 0px 2px 1px #02569Ddd",
  }
}));

function MCQ({ active, currentQuestion, question, ...props }) {
  const classes = useStyles();

  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    const values = question?.answer?.value;
    setSelected(values === undefined ? [] : values);
  }, [currentQuestion]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    const value = +e.target.value;

    if (checked) setSelected((s) => {
      question.answer.value = [...s, value];

      return question.answer.value;
    });
    else setSelected((s) => {
      question.answer.value = s.filter(v => v !== value);

      return question.answer.value;
    });
  };

  return active ? (
    <Box {...props} maxHeight={'45vh'} overflow="auto" pr={1}>
      <Box display="flex">
        <Typography mt={1} fontSize={14} fontWeight={700} pl={1}>
          {currentQuestion + 1}.
        </Typography>
        <Typography
          mt={1}
          fontSize={14}
          fontWeight={700}
          px={1}
          dangerouslySetInnerHTML={{
            __html: (question?.question || question?.content)
          }}
        />
      </Box>
      {question?.options?.map((option, i) => (
        <FormControlLabel
          key={i}
          value={i.toString()}
          onChange={handleChange}
          control={<Checkbox
            checked={selected?.includes(i)} color="white" size="small"
          />}
          componentsProps={{ typography: { fontSize: 12 } }}
          label={option}
          classes={{
            label: classes.formControlLabel, root: classes.formControl
          }}
          className={selected?.includes(i) ? classes.selectedOption : ''}
        />
      ))}
    </Box >
  ) : null;
}

export default MCQ;