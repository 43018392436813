import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/HelpCenter';
import CheckIcon from '@mui/icons-material/CheckBoxOutlined';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from 'components/Button';
import InstructionsDialog from 'dialogs/IELTSInstructions';
import useStore from 'utils/ieltsStore';
import EndTestDialog from 'dialogs/EndTest';
import PauseTestDialog from 'dialogs/PauseTest';
import Session from 'utils/Session';
import { finishMockAttempt } from "services";
import { endTest } from 'screens/IELTSMock';
import { exitFullScreen } from 'utils';

function SectionHeader({
  hideFinish, disableFinish, disableEnd, children
}) {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [endTestDialog, setEndTestDialog] = React.useState(false);;

  const attempt = useStore((state) => state.attempt);
  const playing = useStore((state) => state.playing);
  const [reset, openDialog, resume, pause] = useStore((state) => [
    state.reset, state.openSubmitSectionDialog, state.resume, state.pause
  ], shallow);

  const [searchParams,] = useSearchParams();

  const handleSendReport = async () => {
    exitFullScreen();
    if (Session.isLoggedIn()) {
      try {
        await finishMockAttempt(attempt, false);
      } catch (error) { console.error(error) }

      endTest(navigate);
    } else {
      navigate(`/register?aid=${attempt}`, { replace: true });
    }

    reset();
  };

  const handleExitTest = async () => {
    exitFullScreen();
    setEndTestDialog(false);

    if (Session.isLoggedIn()) endTest(navigate);
    else navigate(`/`, { replace: true });

    reset();
  };

  return (
    <Box
      display="flex" justifyContent="space-between" alignItems="center" px={6}
      py={2} bgcolor="primary.darkest" boxShadow="0px -2px 8px 2px black"
      position="sticky" top={0}
    >
      <Box flexGrow={1}>
        {(!searchParams.get('api')) && <Button
          disabled={disableEnd}
          variant="contained" style={{ padding: "8px 16px" }}
          onClick={() => setEndTestDialog(true)}
          sx={{ backgroundColor: "primary.dark" }}
          startIcon={<LeftIcon color="inherit" />}
        >
          End Test
        </Button>}
        {(!searchParams.get('api')) && <Button
          disabled={disableEnd}
          variant="contained" style={{ padding: "8px 16px" }}
          onClick={pause}
          sx={{ backgroundColor: "primary.dark" }}
        >
          Pause
        </Button>}
      </Box>
      {children}
      <Box flexGrow={1} display="flex" justifyContent="end" alignItems="center">
        {!hideFinish && (
          <Button
            disabled={disableFinish}
            variant="contained" style={{ padding: "8px 16px" }}
            onClick={openDialog}
            sx={{ backgroundColor: "primary.dark" }}
            endIcon={<CheckIcon color="inherit" />}
          >
            Finish Section
          </Button>
        )}
        <IconButton title="Instructions" onClick={() => setOpen(true)}>
          <InfoIcon color="white" />
        </IconButton>
        <InstructionsDialog open={open} onClose={() => setOpen(false)} />
        <EndTestDialog
          open={endTestDialog}
          onSaveAndGetReport={handleSendReport}
          onExitTest={handleExitTest}
        />
        <PauseTestDialog
          open={!playing}
          onConfirm={handleSendReport}
          onCancel={resume}
        />
      </Box>
    </Box>
  );
}

export default SectionHeader;