import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CenterFlexBox from "components/CenterFlexBox";

import { getCefrGradeByScore } from "utils";

export default function OverallPerformance({ active, score }) {

    return (
        active ? (
            <>
                <Box display='flex' justifyContent='space-between' my={2}>
                    <Box width='30%' >
                        <CenterFlexBox flexDirection='column'>
                            <CenterFlexBox
                                width='90%' backgroundColor='#D7E5FF'
                                borderRadius='4px' py={1}
                                fontSize='16px' fontWeight={600}
                                fontFamily='inter'
                            >
                                Total Score &nbsp;
                                <span style={{ color: '#02569D' }}>
                                    {score | 0}
                                </span>
                            </CenterFlexBox>
                            <Typography mt={1} fontSize='14px'
                                fontWeight={500} fontFamily='inter'
                            >
                                out of 120
                            </Typography>
                        </CenterFlexBox> <br />
                    </Box>

                    <Divider orientation="vertical" flexItem />

                    <Box width='60%'>
                        <Typography
                            fontSize='15px' fontWeight={500}
                            fontFamily='inter' mb={1}
                        >
                            Expected CEFR Level: &nbsp;
                            <span style={{ color: '#02569D' }}>
                                {getCefrGradeByScore(score)?.grade}
                            </span>
                        </Typography>
                        <Typography
                            fontSize='12px' fontWeight={500}
                            fontFamily='inter' color='rgba(0, 0, 0, 0.6)'
                        >
                            {getCefrGradeByScore(score)?.description}
                        </Typography>
                        <br />
                    </Box>
                </Box>
            </>
        ) : null
    );
}