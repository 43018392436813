import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { DarkTooltip, WarningTooltip } from 'components/Tooltip';

export default function LargeIconButton({
    children, showProgress, showTooltip, progress, allottedTime, isPercentage,
    ...props
}) {
    const value = Math.round((progress / allottedTime) * 100);

    let tip;

    if (isPercentage) {
        tip = `${progress}%`;
    } else {
        const min = Math.floor(progress / 60);
        const sec = progress % 60;
        tip = progress >= 60 ? `${min}m ${sec}s` : `${sec}s`;
    }

    const warningState = progress < 10;

    const Tooltip = React.useMemo(
        () => warningState ? WarningTooltip : DarkTooltip, [warningState]
    );

    return (
        <Tooltip
            arrow title={tip} open={!!showTooltip} placement="right"
        >
            <Box sx={{ position: "relative", display: "inline-flex" }}>
                {!!showProgress && (
                    <CircularProgress
                        variant={progress >= 0 ? "determinate" : "indeterminate"}
                        size={72} thickness={2} value={value}
                        color={warningState ? "errorLight" : "primary"}
                    />
                )}
                <Box
                    sx={{
                        top: 0, left: 0, bottom: 0, right: 0, width: 60, margin: '6px',
                        background: '#2355982E', borderRadius: '50%', position: "absolute",
                        display: "flex", alignItems: "center", justifyContent: "center",
                        fontSize: 12, textAlign: 'center', color: "#235598",
                        fontWeight: 500, lineHeight: '12px',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </Tooltip>
    );
}