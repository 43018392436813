import Box from "@mui/material/Box";
import CenterFlexBox from "components/CenterFlexBox";
import CircleProgressbar from "components/Report/CircleProgressbar";
import { getToeflLevelByScore } from "utils";
import Title from "../Title";

export default function AllSectionScores({ active, scores }) {

    return (
        (active) ? (
            <Box>
                <Title title={'Section wise score (out of 30) :'} />
                <Box mb={2}>
                    <Box display='flex' gap={4} my={4}>
                        {
                            Object.keys(scores).map((key, index) => (
                                (key !== 'overall') &&
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    fontSize="12px"
                                    key={index}
                                >
                                    <CenterFlexBox
                                        mb={2} backgroundColor='#D7E5FF'
                                        px={2} py={0.5} borderRadius={1}
                                        color='#235598' fontFamily='inter'
                                        fontSize={16} fontWeight={600}
                                    >
                                        {key.charAt(0).toUpperCase() + key.substring(1)}
                                    </CenterFlexBox>
                                    {
                                        (scores?.[key]?.score >= 0) &&
                                        <CircleProgressbar
                                            value={scores?.[key]?.score}
                                            size={90}
                                            percent={false}
                                            color={
                                                getToeflLevelByScore(
                                                    scores?.[key]?.score,
                                                    key.toUpperCase()
                                                )?.color
                                            }
                                        />
                                    }
                                </Box>
                            ))
                        }
                    </Box>
                </Box>
            </Box>
        ) : null
    );
}