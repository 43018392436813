import { Box, Typography } from "@mui/material";
import CenterFlexBox from "components/CenterFlexBox";
import { ToeflLevels } from "./constants";

export default function LevelIndicator({ active, ...props }) {

    return (
        (active) ? <>
            <CenterFlexBox
                border={'1px solid #02569D'}
                justifyContent='space-evenly'
                py={1} borderRadius={1} {...props}
            >
                {
                    Object.values(ToeflLevels).map((value) => {
                        return (value.id !== "DEFAULT") ?
                            <> <CenterFlexBox>
                                <Box
                                    width={12} height={12} mr={1}
                                    borderRadius={12} backgroundColor={value.color}
                                >
                                </Box>
                                <Typography
                                    fontFamily='inter' fontWeight={500}
                                    fontSize={12}
                                >
                                    {value.label}
                                </Typography>
                            </CenterFlexBox></> :
                            null
                    }
                    )
                }
            </CenterFlexBox>
        </> : null
    );
}