import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { SectionCarousal } from "./constant";
import { LightButton } from 'components/Button';
import MockSelectDialog from 'dialogs/MockSelectDialog';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SectionsCarousal(props) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box
      display="flex" flexDirection="column" alignItems="center"
      height="fit-content" py={4} justifyContent="center"
      backgroundColor="#001929" width="100%"
    >
      <Typography
        fontSize="4vmin !important" textAlign="center" color="white.main"
        my={2} component="div" fontWeight="bold"
      >
        {SectionCarousal[activeStep].label}&nbsp;
        <div style={{ color: "#2DABFF", }}>
          {SectionCarousal[activeStep].primaryLabel}
        </div>
      </Typography>
      <AutoPlaySwipeableViews
        resistance enableMouseEvents loop
        interval={4000}
        index={activeStep}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        onChangeIndex={handleStepChange}
        style={{ width: 'min(680px, 100vw)' }}
        slideStyle={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        {SectionCarousal.map((step, index) => (
          <Box
            key={index} component="img" src={step.imgPath} alt={step.label}
            style={{
              height: '50vmin', display: 'block', width: 'auto',
              borderRadius: 4,
            }}
          />
        ))}
      </AutoPlaySwipeableViews>
      <LightButton
        variant="contained"
        style={{ marginTop: 16 }}
        onClick={() => setOpen(o => !o)}
      >
        Take Free Mock
      </LightButton>
      <MockSelectDialog open={open} onClose={handleClose} />
    </Box>
  );
}

export default SectionsCarousal;