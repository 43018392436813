import BackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SwipeableViews from 'react-swipeable-views';

import Button from 'components/Button';
import CitySelect from 'components/CitySelect';
import ContentShell from 'components/ContentShell';
import CountryCodeSelect from 'components/CountryCodeSelect';
import Otpinput from 'components/OTPInput';
import TextField from 'components/TextField';

import { useLoading, useSnackbar } from 'contexts';
import { RegistrationSchema } from 'schemas/register';
import { isContactRegistered, signUp } from 'services';
import { OTPAuth } from 'utils';
import Session from 'utils/Session';

const formFieldSX = { width: '300px' };

const PrivacyPolicy = "https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf";

const InitialValues = {
  name: '', email: '', phone: '', city: '', countryCode: '91', otp: ''
};

function Register(props) {
  const loading = useLoading();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { type } = useParams();
  const [queryParams] = useSearchParams();

  const [validateOTP, setValidateOTP] = useState(false);
  const [enableValidateOTP, setEnableValidateOTP] = useState(false);
  const [resent, setResent] = useState(0);

  const [values, setValues] = useState(InitialValues);
  const [errors, setErrors] = useState({});

  const otpAuth = useRef(new OTPAuth());

  const onOTPChange = (otp) => {
    const _otp = otp.trim();

    setValues((v) => ({ ...v, otp: _otp }));

    if (_otp.length === 6) {
      setEnableValidateOTP(true);
      onOTPSubmit(_otp);
    }
  };

  const onOTPSubmit = async (otp) => {
    try {
      let otpToVerify = otp || values.otp;

      console.info("Verifying OTP: ", otpToVerify);

      loading.show();

      let user;
      try {
        user = await otpAuth.current.verifyOTP(otpToVerify);
      } catch (error) {
        setResent(1);
        console.error(error)
        if (error.message.includes("code-expired")) {
          snackbar.error("Uh Oh! OTP expired. Send OTP again");
          return;
        }

        if (error.message.includes("invalid-verification-code")) {
          snackbar.error("Uh Oh! You have entered an incorrect OTP");
          return;
        }

        console.error(error);

        snackbar.error(error.message || "Uh Oh! Something went wrong");

        return;
      }

      const { user: signedupUser } = await signUp({
        ...values, guid: user.uid, attemptId: queryParams.get("aid")
      });

      snackbar.success("Logged in successfully");

      if (signedupUser?.metadata?.educationInfo || !type) navigate('/home');
      else navigate(`/${type}/education-info`);

    } catch (error) {
      console.error(error);

      snackbar.error("Uh Oh! Something went wrong!");
    } finally {
      loading.hide();
    }
  };

  const handleChangeView = (index, indexLatest) =>
    index === 1 ? setValidateOTP(true) : setValidateOTP(false);

  const resendOtp = () => {
    setResent(0);
    sendOTP();
  }

  const sendOTP = React.useCallback(async () => {
    loading.show();

    const contact = values.countryCode.concat(values.phone);

    try {
      const isRegistered = await isContactRegistered(contact);

      if (isRegistered) {
        snackbar.error("This contact is already registered with us! Login Instead");
        loading.hide();
        return;
      }
    } catch (error) { console.error(error); }

    const invokeSendOTP = () => {
      console.log("Sending otp to...", values.countryCode.concat(values.phone));

      otpAuth.current.sendOTP("+" + values.countryCode.concat(values.phone))
        .then(() => {
          setValidateOTP(true);
          setValues((v) => ({ ...v, otp: '' }));
          snackbar.success("OTP sent successfully")
        })
        .catch((e) => {
          console.error(e);
          snackbar.error("Something went wrong. Try sending Again!!")
        }).finally(() => loading.hide());
    };

    if (otpAuth.current.appVerifier) invokeSendOTP();
    else {
      otpAuth.current.setRecaptchaVerifier();
      invokeSendOTP();
    }
  }, [values]);

  const handleRegister = async (e) => {
    try {
      e.preventDefault();

      setErrors({});

      setResent(0);

      const formData = new FormData(e.target);

      const values = {};
      formData.forEach((value, key) => {
        values[key] = value;
      });

      const { error, value } = RegistrationSchema.validate(values, { abortEarly: false });

      if (error) {
        setErrors(error.details.reduce((acc, curr) => {
          acc[curr.path.toString()] = true;
          return acc;
        }, {}));

        console.error(error)
      } else {
        setValues((v) => ({ ...v, ...value }));

        sendOTP();
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  }

  const getFieldProps = (name) => ({
    id: name,
    name: name,
    error: errors[name],
    sx: formFieldSX,
    inputProps: { autoComplete: 'none' },
    onChange: (e) => setValues((v) => ({ ...v, [name]: e.target.value }))
  });

  React.useState(() => {
    if (Session.isLoggedIn()) {
      navigate("/home");
    }
  }, []);

  return (
    <ContentShell>
      <SwipeableViews
        index={validateOTP ? 1 : 0}
        onChangeIndex={handleChangeView}
        slideStyle={{ margin: "0px 0px" }}
        enableMouseEvents={false}
        ignoreNativeScroll
      >
        <Paper sx={{ width: 'fit-content', mx: 'auto' }}>
          <Box
            display="flex" mx='auto' py={2} px={'5vw'}
            flexDirection="column" alignItems="center" component="form"
            onSubmit={handleRegister}
          >
            <Typography
              fontSize={"min(3vmin,18px)"} fontWeight={700} textAlign="center"
            >
              We need your basic information to save & share the report!
            </Typography>
            <br />
            <TextField {...getFieldProps('name')} placeholder="Your name*" />
            <TextField {...getFieldProps('email')} placeholder="Email*" />
            <CitySelect
              error={!!errors['city']}
              onSelected={(city) => setValues((v) => ({ ...v, city }))}
            />
            <Box display='flex' sx={formFieldSX} justifyContent='space-between'>
              <CountryCodeSelect
                onSelected={(c) => setValues((v) => ({ ...v, countryCode: c }))}
              />
              <TextField
                {...getFieldProps('phone')} placeholder="Mobile number*"
                sx={{ width: 'calc(100% - 82px)' }}
              />
            </Box>
            <br />
            <Button
              type="submit" variant="contained"
              style={{ padding: '8px 5vw' }}
            >
              Register
            </Button>
            <Typography
              fontFamily='inter' fontSize={12}
              fontWeight={500} mt={1} color='rgba(0, 0, 0, 0.6)'
            >
              Already have an account? &nbsp;
              <Link
                to={`/login?aid=${queryParams.get("aid")}&rinfo=true`}
                style={{ color: '#02569D' }}
              >
                Login
              </Link>
            </Typography>
            <Typography
              variant='caption' color="#00000099" mt={4} textAlign="center"
            >
              By continuing, you agree to our&nbsp;
              <Typography
                component="a" variant='caption' color="#02569D" target="_blank"
                href={PrivacyPolicy} style={{ textDecoration: 'none' }}
                rel="noreferrer"
              >
                Terms of service and Privacy Policy
              </Typography>
            </Typography>
          </Box>
        </Paper>
        <Paper sx={{ width: 'fit-content', mx: 'auto', position: 'relative' }}>
          <Box
            display="flex" mx='auto' py={2} px={'5vw'}
            flexDirection="column" alignItems="center"
          >
            <IconButton
              onClick={() => setValidateOTP(false)}
              style={{ position: 'absolute', left: 8, top: 8 }}
            >
              <BackIcon />
            </IconButton>
            <Typography
              fontSize={"min(3vmin,18px)"} fontWeight={700} textAlign="center" mt={3}
            >
              Please enter the OTP to verify mobile number
            </Typography>
            <Typography fontSize={10} fontWeight={500} color="#00000099" mt={1}>
              A code has been sent to {values.countryCode.concat(values.phone)}
            </Typography>
            <br /><br />
            <Otpinput value={values.otp} onChange={onOTPChange} />
            <br /><br />
            <Button
              disabled={!enableValidateOTP} variant="contained"
              onClick={() => onOTPSubmit()}
            >
              Validate
            </Button>
            <Typography fontSize={10} fontWeight={500} color="#00000099" mt={1}>
              Didn’t get the code?
              <Button
                variant="text" style={{ fontSize: 10, padding: "4px 8px" }}
                onClick={resent > 0 ? resendOtp : () => setValidateOTP(false)}
              >
                {resent > 0 ?
                  `Resend` :
                  "Check phone number"
                }
              </Button>
            </Typography>

            <Typography
              fontSize={10} fontWeight={500} color="#00000099" my={1}
              component="div" display="flex" alignItems="center"
            >
              <Checkbox defaultChecked size="small" />
              Subscribe to receive AI based feedback report on whatsapp
            </Typography>
            <Divider width="100%" light />
            <Typography variant='caption' color="#00000099" mt={1} textAlign="center">
              By continuing, you agree to our&nbsp;
              <Typography
                component="a" variant='caption' color="#02569D" target="_blank"
                href={PrivacyPolicy} style={{ textDecoration: 'none' }}
                rel="noreferrer"
              >
                Terms and Conditions
              </Typography>
            </Typography>
          </Box>
        </Paper>
      </SwipeableViews>
      <div id="recaptcha-container"></div>
    </ContentShell >
  );
}

export default Register;