import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CountDown from 'components/CountDown';
import { Button } from 'components/Button';
import useStore from 'utils/toeflStore';

const instructions = [
  "Your reading and listening answers have been saved successfully",
  "Please make sure you are seated in a noise-free environment with good quality headphones to ensure proper recording of your speaking task answer",
  "Note: You will be introduced with a microphone test before the speaking assessment"
];

function ListItem(props) {
  return (
    <Typography variant="body2" mt={1} color="#535353" {...props} display="inline-flex">
      <span
        style={{
          marginTop: 6, marginRight: 16, backgroundColor: "#02569d",
          padding: 4, height: 8, borderRadius: "50%"
        }}
      />
      <span style={{ display: "inline-block" }}>{props.children}</span>
    </Typography>
  );
}

function Break(props, ref) {
  const totalTime = 600;

  const nextSection = useStore(state => state.nextSection);

  return (
    <div
      style={{
        height: '100vh', display: "flex", alignItems: "center",
        justifyContent: "center"
      }}
      ref={ref}
    >
      <Box
        display="flex" width="100%" height="fit-content" px={6} py={2}
        justifyContent="center" position="relative" mx="auto" maxWidth={650}
        flexDirection="column" backgroundColor="#FFFFFF" borderRadius={2}
      >
        <Box style={{
          position: 'absolute', top: "-24px", textAlign: "center",
          width: '-webkit-fill-available',
        }}>
          <Typography style={{
            fontSize: 14, fontWeight: 500, color: "white",
          }}
          >
            Test will start in:
            <CountDown
              totalTime={totalTime} variant="caption" onTimeout={nextSection}
              style={{ fontSize: 14, fontWeight: 500, }}
            />
            minutes
          </Typography>
        </Box>
        <Typography variant="overline" fontWeight="bold" color="#838383">
          NEXT SECTION:&nbsp;SPEAKING
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          10 minutes Test Break
        </Typography>
        {instructions.map((instruction, i) => (
          <ListItem key={i}>{instruction}</ListItem>
        ))}
        <br /><br />
        <Button variant="contained" onClick={nextSection}>Skip Break</Button>
      </Box>
    </div>
  );
}

export default React.forwardRef(Break);