import React from 'react';
import Box from "@mui/material/Box";
function Brief({ active, content }) {
  return active ? (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" pt={2} flexGrow={1}>
      <Box flexGrow={1}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  ) : null;
};

export default Brief;