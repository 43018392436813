import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';
import VolumeIcon from '@mui/icons-material/VolumeUp';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSnackbar } from 'contexts';

const useStyles = makeStyles((theme) => ({
  slider: {
    '& .MuiSlider-thumb': {
      height: 12, width: 12,
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      }
    }
  },
}));

function AudioPlayer({ audioUrl, play, onCompleted = () => { } }) {
  const snackbar = useSnackbar();
  const classes = useStyles();
  const [audioPlayer, setAudioPlayer] = React.useState(null);
  const [volume, setVolume] = React.useState(100);
  const [totalTime, setTotalTime] = React.useState(300);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [error, setError] = React.useState(false);
  const [retry, setRetry] = React.useState(0);

  const handleSliderChange = React.useCallback((e, newValue) => {
    setVolume(newValue);
    if (audioPlayer) audioPlayer.volume = newValue / 100;
  }, [audioPlayer]);

  React.useLayoutEffect(() => {
    let audio;
    if (play) {
      setError(false);
      setCurrentTime(0);
      setTotalTime(300);

      audio = document.createElement('audio');
      audio.setAttribute('preload', 'metadata');
      audio.src = audioUrl;
      audio.addEventListener('timeupdate', () => {
        setCurrentTime(Math.floor(audio.currentTime));
      });
      audio.addEventListener('loadedmetadata', () => {
        setTotalTime(audio.duration);
        setCurrentTime(0);
      });
      audio.addEventListener('ended', () => {
        setCurrentTime(audio.duration);
        onCompleted();
      });
      audio.addEventListener('error', (error) => {
        console.error(error);
        setError(true)
        snackbar.error("Unable to play audio! Check your network connection");
      });

      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = null;
        ['play', 'pause', 'stop', 'nexttrack', 'previoustrack', 'seekbackward',
          'seekforward', 'seekto'
        ].forEach((action) =>
          navigator.mediaSession.setActionHandler(action, () => { })
        );
      }

      audio.play();

      setAudioPlayer(audio);
    } else {
      audio.pause();
      audio.currentTime = 0;
    }
    return () => {
      if (play && audio) {
        audio.pause();
        audio.currentTime = 0;
      }
    }
  }, [play, audioUrl, retry]);

  return play ? (
    <Box display="flex" width="100%" columnGap={1}>
      <Box flexGrow={1} position="relative">
        <LinearProgress
          color="primary"
          variant="determinate"
          value={Math.floor((currentTime / totalTime) * 100)}
          sx={{
            height: 32, borderRadius: 0, backgroundColor: "transparent",
            border: "1px solid grey"
          }}
        />
        {error && (<IconButton
          onClick={() => setRetry(r => r + 1)} size="small"
          style={{ position: "absolute", right: 0, top: 0 }}
        >
          <RefreshIcon color="primary" fontSize="small" />
        </IconButton>)}
      </Box>
      <Box px={2} sx={{
        width: 150, display: "flex", alignItems: "center",
        borderRadius: 1, border: '1px solid grey'
      }}>
        <VolumeIcon color="primary" fontSize="small" />&nbsp;&nbsp;&nbsp;
        <Slider
          disabled={error}
          size="small"
          color='primary'
          track="normal"
          value={volume}
          onChange={handleSliderChange}
          className={classes.slider}
        />
      </Box>
    </Box>
  ) : null;
}

export default AudioPlayer;