import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Universities } from "./constant";

const useStyles = makeStyles({
  marqueeBox: {
    display: "flex", animation: "$marquee 100s linear infinite"
  },
  "@keyframes marquee": {
    "0%": {
      transform: "translateX(0%)",
    },
    "50%": {
      transform: "translateX(calc(-5740px + 100vw))",
    },
    "100%": {
      transform: "translateX(0%)",
    }
  }
});

const styles = {
  card: { minWidth: 300, mx: 2, background: 'white', textDecoration: 'none' },
};

function UniversityCard({ step }) {
  return (
    <Card
      sx={styles.card} component="a" href={step.url} target="_blank"
      rel="noreferrer"
    >
      <CardHeader
        avatar={
          <Avatar
            aria-label="university"
            src={step.imagePath}
            sx={{ width: 24, height: 24 }}
          >
            {step.name.charAt(0)}
          </Avatar>
        }
        title={step.name}
        subheader={`${step.score} ${step.ielts ? "in IELTS" : "in TOEFL"}`}
      />
    </Card>
  );
}

function UniversitiesCarousal() {
  const classes = useStyles();
  return (
    <Box width="100%" mt={2}>
      <Box className={classes.marqueeBox}>
        {Universities.map((step, index) => (
          <UniversityCard key={index} step={step} />
        ))}
      </Box>
    </Box >
  );
}

export default UniversitiesCarousal;