import React from 'react';
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import CallIcon from "@mui/icons-material/Call";
import BackToTopButton from "./BackToTopButton";

import { useSnackbar } from 'contexts/SnackbarProvider';
import ProductHuntBadge from './ProductHuntBadge';

const links = [
  {
    id: 1, name: "Teach & Train",
    link: process.env.REACT_APP_TEACH_TRAIN_URL,
    newPage: false
  },
  {
    id: 2, name: "In-Prep",
    link: process.env.REACT_APP_INPREP_URL,
  },
  {
    id: 4, name: "Careers",
    link: process.env.REACT_APP_CAREER_PAGE_LINK, newPage: true
  },
];

const socialLinks = [
  {
    Icon: <LinkedInIcon color="primary" />,
    link: "https://www.linkedin.com/company/languify-in/"
  },
  {
    Icon: <InstagramIcon color="primary" sx={{ margin: "4px" }} />,
    link: "https://instagram.com/thelanguify?igshid=ack5ewhe6n96"
  },
  {
    Icon: <FacebookIcon color="primary" />,
    link: "https://www.facebook.com/Languify/"
  },
];

const useStyles = makeStyles(theme => ({
  parentContainer: {
    display: "flex", position: "relative", backgroundColor: "#013B6B",
    justifyContent: "space-around", flexWrap: "wrap", padding: "2em",
    marginTop: "1em",
    [theme.breakpoints.down(725)]: {
      flexDirection: "column",
    }
  },
  itemContainer: {
    maxWidth: 350,
    [theme.breakpoints.down(725)]: { maxWidth: "unset", padding: 0 }
  },
  mobileElement: {
    display: "none",
    [theme.breakpoints.down(725)]: { display: "block", }
  },
  divider: { width: "100%", margin: "2em auto" },
  button: {
    borderRadius: "4px", margin: "10px 0px"
  },
  header: {
    color: "white", fontSize: "1.3em", fontWeight: "bold",
    [theme.breakpoints.down(725)]: { fontSize: "1.4em" }
  }
}));

const NewTabLink = ({ newTab, href, children }) => {
  return (
    <Typography
      href={href} target={newTab ? "_blank" : "_self"}
      style={{
        color: "white", textDecoration: "none", margin: "0.4em 0",
        display: "block",
      }}
      component="a" rel="noreferrer" fontWeight="bold" fontSize={15}
    >
      {children}
    </Typography>
  );
};

const Heading = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography className={classes.header}>
      {children}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();
  const snackbar = useSnackbar();

  const handleSubscribeClick = (e) => {
    e.preventDefault();
    e.target.reset();
    snackbar.showSnackbar("Subscribed successfully !!", "success");
  }

  return (
    <Box className={classes.parentContainer}>
      <BackToTopButton style={{ position: "absolute", top: -24, right: 32 }} />
      <Box className={classes.itemContainer}>
        <Heading>
          About Languify
        </Heading>
        <Typography color="white.main" m="1em 0" variant="body2" fontWeight={500}>
          We are a team of highly motivated folks from global universities and various domain expertise.
          We collaborate and co-create at Languify to solve the communication,
          assessment and hiring challenges of India and the World.
        </Typography>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {
            socialLinks.map((socialLink, index) => (
              <NewTabLink key={index} href={socialLink.link} children={socialLink.Icon} />
            ))
          }
        </Box>
        <a href='https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf'
          style={{
            color: '#049CFF', fontFamily: 'Lato', textDecoration: 'none',
            borderBottom: '1px solid #049CFF'
          }}
          target='_blank' rel="noreferrer"
        >
          Privacy Policy
        </a>
      </Box>
      <hr className={`${classes.mobileElement} ${classes.divider}`} />
      <Box className={classes.itemContainer} padding="0 1em" id="contact-us">
        <Heading>
          Contact Us
        </Heading>
        <Box display="flex" alignItems="center" my="0.5em">
          <MailIcon sx={{ color: "#2DABFF" }} fontSize="medium" />
          <Typography
            color="white.main" variant="body2" fontWeight={500} ml={2}
          >
            growth@languify.in
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" my="0.5em">
          <CallIcon sx={{ color: "#2DABFF" }} fontSize="medium" />
          <Typography color="white.main" variant="body2" fontWeight={500}
            ml={2}
          >
            +91-9589952975
          </Typography>
        </Box>
        <br />
        <Typography variant="h6" color="white.main" mb={1}>
          Our offices
        </Typography>
        <Typography color="white.main" variant="body2" fontWeight={500} mb={1}
        >
          HSR Layout, Bengaluru, Karnataka 560102, India
        </Typography>
        <Typography color="white.main" variant="body2" fontWeight={500}
        >
          Norrsken House, Birger Jarlsgatan 57c, 113 56, Stockholm, Sweden
        </Typography>

      </Box>
      <hr className={`${classes.mobileElement} ${classes.divider}`} />
      <Box className={classes.itemContainer}>
        <Box display="flex" flexDirection="column">
          {
            links.map((link) => (
              <NewTabLink
                href={link.link}
                key={link.id}
                newTab={link.newPage}
              >
                {link.name}
              </NewTabLink>
            ))
          }
        </Box> <br />

        <ProductHuntBadge />
      </Box>
    </Box>
  )
}

export default Footer;