import React from "react";
import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CustomFaqs from "components/CustomFaqs";

const faqs = [
    {
        question: "How to get Languify mock test integrated for your Business?",
        answers: [
            "To integrate Languify mock test for your Business, contact us at info@languify.in or +91-9589952975.",
            "Our team will work with you to understand your product and integrate Languify into your platform within 2-3 hours"
        ],
    },
    {
        question: "Are all the mock Tests on Languify Free?",
        answers: ["Yes all mock tests are free and you can practice as many times as you want"],
    },
    {
        question: "Can I expect my scores from Languify to be similar to what I will be receiving on the actual TOEFL or IELTS exam?",
        answers: ["Many of our users have shared similar test results between Languify and official TOEFL and IELTS scores. Please note that variations in factors such as test day, experimental ETS and Cambridge University test material, and technical differences influence the testing experience as a whole, so we cannot provide an exact match. Languify is a service designed to provide feedback on TOEFL and IELTS improvement, so feel free to use the scores as a guide to help you find the fastest way to improve on your weak points!"],
    },
    {
        question: "How long are the mocks?",
        answers: ["To help you prepare for the actual exams, our IELTS/TOEFL product provides three types of mock tests. The first is a quick diagnostic test, lasting 30 minutes and covering all four sections. The second is a comprehensive full-length mock test, lasting 3 hours and closely following the official TOEFL/IELTS format. Finally, we offer section-specific mock tests, each with the same duration as the full-length mock test, to give you a focused evaluation of your skills in each section."]
    },
    {
        question: "Will I get speaking and writing scores in a detailed manner?",
        answers: ["Yes, you will get a detailed report for these sections as well as others."]
    },
    {
        question: "Why does languify collect phone numbers?",
        answers: ["We collect the numbers to authenticate our users and we promise we won't spam."]
    },
    {
        question: "When and where will I get my reports?",
        answers: ["You will get your reports on your registered email account just after you complete  the assesment."]
    },
    {
        question: "Are the scores of Mock TOEFL and IELTS based on the official scoring pattern?",
        answers: ["Yes, TOEFL scoring is based on CEFR standards which is the official standard for TOEFL tests and IELTS test scores are also based on the official IELTS scoring system"]
    },
    {
        question: "Will I get a band score report for each section for IELTS mock test?",
        answers: ["Yes, you will get a section-wise band score report for IELTS mock test."]
    },
    {
        question: "Is the evaluation done by a machine or human?",
        answers: ["The evaluations are 100% Artificial Intelligence graded and that's the best part, there is no human bias on the scores."]
    },
    {
        question: "Will I get a detailed question-wise analysis after giving the mock?",
        answers: ["Yes, Languify provides a comprehensive skill report in which you can check your question-wise performance."]
    },
];

const useStyles = makeStyles(theme => ({
    parentContainer: {
        width: "90%",
        margin: "1em auto 4em auto",
        [theme.breakpoints.down(725)]: { marginBottom: "4em", width: "100%" }
    },
    faqsContainer: {
        backgroundColor: "#F9F9F9", padding: '0px 48px',
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
    }
}));

function Faq() {
    const classes = useStyles();

    return (
        <Box id="faqs" className={classes.parentContainer}>
            <Typography
                ml={0} color="primary" textAlign="center"
                fontSize="4.1vw"
            >
                FAQ's.
            </Typography>
            <Typography mb={2} textAlign="center" fontSize={"4vw !important"}>
                Incase you missed anything
            </Typography>
            <CustomFaqs faqs={faqs} />
        </Box>);
}

export default Faq;