import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CopyIcon from "@mui/icons-material/CopyAllOutlined";
import CopiedIcon from '@mui/icons-material/CheckCircle';
import Button from "components/Button";
import CenterFlexBox from "components/CenterFlexBox";
import Header from "components/Header";
import { copyToClipboard } from "utils";

export default function UnsupportedBrowser() {
    const testLinkRef = React.useRef();

    const [copied, setCopied] = React.useState(false);

    const handleClick = () => {
        if (copied) return;

        copyToClipboard(window.location.origin, testLinkRef);

        setCopied(true);

        setTimeout(() => setCopied(false), 1000);
    }

    return (
        <>
            <Header />
            <CenterFlexBox>
                <CenterFlexBox
                    height='calc(100vh - 80px)'
                    backgroundColor='#235598'
                    flexDirection='column'
                    width={'100%'}
                >
                    <img
                        src="https://assets.languify.in/images/page-not-found.svg"
                        alt="Not-found"
                    />
                    <Box maxWidth={500} pl={4}>
                        <Typography
                            fontSize={26}
                            fontWeight={500}
                            color='white.main'
                            alignSelf={'flex-start'}
                            mb={1}
                        >
                            Uh-oh !
                        </Typography>
                        <Typography
                            fontSize={15} fontWeight={500} color='white.main'
                        >
                            You’re trying to access free mock test on an unsupported device,
                            to give a real TOEFL experience we only offer mock test on the desktop.
                            <br /><br />
                            For the best experience, access this site with an updated Chrome or Microsoft Edge Browser on your PC or laptop.
                        </Typography>
                        <br />
                        <Box display="flex" alignItems="center">
                            <Typography
                                mr={2} fontSize={15} fontWeight={500}
                                color='white.main'
                                ref={testLinkRef}
                            >
                                {window.location.origin}
                            </Typography>
                            <Button
                                variant="outlined"
                                disableElevation
                                color='white'
                                style={{ padding: '4px 12px' }}
                                onClick={handleClick}
                                startIcon={copied ? <CopiedIcon color='success' /> : <CopyIcon />}
                            >
                                Copy test link
                            </Button>
                        </Box>
                    </Box>
                </CenterFlexBox>
            </CenterFlexBox>
        </>
    );
}