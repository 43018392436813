import Box from "@mui/material/Box";
import useStore from "utils/reportStore";
import Typography from "@mui/material/Typography";

import { makeStyles } from "@mui/styles";
import Passage from "components/Passage";
import React from "react";

const useStyles = makeStyles({
    title: {
        fontFamily: 'inter',
        fontSize: 18,
        fontWeight: 600,
    },
    container: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
    },
    overlay: {
        position: 'absolute',
        width: '300px',
        height: '100%',
        zIndex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: 24,
        WebkitBackdropFilter: 'blur(1px)',
        backdropFilter: 'blur(1px)',
        textAlign: 'center',
        paddingTop: 15,
        fontSize: 16,
        fontWeight: 700,
        fontFamily: 'inter',
        alignSelf: 'center',
        color: '#ffffff',
    },
    content: {
        fontFamily: 'inter',
        fontSize: 14,
        fontWeight: 400,
        color: 'rgba(0, 0, 0, 0.6)',
    }
});

function AudioElement({ audio }) {

    const Audio = React.useCallback(() => {
        return (<audio controls>
            <source src={audio}></source>
        </audio>)
    }, [audio]);

    return <Audio />;
}

function AudioAnswer({ questionAudio, yourAnswerAudio, idealAnswerAudio }) {
    const classes = useStyles();

    return (
        <>
            <br />
            <Typography mb={1} className={classes.title}>
                Audio Passage:
            </Typography>
            <Box className={classes.container} >
                <AudioElement audio={questionAudio} />
            </Box> <br /><br />
            <Typography mb={1} className={classes.title}>
                Your Answer Audio:
            </Typography>

            <img
                src="https://assets.languify.in/images/under-development.png"
                alt="Under development" width={300} height='auto'
            />
            {/* <Box className={classes.container} >
                <Box className={classes.overlay}>Under development</Box>
                <AudioElement audio={yourAnswerAudio} />
            </Box> */}
            <br /><br />
            <Typography mb={1} className={classes.title}>
                Ideal Answer Audio:
            </Typography>
            <img
                src="https://assets.languify.in/images/under-development.png"
                alt="Under development" width={300} height='auto'
            />
            {/* <Box className={classes.container} >
                <Box className={classes.overlay}>Under development</Box>
                <AudioElement audio={idealAnswerAudio} />
            </Box> */}
        </>
    );
}

export default function Questions({ active }) {
    const section = useStore((state) => state.section);
    const {
        questions = [], passages = []
    } = useStore((state) => state.sections?.[section] || {});
    const analysis = useStore((state) => state.analysis[section]);
    const currentQuestion = useStore((state) => state.currentQuestion);
    const type = useStore((state) => state.sections?.[section]?.name?.toUpperCase());
    const question = questions?.[currentQuestion];

    return (
        (active) ? (
            <>
                <Typography
                    fontFamily='inter' fontSize={22} mb={1}
                    fontWeight={600} color='#02569D'
                >
                    Q{currentQuestion !== null && (currentQuestion + 1)}
                </Typography>

                {
                    (type === "SPEAKING") && (<>
                        <Typography
                            fontFamily='inter' fontSize={15} fontWeight={600}
                        >
                            Passage:
                        </Typography>
                        <Typography
                            fontFamily='inter' fontSize={12} fontWeight={500} color='rgba(0, 0, 0, 0.6)'
                            dangerouslySetInnerHTML={{ __html: question?.passage?.content || 'NA' }}
                        >
                        </Typography>
                        {(question?.question?.content) && <Typography
                            fontFamily='inter' fontSize={12} fontWeight={500} color='rgba(0, 0, 0, 0.6)'
                            dangerouslySetInnerHTML={{ __html: question?.question?.content }}
                        >
                        </Typography>}
                        <AudioAnswer questionAudio={question?.audioPassage?.audioUrl} />
                    </>)
                }
                {
                    (type === "WRITING") && (<>
                        {(question?.passage?.content) && <Typography
                            fontFamily='inter' fontSize={12} fontWeight={500} color='rgba(0, 0, 0, 0.6)'
                            dangerouslySetInnerHTML={{ __html: question?.passage?.content }}
                        >
                        </Typography>}
                        {(question?.audioPassage?.audioUrl) &&
                            <AudioElement audio={question?.audioPassage?.audioUrl} />
                        }
                        {(question?.question?.content) && <Typography
                            fontFamily='inter' fontSize={12} fontWeight={500} color='rgba(0, 0, 0, 0.6)'
                            dangerouslySetInnerHTML={{ __html: question?.question?.content }}
                        >
                        </Typography>}<br />
                        <Typography
                            fontFamily='inter' fontSize={15} fontWeight={600}
                        >
                            Your answer:
                        </Typography>
                        <Typography
                            fontFamily='inter' fontSize={12} fontWeight={500}
                            dangerouslySetInnerHTML={{ __html: analysis?.[currentQuestion]?.answer || 'NA' }}
                        >
                        </Typography> <br />
                        <Typography
                            fontFamily='inter' fontSize={15} fontWeight={600} color='green'
                        >
                            Ideal answer:
                        </Typography>
                        <Typography
                            fontFamily='inter' fontSize={12} fontWeight={400}
                            dangerouslySetInnerHTML={{ __html: questions?.[currentQuestion]?.question?.answer || 'NA' }}
                        >
                        </Typography> <br />
                    </>)
                }
                {
                    (type === "READING") && (<>
                        <br />
                        <Typography
                            fontFamily='inter' fontSize={15}
                            fontWeight={600}
                        >
                            Passage:
                        </Typography>
                        <Passage active noScroll>
                            {passages?.[question.passage]?.content || 'NA'}
                        </Passage> <br />
                    </>)
                }
                {
                    (type === "LISTENING") && (<>
                        <br />
                        <Typography
                            fontFamily='inter' fontSize={15}
                            fontWeight={600} mb={1}
                        >
                            Audio Passage :
                        </Typography>
                        <AudioElement audio={passages?.[question.passage]?.audioUrl} />

                        <br /> <br />

                        {(passages?.[question.passage]?.imageUrl) &&
                            <Box m={2} border='2px solid #E7E7E7'> <br />
                                <img
                                    width={'100%'} height={'100%'}
                                    alt="question"
                                    src={passages?.[question.passage]?.imageUrl}
                                />
                            </Box>
                        }
                    </>)
                }
            </>
        ) : null
    );
}