import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const ContainerBox = styled('div')(({ theme }) => ({
    height: 230,
    width: 230,
    borderRadius: 4,
    margin: '0.5em',
    display: 'flex',
    alignItems: 'flex-end',
    backgroundSize: 'cover',
    cursor: 'pointer',
    '&:hover': {
        '&>*': {
            visibility: 'visible',
            backgroundImage: "linear-gradient(180deg, rgba(217, 217, 217, 0) 36.52%, rgba(79, 110, 136, 0.63) 61.66%, rgba(5, 53, 94, 0.87) 100%)",
            boxShadow: "0px 8px 12px grey",
        }
    },
    [theme.breakpoints.down('sm')]: {
        width: 120,
        height: 120
    },
    [theme.breakpoints.down('md')]: {
        width: 150,
        height: 150
    },
    '& > div': {
        transition: "all 400ms ease-in",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column-reverse",
        borderRadius: 8,
        padding: 8,
    }
}));

function ImageInfo({ src, name, title }) {
    return (
        <ContainerBox
            sx={{
                backgroundImage: `url(${src})`,
            }}
        >
            <Box visibility={'hidden'} >
                <Typography variant='body2' color='#ffffff'>
                    {title}
                </Typography>
                <Typography variant='h6' color='#ffffff'>
                    {name}
                </Typography>
            </Box>
        </ContainerBox>
    );
}

export default ImageInfo;
