import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextArea from 'components/TextArea';

function WritingQuestion({ active, question, ...props }) {
  const [value, setValue] = React.useState('');
  const [wordCount, setWordCount] = React.useState(0);

  const wordLimit = question?.question?.expectedWords || question?.expectedWords || 300;

  const handleKeyDown = (e) => {
    const key = e.keyCode || e.charCode;

    if (wordCount >= wordLimit && !(key === 8 || key === 46)) {
      e.target.value = value;
      e.nativeEvent.preventDefault();
      return false;
    }
  }

  const handleChange = (e) => {
    setValue(e.target.value);

    question.answer.value = e.target.value;
  };

  React.useEffect(() => {
    const wc = value?.trim()?.split(" ")?.length;
    setWordCount(value ? wc : 0);
  }, [value]);

  return active ? (
    <Box
      minWidth="calc(50% - 16px)" display="flex" flexDirection="column"
      flexGrow={1} width="50%" ml={2} mt={1} {...props}
    >
      <Typography fontSize={14} minHeight={40} m={0}
        dangerouslySetInnerHTML={{ __html: question?.question?.content }}
        flexShrink={0}
      />
      <Typography textAlign="right" fontSize={12} mt={1} flexShrink={0}>
        Word Count:&nbsp;
        <span style={{ minWidth: 100, color: "#235598", fontWeight: 500 }}>
          {wordCount}/{wordLimit}
        </span>
      </Typography>
      <TextArea
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        spellCheck={false}
        data-gramm={false}
        data-gramm_editor={false}
        data-enable-grammarly={false}
        max={wordLimit}
        onPaste={(e) => e.preventDefault()}
        onDrop={(e) => e.preventDefault()}
        autoComplete="off"
        placeholder="Write your answer here..."
      />
    </Box>
  ) : null;
}

export default WritingQuestion;