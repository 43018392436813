import React from 'react';
import Box from '@mui/material/Box';

function QuestionsContainer({ children }) {
  return (
    <Box display="flex" width="100%" flexGrow={1} mt={2} overflow="auto">
      <Box
        mx={'auto'} mt={2} boxShadow="0px 4px 24px rgba(0, 0, 0, 0.16)"
        width="calc(100% - 64px)" minHeight="-webkit-fill-available"
        height="fit-content"
        borderRadius={2} p={2}
      >
        {children}
      </Box>
    </Box>
  );
}

export default QuestionsContainer;