import React from 'react';
import { styled, makeStyles } from '@mui/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MUISelect from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import InputBase from '@mui/material/InputBase';
import { Checkbox, Chip } from '@mui/material';

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 200,
    background: 'white',
    color: grey[500],
    fontWeight: 200,
    fontSize: 12,
    borderStyle: 'none',
    borderWidth: 2,
    borderRadius: 12,
    paddingLeft: 24,
    paddingTop: 16,
    paddingBottom: 16,
    boxShadow: '0px 5px 8px -3px rgba(0,0,0,0.14)',
    "&:focus": {
      borderRadius: 12,
      background: 'white',
      borderColor: grey[100]
    },
  },
  icon: {
    color: grey[900],
    right: 12,
    position: 'absolute',
    userSelect: 'none',
    pointerEvents: 'none'
  },
  paper: {
    borderRadius: 8,
    marginTop: 0,
  },
  list: {
    maxHeight: 150, overflowY: "auto", padding: "12px 0px",
    background: '#E4E3E8',
    "& li": {
      fontWeight: 400,
      padding: "4px 16px",
      fontSize: 12,
      fontFamily: "Inter"
    },
    "& li:hover": {
      background: '#dcdbe0'
    },
    "& li.Mui-selected": {
      background: '#dcdbe0',
    },
    "& li.Mui-selected:hover": {
      background: '#dcdbe0',
      fontWeight: 400
    }
  }
}));

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    borderRadius: "6px",
    position: 'relative',
    backgroundColor: "#E4E3E8",
    border: 'none',
    fontSize: "12px",
    padding: '6px 16px',
    fontFamily: "Inter",
    fontWeight: "500",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: "6px",
      borderColor: '#02569D',
      boxShadow: 'none',
    },
  },
}));

function Select({ options, helperText, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: {
      paper: classes.paper,
      list: classes.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  return (
    <FormControl
      fullWidth disabled={!options.length} size="small" variant='outlined'
      error={props.error} sx={{ my: 1 }}
    >
      <InputLabel
        id="simple-select-label"
        style={{ fontSize: 12, fontWeight: "normal" }}
      >
        {props.label}
      </InputLabel>
      <MUISelect
        {...props}
        labelId="simple-select-label"
        input={<BootstrapInput />}
        MenuProps={menuProps}
        classes={{ root: classes.select }}
      >
        {
          options.map((option) => (
            <MenuItem key={option._id} value={option._id}>{option.name}</MenuItem>
          ))
        }
      </MUISelect>
      <FormHelperText error >{helperText}</FormHelperText>
    </FormControl>
  );
};

export function MultipleSelect({ options, helperText, ...props }) {
  const classes = useStyles();

  const menuProps = {
    classes: { paper: classes.paper, list: classes.list },
    anchorOrigin: { vertical: "bottom", horizontal: "left" },
    transformOrigin: { vertical: "top", horizontal: "left" },
    getContentAnchorEl: null
  };

  return (
    <FormControl
      fullWidth disabled={!options.length} size="small" variant='outlined'
      error={props.error} sx={{ my: 1 }}
    >
      <InputLabel
        id="simple-multi-select-label"
        style={{ fontSize: 12, fontWeight: "normal" }}
      >
        {props.label}
      </InputLabel>
      <MUISelect
        {...props}
        multiple
        labelId="simple-multi-select-label"
        input={<BootstrapInput />}
        MenuProps={menuProps}
        classes={{ root: classes.select }}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip
                key={value} label={value} size="small" variant="outlined"
                color="primary"
              />
            ))}
          </Box>
        )}
      >
        {options.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            <Checkbox checked={props.value.indexOf(option.name) > -1} />
            {option.name}
          </MenuItem>
        ))}
      </MUISelect>
      <FormHelperText error >{helperText}</FormHelperText>
    </FormControl>
  );
};

export default Select;