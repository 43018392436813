import React from 'react';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const SectionColors = {
  "READING": { color: "#FFA800", backgroundColor: "#FFC70033" },
  "LISTENING": { color: "#00AB44", backgroundColor: "#D5FFD4" },
  "SPEAKING": { color: "#BF27F6", backgroundColor: "#D767FF33" },
  "WRITING": { color: "#FF00D6", backgroundColor: "#FF00D633" },
};

function SectionChip({ name, hideInfoIcon, onViewInfo, onHideInfo }) {
  const uName = name?.trim()?.toUpperCase();

  return (
    <Typography
      component="div" px={2} py={1} style={{
        ...SectionColors[uName], borderRadius: 12, width: "fit-content",
        fontSize: 12, fontWeight: 600,
      }}
    >
      {name}
      {!hideInfoIcon && (
        <IconButton
          sx={{ ml: 1, p: 0 }} onMouseEnter={onViewInfo}
          onMouseLeave={onHideInfo}
        >
          <InfoOutlinedIcon />
        </IconButton>
      )}
    </Typography >
  );
}

export default SectionChip;