import React from 'react';
import shallow from "zustand/shallow";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import NextIcon from '@mui/icons-material/NextPlan';

import Button from 'components/Button';
import { analyseSection } from 'services/analysisService';
import useStore from 'utils/ieltsStore';

function SavingAnswers({
  active, questions, onSaved, analysisTasks,
}) {
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [retry, setRetry] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [analysed, setAnalysed] = React.useState(-1);

  const [section, attempt, mockType, sections] = useStore(state => [
    state.section, state.attempt, state.mockType, state.sections
  ], shallow);

  const sectionType = sections?.[section]?.name?.toUpperCase();

  React.useEffect(() => {
    if (active) {
      setAnalysed(0);
      setProgress(0);

      if (sectionType === "SPEAKING") {
        const totalTasks = analysisTasks.current.length;
        setTotal(totalTasks);
        setErrors(new Array(totalTasks).fill(false));

        const failedTasks = analysisTasks.current.filter(t => t.error);
        if (retry > 0 || failedTasks.length) {

          failedTasks.forEach((task) => {
            task.error = false;
            task.progress = 0;
            task.completed = false;
            task.service(task.answer, task.eventHandler);
          });
        }

        const progressInterval = setInterval(() => {
          let _progress = 0;
          let _analysed = 0;
          analysisTasks.current.forEach((task, i) => {
            _progress += task.progress;
            if (task.completed) _analysed += 1;
            if (task.error) {
              setErrors((errors) => ([
                ...errors.slice(0, i), true, ...errors.slice(i + 1)
              ]));
            }
          });

          setAnalysed(_analysed);
          setProgress(Math.floor(_progress / totalTasks));

          if (_analysed === totalTasks) {
            clearInterval(progressInterval);
          }
        }, 1000);

        return () => clearInterval(progressInterval);
      } else {
        setErrors([false]);
        setTotal(1);

        const answers = questions.map((q) => ({
          questionType: q.type || q.answer.questionType,
          answer: q.answer.value,
        }));

        const progressInterval = setInterval(() => {
          setProgress((p) => {
            if (p >= 90) {
              clearInterval(progressInterval);
              return p;
            } else return p + Math.round(Math.random() * 20);
          });
        }, 1000);

        analyseSection(
          {
            section, attempt, mockType, answers,
            mockSection: sections?.[section]?._id,
          },
          (event, data) => {
            if (event === 'analysed') {
              setProgress(100);
              onSaved();
            }
            if (event === 'error') {
              setProgress(100);
              console.error("ERROR_SAVING_ANSWER:", data);
              setErrors([data]);
            }
          }
        );

        return () => clearInterval(progressInterval);
      }
    }
  }, [active, retry]);

  React.useEffect(() => {
    if (analysed === total && !!!errors.find(e => e !== false)) {
      setProgress(100);
      onSaved();
    }
  }, [analysed, total, errors, onSaved]);

  return active ? (
    <Box
      width="100%" height="fit-content"
      justifyContent="center" position="relative" mx="auto" maxWidth={550}
      flexDirection="column" backgroundColor="white.main" borderRadius={2}
      py={3} px={6} alignItems="center" display="flex"
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.16)"
    >
      {(!!errors.find(e => e !== false)) ? (<>
        <Typography fontWeight={600} mb={1}>Upload Failed</Typography>
        <Typography variant="caption" color="#00000099">
          There was an error while uploading your answers. Kindly check your
          network connection and try again, otherwise you will not be evaluated
          for this section.
        </Typography>
        <Box display="flex" alignItems="center" mt={4} width="100%">
          <Button
            variant="outlined"
            color="error"
            onClick={onSaved}
            startIcon={<NextIcon />}
            style={{ padding: "4px 12px", width: "50%" }}
          >
            Proceed to Next Section
          </Button>
          <Button
            style={{ padding: "5px 12px", width: "50%" }}
            variant="contained"
            color="primary"
            onClick={() => {
              setRetry(r => r + 1);
              setErrors([]);
              setAnalysed()
            }}
            startIcon={<RefreshIcon />}
          >
            Try Again
          </Button>
        </Box>
      </>) : (<>
        <Typography fontWeight={600} mb={1}>You answers are being saved!</Typography>
        <Typography variant="caption" color="#00000099">
          If you leave or refresh this page now, your answer will not be saved.
          Hold on until the save is complete. Thank you for your patience!
        </Typography>
        <br /><br />
        <Typography
          variant="caption" color="primary" alignSelf="start" fontWeight={600}
        >
          {`${Math.round(progress)}`}<span style={{ fontSize: "70%" }}>%</span>
        </Typography>
        <Box flexGrow={1} width="100%">
          <LinearProgress
            color="primary"
            variant={progress === 100 ? "indeterminate" : "determinate"}
            value={progress < 100 ? progress : undefined}
            sx={{
              height: 32, borderRadius: 0, backgroundColor: "#D9D9D9",
            }}
          />
        </Box>
      </>)}
    </Box>
  ) : null;
}

export default SavingAnswers;