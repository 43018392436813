import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import useStore from "utils/reportStore";

export default function Heading({ active, ...props }) {
    const sections = useStore((state) => state.sections);

    return (
        active ? (
            <Box
                display="flex"
                justifyContent="space-between"
                borderBottom="0.5px solid rgba(0, 0, 0, 0.6)"
                {...props}
            >
                <Box display={"flex"} flexDirection={"column"}>
                    <Typography
                        variant="fontFamily"
                        sx={{
                            fontSize: 24,
                            fontWeight: 500,
                        }}>
                        TOEFL Grade Report
                    </Typography>
                    <Box display={"flex"} mt={1} mb={2}>
                        {
                            sections.map((section, index) =>
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    color='#626060'
                                    fontSize='14px'
                                    key={index}
                                >
                                    <CheckCircleOutlineOutlinedIcon
                                        color="inherit"
                                        fontSize="inherit"
                                    />
                                    &nbsp;{section.name}&nbsp;&nbsp;
                                </Box>
                            )
                        }
                    </Box>
                </Box>
            </Box>
        ) : null
    );
}