import React from 'react';
import AutoComplete from 'components/AutoComplete';
import { getCities } from 'services/geodbService';
import lodash from 'lodash';

const OtherOption = { id: 'other', city: 'Other' }

function CitySelect({ onSelected, error, width }) {
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [value, setValue] = React.useState(null);

  const getCitiesDebounced = lodash.debounce(async (e) => {
    try {
      const input = e.target.value;

      if (input.trim().length < 3) {
        return;
      }

      setLoading(true);

      setOptions([]);

      const cities = await getCities(input);

      cities.push(OtherOption);

      setOptions(cities);
    } catch (error) {
      console.error(error);
      setOptions([OtherOption]);
    } finally {
      setLoading(false);
    }
  }, 1000);

  const onSelect = (e, option) => {
    setValue(option);
    if (onSelected) onSelected(option.city);
  }

  return (
    <AutoComplete
      id="city"
      name="city"
      filterOptions={(x) => x}
      loading={loading}
      containerProps={{ flexGrow: 1, width: width || '300px' }}
      getOptionLabel={(option) => option.city}
      value={value}
      options={options}
      onSelect={onSelect}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      placeholder="Type your city name..."
      onInput={getCitiesDebounced}
      error={error}
    />
  );
}

export default CitySelect;