import React from 'react';

import Box from '@mui/material/Box';
import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import { PartsNavigation } from 'components/IELTSMock/Reading';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import SectionShell from 'components/IELTSMock/SectionShell';
import PartDescription from 'components/PartDescription';
import WritingQuestion from './WritingQuestion';

import useStore from 'utils/ieltsStore';

function WritingSection({ onTimeout }) {
  const [current, setCurrent] = React.useState(0);


  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(() => groups?.[question.group], [question, groups]);

  const setPartIndex = useStore(state => state.setPartIndex);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);

  const handleNext = () => setCurrent(curr => curr + 1);

  React.useEffect(() => {
    setPartIndex(0);
    setCurrentQuestion(0);
  }, []);

  return (
    <SectionShell
      active
      timerProps={{
        totalTime: time[current],
        onTimeout: current === 0 ? handleNext : onTimeout
      }}
      headerProps={{ hideFinish: current !== 1, disableFinish: false }}
      footerProps={{
        hide: current !== 1,
        disableNext: partIndex + 1 === parts.length,
        disablePrev: partIndex === 0,
        children: <PartsNavigation />
      }}
    >
      <SectionInstructions
        type="ielts"
        active={current === 0}
        onNext={handleNext}
        name={"Writing"}
        time={duration}
      />
      {current === 1 && (
        <>
          <PartDescription
            part={partIndex + 1}
            content={part?.description}
          />
          <QuestionsContainer>
            <Box
              display="flex" flex="1 1 auto" minHeight="56vh"
              justifyContent="space-between"
            >
              <Box p={2} pt={3}>
                {group?.content && (
                  <Box
                    width="100%" fontSize={'1.8vmin'}
                    dangerouslySetInnerHTML={{ __html: group.content }}
                  />
                )}
                {group?.image && (
                  <Box p={2} border="1px solid lightgrey" m={2}>
                    <img
                      alt="Part"
                      src={group?.image}
                      width="100%"
                    />
                  </Box>
                )}
              </Box>
              <WritingQuestion active question={question} />
            </Box>
          </QuestionsContainer>
        </>
      )}
    </SectionShell>
  );
}

export default WritingSection;