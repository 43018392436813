import React from 'react';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import YesIcon from '@mui/icons-material/CheckCircle';
import NoIcon from '@mui/icons-material/Cancel';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  comparisionsContainer: {
    width: 510,
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: 110,
    }
  },
  comparisions: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      animation: "$spin_comparision 6s infinite"
    },
  },
  "@keyframes spin_comparision": {
    "10%": { transform: 'translateX(-105%)' },
    "25%": { transform: 'translateX(-100%)' },
    "35%": { transform: 'translateX(-205%)' },
    "50%": { transform: 'translateX(-200%)' },
    "60%": { transform: 'translateX(-305%)' },
    "75%": { transform: 'translateX(-300%)' },
    "85%": { transform: 'translateX(-405%)' },
    "100%": { transform: 'translateX(-400%)' },
  },
  column: {
    width: 150, minWidth: 150,
    [theme.breakpoints.down("md")]: {
      width: 110, minWidth: 110,
    },
  },
  staticColumn: {
    width: 150, backgroundColor: "#49B6FF33",
    [theme.breakpoints.down("md")]: {
      width: 100, minWidth: 100,
    },
  },
  staticImage: {
    height: 32,
    [theme.breakpoints.down("md")]: {
      height: 24,
    },
  },
  image: {
    [theme.breakpoints.down("md")]: {
      height: 24,
    },
  },
}));

const comparisions = [
  [{
    icon: "https://assets.languify.in/images/comparision-icon-3.svg",
    title: "Diagnostic Test (30 minutes)",
    subtitle: "With all 4 sections"
  }, true, false, false, false],
  [{
    icon: "https://assets.languify.in/images/comparision-icon-2.svg",
    title: "Full TOEFL/IELTS (3 Hr)",
    subtitle: "Containing all 4 sections"
  }, true, true, true, true],
  [{
    icon: "https://assets.languify.in/images/comparision-icon-1.svg",
    title: "AI Based Feedback",
    subtitle: "For all 4 sections",
  }, true, false, false, false],
];

const columns = [
  {
    imageSrc: "https://assets.languify.in/images/testglider-logo.svg",
    alt: "testglider_logo",
    height: 24,
    availablilities: [false, true, false,],
  },
  {
    imageSrc: "https://assets.languify.in/images/ets-logo.svg",
    alt: "ets_logo",
    height: 32,
    availablilities: [false, true, false,],
  },
  {
    imageSrc: "https://assets.languify.in/images/tcy-logo.svg",
    alt: "tcy_logo",
    height: 64,
    availablilities: [false, true, false,],
  },
  {
    imageSrc: "https://assets.languify.in/images/ets-logo.svg",
    alt: "ets_logo",
    height: 32,
    availablilities: [false, true, false,],
  },
  {
    imageSrc: "https://assets.languify.in/images/testglider-logo.svg",
    alt: "testglider_logo",
    height: 24,
    availablilities: [false, true, false,],
  },
];

function Comparators() {
  return (
    <Box width="40%">
      <Box
        display="flex" alignItems="center" width="100%" textAlign="center"
        maxWidth={1200} borderBottom="1px solid grey" minHeight={72}
      />
      {comparisions.map((c, i) => (
        <ListItem
          key={i} sx={{ borderBottom: "1px solid grey", minHeight: '13vh' }}
        >
          <ListItemAvatar>
            <Avatar src={c[0].icon} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{ fontSize: '2vmin', fontWeight: 'bold' }}
            secondaryTypographyProps={{ fontSize: '1.6vmin', }}
            primary={c[0].title} secondary={c[0].subtitle}
          />

        </ListItem>
      ))}
    </Box>
  );
}

function PlatformComparisions(props) {
  const classes = useStyles();

  return (
    <Box
      display="flex" flexDirection="column" alignItems="center"
      justifyContent="center" width="100%" py={5}
    >
      <Typography fontSize="4vmin !important" textAlign="center" mx={4}>
        See how Languify compares against other platforms
      </Typography>
      <br />
      <Box display="flex" width="100%" alignItems="stretch" maxWidth={1000} mx="auto">
        <Comparators />
        <Box className={classes.staticColumn}>
          <Box p={2} minHeight={72} borderBottom="1px solid grey">
            <img
              src="https://assets.languify.in/images/lang-logo.svg"
              alt="Languify"
              className={classes.staticImage}
            />
          </Box>
          {[2, 3, 4].map((i, index) => (
            <Box
              key={index} sx={{ borderBottom: "1px solid grey" }}
              minHeight={'13vh'} display="flex" alignItems="center"
              justifyContent="center"
            >
              <YesIcon color="subprimary" />
            </Box>
          ))}
        </Box>
        <Box className={classes.comparisionsContainer}>
          <Box className={classes.comparisions} >
            {columns.map((column, i) => (
              <Box key={i} className={classes.column}>
                <Box minHeight={72} borderBottom="1px solid grey" display="flex" alignItems="center" justifyContent='center'>
                  <img
                    src={column.imageSrc} alt={column.alt} height={column.height} />
                </Box>
                {column.availablilities.map((i, index) => (
                  <Box
                    key={index} sx={{ borderBottom: "1px solid grey" }}
                    minHeight={'13vh'} display="flex" alignItems="center"
                    justifyContent="center"
                  >
                    {i ? <YesIcon color="subprimary" /> : <NoIcon />}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PlatformComparisions;