import { Box, Typography } from "@mui/material";
import { Sections } from "./constants";
import useStore from "utils/reportStore";

export default function CustomTips({ active }) {

    const section = useStore((state) => state.section);
    const sectionName = useStore((state) => state.sections[section]?.name?.toUpperCase());

    const SECTION = Sections?.[sectionName];

    return (
        (SECTION && active) ? (
            <Box
                backgroundColor='rgba(243, 243, 243, 0.5)'
                borderRadius={2} p={4}
            >
                <Typography
                    fontStyle='inter'
                    fontSize='18px'
                    fontWeight={600}
                    color='#235598'
                >
                    {SECTION?.tipTitle}
                </Typography>
                <ul>
                    {
                        SECTION?.tips?.map((tip, index) => (
                            <li
                                key={index}
                                style={{
                                    fontStyle: 'inter',
                                    marginBottom: '4px',
                                    fontSize: 14
                                }}
                            >
                                {tip}
                            </li>))
                    }
                </ul>
            </Box>
        ) : null
    );
}