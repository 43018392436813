import * as React from 'react';
import Box from '@mui/material/Box';
import MuiAccordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';

const Accordion = styled((props) => (
    <MuiAccordion expanded={props.open} disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    '&:before': {
        display: 'none',
    },
    '& .MuiAccordionSummary-root': {
        minHeight: 30,
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon color={(props.open) ? 'primary' : 'white'} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        style={{
            backgroundColor: (props.open) ? '#ffffff' : '#02569D',
            border: (props.open) ? '1px solid #02569D' : 'none',
            borderRadius: 4, height: 35,
        }}
        {...props}
    />
))(({ theme }) => ({
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

export default function CustomAccordion({ open, index, label, onChange, children }) {

    const handleChange = (event, state) => onChange(index, state);

    return (
        <>
            <Accordion
                style={{ boxShadow: 'none' }}
                onChange={handleChange}
                open={open}
            >
                <AccordionSummary open={open} >
                    <Typography
                        fontFamily='inter' fontSize={15} fontWeight={500}
                        color={open ? '#02569D' : '#ffffff'}
                    >
                        {label}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: '5px' }} >
                    <Box>
                        {children}
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
}