import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ContentShell(
  { hideLogo, children, light, forwardedRef, ...props }
) {
  return (
    <Box
      display="flex" height="100vh" maxHeight="-webkit-fill-available"
      width="100%" alignItems="center" justifyContent="center"
      position="relative" backgroundColor={light ? "#ffffff" : "#001929"}
      {...props}
    >
      {children}
      {!hideLogo && (<Box width="100%" position="absolute" bottom={8}>
        <Box mx="auto" width="fit-content" textAlign="center">
          <Typography
            variant="subtitle2" component="div" mb={0.5} fontSize={12}
            color={light ? "#000000" : "#FFFFFF"}
          >
            Powered By
          </Typography>
          <img
            src={"https://assets.languify.in/images/lang-logo.svg"}
            alt="languify"
            height={'40vh'}
          />
        </Box>
      </Box>)}
    </Box>
  );
}

export default ContentShell;