import React from 'react';
import Box from '@mui/material/Box';
import LeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import RightIcon from '@mui/icons-material/KeyboardArrowRight';
import Button from 'components/Button';
import useStore from 'utils/ieltsStore';

const styles = {
  button: { padding: 8, minWidth: 'fit-content' }
};
function SectionFooter({ disablePrev, disableNext, hideNav, children }) {
  const [next, prev] = useStore((state) => [state.next, state.prev]);

  return (
    <Box
      display="flex" justifyContent="space-between" alignItems="center" px={6}
      py={2} bgcolor="white.main" boxShadow="0px -4px 24px rgba(0, 0, 0, 0.1);"
      position="sticky" bottom={0}
    >
      <Box flexGrow={1}>
        {children}
      </Box>
      {!hideNav && (<Box flexShrink={0}>
        <Button
          variant="contained" style={styles.button} onClick={prev}
          disabled={disablePrev}
        >
          <LeftIcon />
        </Button>
        <Button
          variant="contained" style={styles.button} onClick={next}
          disabled={disableNext}
        >
          <RightIcon />
        </Button>
      </Box>
      )}
    </Box>
  );
}

export default SectionFooter;