import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import BeforeIcon from '@mui/icons-material/NavigateBefore';
import NextIcon from '@mui/icons-material/NavigateNext';
import { LightButton } from 'components/Button';

import { blogs } from "./constant";

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400, borderRadius: 3, padding: "24px 24px 0px 24px",
    minHeight: 370,
    marginRight: 16, flexShrink: 0, [theme.breakpoints.down("md")]: {
      maxWidth: '85vw'
    },
  },
  blogsContainer: {
    display: "flex", marginLeft: "auto", marginRight: "auto", width: "90vw",
    overflow: "auto", alignItems: "center",
    "&::-webkit-scrollbar": {
      display: 'none',
    }
  },
  overflow: {
    "--max-lines": 3,
    textAlign: "justify",
    position: "relative",
    overflow: "hidden",
    maxHeight: "60px",
    paddingRight: "1rem",
    "&::before": {
      position: "absolute",
      content: '"..."',
      bottom: 0,
      right: 0,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      right: 0,
      width: "1rem",
      height: "1rem",
      background: "white",
    }
  }
}));

function BlogCard(props) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardMedia
        component="img" height="140" image={props.imageUrl} alt={props.title}
        style={{ borderRadius: 8 }}
      />
      <CardContent>
        <Typography
          gutterBottom component="div" fontWeight={600} fontSize={14}
        >
          {props.title}
        </Typography>
        <div style={{ overflow: 'hidden' }}>
          <Typography
            variant="body2" color="text.secondary"
            className={classes.overflow}
          >
            {props.summary}
          </Typography>
        </div>
      </CardContent>
      <CardActions>
        <LightButton
          size="small" color="primary" LinkComponent={"a"} href={props.blogUrl}
          target="_blank" rel="noreferrer"
          endIcon={<ArrowCircleRightIcon color="primary" />}
          style={{ fontWeight: 600, fontSize: 13, padding: '8px 16px' }}
        >
          Read More
        </LightButton>
      </CardActions>
    </Card>
  );
}



function Blogs(props) {
  const classes = useStyles();
  const blogsContainer = React.createRef();
  const intervalId = React.useRef(0);

  function sideScroll(element, direction, speed, distance, step) {
    if (intervalId.current) clearInterval(intervalId.current);

    let scrollAmount = 0;
    intervalId.current = setInterval(function () {
      if (direction === 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) clearInterval(intervalId.current);
    }, speed);
  }

  return (
    <Box backgroundColor="#02569D" px={2} pt={5} pb={8} width="100%" id="blogs">
      <Typography
        color="white.main" mb={4} ml={2} fontWeight="bold" fontSize="3.0vmax"
        lineHeight="3.5vmax" variant="h1"
      >
        Effective Test Preparation Tips by Languify
      </Typography>
      <Box
        width="100%" overflow="auto" display="flex" alignItems="center"
        position="relative"
      >
        <IconButton
          style={{ position: 'absolute', zIndex: 100, left: 0 }}
          onMouseDown={() => sideScroll(blogsContainer.current, 'left', 5, 250, 5)}
        >
          <BeforeIcon fontSize="large" />
        </IconButton>
        <Box
          className={classes.blogsContainer}
          ref={blogsContainer}
        >
          {blogs.map((blog, i) => (<BlogCard key={i} {...blog} />))}
        </Box>
        <IconButton
          style={{ position: 'absolute', zIndex: 100, right: 0 }}
          onMouseDown={() => sideScroll(blogsContainer.current, 'right', 5, 250, 5)}
        >
          <NextIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Blogs;