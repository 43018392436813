import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Button from 'components/Button';
import ContentShell from 'components/ContentShell';
import RateExperience from 'components/RateExperience';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Box, IconButton, MobileStepper, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { autoPlay } from 'react-swipeable-views-utils';
import Session from 'utils/Session';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reportDetails = [
  {
    img: 'https://assets.languify.in/images/sample-report-01.png',
    label: 'Know your IELTS/TOEFL score',
  },
  {
    img: 'https://assets.languify.in/images/sample-report-02.png',
    label: 'Detailed feedback for all 4 sections',
  },
  {
    img: 'https://assets.languify.in/images/sample-report-03.png',
    label: 'Achieve your target score with review & practice',
  },
  {
    img: 'https://assets.languify.in/images/sample-report-04.png',
    label: 'AI-Based speaking test assessment for TOEFL/IELTS ',
  },
];

const styles = {
  container: { alignSelf: 'center' },
  slide: {
    display: "flex", alignItems: "center", justifyContent: 'center',
    width: '100%', marginLeft: 'auto', marginRight: 'auto', padding: 16,
  },
  imageContainer: {
    backgroundColor: "#FFF", boxShadow: "0 0 8px 0px whitesmoke",
    borderRadius: 16, overflow: 'hidden', padding: 8
  },
  image: {
    objectFit: 'cover', width: 'calc(40vw - 32px)', height: "auto",
  }
};

const headings = [
  "Know your score",
  "Detailed feedback for all 4 sections",
  "Achieve your target score with review & practice",
  "",
  "",
];

function ReportCarousal(props) {
  const { type } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const TotalSteps = type.toLowerCase() === "ielts" ? 3 : 5;
  const handleNext = () => setActiveStep(step => step + 1 > TotalSteps - 1 ? 0 : step + 1);
  const handlePrev = () => setActiveStep(step => step - 1 >= 0 ? step - 1 : TotalSteps - 1);

  return (
    <Box mx={6}>
      {type.toLowerCase() === "ielts" && (
        <Typography
          mt={1}
          color="subprimary.main"
          variant="body2"
          textAlign="center"
          fontWeight={700}
        >
          {headings[activeStep]}
        </Typography>
      )}
      <AutoPlaySwipeableViews
        loop
        enableMouseEvents
        index={activeStep}
        onChangeIndex={handleStepChange}
        style={styles.container}
        slideStyle={styles.slide}
        resistance
      >
        {reportDetails.map(({ img, label }, index) => (
          <Box>
            <Typography
              color='#49B6FF' fontSize={18} fontWeight={600}
              textAlign='center' fontFamily='inter'
            >
              {label}
            </Typography> <br />
            <div key={index} style={styles.imageContainer}>
              <img
                src={img}
                alt={index}
                style={styles.image}
              />
            </div>
          </Box>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        variant="dots"
        steps={TotalSteps}
        position="static"
        activeStep={activeStep}
        sx={{ maxWidth: 'fit-content', mx: 'auto', backgroundColor: "transparent" }}
        nextButton={
          <IconButton
            size="small"
            onClick={handleNext}
          >
            <KeyboardArrowRight />
          </IconButton>
        }
        backButton={
          <IconButton
            size="small"
            onClick={handlePrev}
          >
            <KeyboardArrowLeft />
          </IconButton>
        }
      />
      <Typography
        color='#ffffff' fontSize={15} fontWeight={400}
        textAlign='center' fontFamily='Montserrat'
      >
        Note: This is just a sample report. Your actual report
        will be personalised with your own test results.
      </Typography>
    </Box>
  );
}

function RegistrationChoice(props) {
  const navigate = useNavigate();
  const { type } = useParams();
  const [queryParams] = useSearchParams();

  const handleClick = () => {

    if (Session.isLoggedIn()) {
      navigate('/home');
    } else {
      navigate(`/${type}/register?aid=${queryParams.get("aid")}`);
    }
  }

  return (
    <ContentShell dark hideLogo>
      <Box p={4} width="100%">
        <Box
          bgcolor="primary.dark" display="flex" alignItems="center"
          minHeight="70vh" borderRadius={2}
        >
          <Box width="60%">
            <ReportCarousal />
            <br />
          </Box>
          <Box
            width="40%" display="flex" alignItems="left"
            justifyContent="center" flexDirection="column"
          >
            <Typography
              color="white.main" variant="h4" fontWeight="bold" mb={4}
            >
              View your personalised report!
            </Typography>
            <Typography color="#41D7A1" fontSize={16} mb={1}>
              Congratulations on completing the Languify {type?.toUpperCase()} mock!
            </Typography>


            {(Session.isLoggedIn()) ?
              <>
                <Typography color="white.main" fontSize={16} fontWeight={500}>
                  We have sent the report to your registered email address,
                  which includes:
                </Typography>
                <ul >
                  <li style={{ color: 'white', marginBottom: 6 }} >
                    Your {type?.toUpperCase()} score
                  </li>
                  <li style={{ color: 'white' }}>
                    Detailed feedback for each section of the test
                  </li>
                </ul>
              </>
              : <>
                <Typography color="white.main" fontSize={16} fontWeight={500}>
                  By registering for FREE, you'll get:
                </Typography>
                <ul >
                  <li style={{ color: 'white', marginBottom: 6 }} >
                    Your personalised feedback report, including: <br />
                    <Typography fontSize={14} fontWeight={400}>
                      - Your {type?.toUpperCase()} score <br />
                      - Detailed feedback for each section of the test
                    </Typography>
                  </li>
                  <li style={{ color: 'white' }}>
                    A profile setup on the Languify platform,
                    where you can <br /> access the Dashboard to
                    view your past performances.
                  </li>
                </ul>

              </>
            }
            <Box
              width="100%" display="flex" alignItems="center" height={120}
              justifyContent="left" gap={1}
            >

              <Button
                variant="contained" color="subprimary"
                style={{ color: '#FFF', padding: "8px 16px" }}
                onClick={handleClick}
              >
                {(Session.isLoggedIn()) ?
                  'View Past Performances' :
                  'Great, Register now'}
              </Button>

              <Button
                variant="outlined" color="white"
                onClick={() => {
                  (Session.isLoggedIn()) ?
                    navigate('/') :
                    navigate("/choose-test")
                }}
                style={{
                  padding: "8px 16px",
                  borderColor: '#2DABFF',
                }}
              >
                {(Session.isLoggedIn()) ?
                  'Back to Home' :
                  "I  don't want to know my score"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <RateExperience active />
    </ContentShell>
  );
}

export default RegistrationChoice;