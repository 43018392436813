import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Button } from 'components/Button';

const instructions = [
  "Your reading and listening answers have been saved successfully.",
  "Please make sure you are seated in a noise-free environment with good quality headphones to ensure proper recording of your speaking task answer.",
  "Note: You will be introduced with a microphone test before the speaking assessment",
  "You may use your notes to help prepare your response.",

  "For accurate grading, make sure you are in a noise-free environment that is similar to an ETS testing center.",
  "Your score might be affected if the audio includes any sound other than the speaker's voice.",
  <><b>Note: You cannot re-record the answer in the speaking assessment. Hence it is advised to check your audio properly in the microphone test itself.</b></>,
  "Considering the above note please speak clearly and loudly.",
];

function ListItem(props) {
  return (
    <Typography variant="body2" mt={1} color="#535353" {...props} display="inline-flex">
      <span
        style={{
          marginTop: 6, marginRight: 16, backgroundColor: "#02569d",
          padding: 4, height: 8, borderRadius: "50%"
        }}
      />
      <span style={{ display: "inline-block" }}>{props.children}</span>
    </Typography>
  );
}

function PreSpeaking({ onNext, active }, ref) {
  return active ? (
    <Box
      display="flex" width="100%" height="fit-content"
      justifyContent="center" position="relative" mx="auto" maxWidth={650}
      flexDirection="column" backgroundColor="#FFFFFF" borderRadius={2}
      px={6} py={2} ref={ref} boxShadow='0px 4px 24px rgba(0, 0, 0, 0.16)'
    >
      <Box style={{
        position: 'absolute', top: "-24px", textAlign: "center",
        width: '-webkit-fill-available',
      }}>
      </Box>
      <Typography variant="overline" fontWeight="bold" color="#838383">
        NEXT SECTION:&nbsp;SPEAKING
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        Before we Start
      </Typography>
      {instructions.map((instruction, i) => (
        <ListItem key={i}>{instruction}</ListItem>
      ))}
      <br /><br />
      <Button onClick={onNext} variant="contained">
        Begin with Microphone test
      </Button>
    </Box>
  ) : null;
}

export default React.forwardRef(PreSpeaking);