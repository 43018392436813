import React from 'react';
import SpeechRecorder from 'languify-speech-recorder';

import AudioPassage from 'components/AudioPassage';
import Brief from 'components/Brief';
import MicrophoneTest from './MicrophoneTest';
import Passage from 'components/Passage';
import PreSpeaking from './PreSpeaking';
import RecordQuestion from './RecordQuestion';
import SectionInstructions from 'components/TOEFLMock/SectionInstructions';
import SectionShell from 'components/TOEFLMock/SectionShell';

import useStore from 'utils/toeflStore';

function SpeakingSection({ onTimeout }, ref) {
  const [current, setCurrent] = React.useState(0);
  const [subSection, setSubSection] = React.useState(-1);
  const speechRecorder = React.useRef(null);

  const next = useStore(state => state.next);
  const currentQuestion = useStore(state => state.currentQuestion);
  const { questions, time } = useStore(state => state.sections[state.section]);
  const name = useStore(state => state.sections[state.section]?.name?.toUpperCase());

  const question = React.useMemo(() => questions[currentQuestion], [questions, currentQuestion]);

  const currentSection = question?.subSections?.[subSection];

  const handleQuestionRecorded = React.useCallback(() => {
    if (currentQuestion + 1 === questions.length) {
      onTimeout();
      setSubSection(-1);
    } else {
      next();
      setSubSection(0);
    }
  }, [next, currentQuestion, questions, onTimeout]);

  const nextSubSection = () => setSubSection(ss => ss + 1);

  const [timerProps, sectionHeadProps] = React.useMemo(() => {
    let _timerProps = {};
    let _sectionHeadProps = {};

    switch (currentSection) {
      case 'BRIEF': {
        _timerProps = {
          totalTime: question?.brief?.time || 60,
          timerText: 'Automatically proceeds in:',
          onTimeout: nextSubSection,
        };
        _sectionHeadProps.hidePrev = true;
        _sectionHeadProps.onNext = nextSubSection;
        _sectionHeadProps.nextAction = "Next";
        _sectionHeadProps.notQuestion = true;
        break;
      }
      case 'AUDIO': {
        _timerProps.hideTimer = true;
        _sectionHeadProps.hideActions = true;
        break;
      }
      case 'PASSAGE': {
        _timerProps = {
          totalTime: question?.passage?.time || 180,
          timerText: 'Time remaining for reading passage:',
          onTimeout: nextSubSection,
        };
        _sectionHeadProps.hidePrev = true;
        _sectionHeadProps.onNext = nextSubSection;
        _sectionHeadProps.nextAction = "Next";
        _sectionHeadProps.notQuestion = true;
        break;
      }
      case 'QUESTION': {
        _timerProps.hideTimer = true;
        _sectionHeadProps.hideActions = true;
        break;
      }
      default: ;
    }

    return [_timerProps, _sectionHeadProps];
  }, [currentSection, question]);

  React.useEffect(() => {
    if (name === 'SPEAKING') {
      speechRecorder.current = new SpeechRecorder({ isMobile: false });

      return () => speechRecorder.current?.destructor();
    }
  }, [name]);

  React.useEffect(() => {
    if (current === 3) setSubSection(0);
  }, [current]);

  return (
    <div ref={ref}>
      <PreSpeaking onNext={() => setCurrent(1)} active={current === 0} />
      <MicrophoneTest active={current === 1} onSuccess={() => setCurrent(2)} />
      <SectionInstructions
        type="toefl"
        active={current === 2}
        name='SPEAKING' title='Speaking Test' section={1} time={time}
        onNext={() => setCurrent(3)}
        totalQuestions={questions.length}
      />
      <SectionShell
        active={current === 3}
        timerProps={timerProps}
        sectionHeadProps={sectionHeadProps}
      >
        {current === 3 && (question?.subSections?.map((ss, i) => {
          let Component, props = {};
          switch (ss) {

            case "BRIEF": {
              Component = Brief;
              props = {
                active: subSection === i,
                onNext: nextSubSection,
                content: question?.brief?.content,
              };
              break;
            }
            case "PASSAGE": {
              Component = Passage;
              props = {
                active: subSection === i,
                title: "Read the passage below and answer the upcoming questions.",
                children: question?.passage?.content
              };
              break;
            }
            case "AUDIO": {
              Component = AudioPassage;
              props = {
                active: subSection === i,
                audioPassage: question.audioPassage,
                playAudio: subSection === i,
                onCompleted: nextSubSection
              };

              break;
            }
            case "QUESTION": {
              Component = RecordQuestion;
              props = {
                active: subSection === i,
                start: subSection === i,
                onFinished: handleQuestionRecorded,
                speechRecorder: speechRecorder,
                question,
              };
              break;
            }
            default: return null;
          }

          return <Component key={i} {...props} />
        }))}
      </SectionShell>
    </div>
  );
}

export default React.forwardRef(SpeakingSection);