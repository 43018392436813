import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FIB from 'components/FIB';
import PartDescription from 'components/PartDescription';
import { PartsNavigation } from 'components/IELTSMock/Reading';
import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import SCQ from 'components/SCQ';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import SectionShell from 'components/IELTSMock/SectionShell';
import SpeakerTest from './SpeakerTest';

import { playAudio } from 'utils';
import useStore from 'utils/ieltsStore';
import { useConnected, useSnackbar } from 'contexts';
import MarkParaGroup from 'components/MarkParaGroup';
import MCQ from 'components/MCQ';

function ListeningSection({ onTimeout }) {
  const snackbar = useSnackbar();

  const connected = useConnected();

  const [current, setCurrent] = React.useState(0);

  const audioPlayed = React.useRef(new Set());

  const audioRef = React.useRef(null);

  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);
  const playing = useStore(state => state.playing);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(
    () => groups?.[question.group], [question, groups]
  );

  const handleNext = () => setCurrent((x) => x + 1);

  React.useEffect(() => {
    if (audioRef.current) {
      if (!audioRef.current.paused && !playing) audioRef.current.pause();

      if (audioRef.current.paused && playing) audioRef.current.play();
    }
  }, [playing]);

  React.useEffect(() => {
    if (!audioPlayed.current.has(partIndex) && current === 2) {
      if (connected) {
        audioPlayed.current.add(partIndex);

        if (audioRef.current !== null) {
          audioRef.current.currentTime = 0;
          audioRef.current.pause();
        }

        audioRef.current = playAudio(
          part?.audio || null,
          () => {
            snackbar.error(
              "Unable to play audio! Check your network connection"
            );
          });

        return () => {
          audioRef.current.currentTime = 0;
          audioRef.current.pause();
        };
      } else {
        snackbar.error("Unable to play audio! Check your network connection");
      }
    }
  }, [partIndex, part, current, connected]);

  const footerProps = React.useMemo(() => ({
    hide: current !== 2,
    children: <PartsNavigation />,
    disableNext: currentQuestion + 1 === questions?.length,
    disablePrev: currentQuestion === 0,
  }), [current, currentQuestion, questions]);

  const partRange = part?.questionsRange?.join("-");
  const groupRange = group?.questionsRange;

  const groupQuestions = questions?.slice(groupRange[0] - 1, groupRange[1]) || [];

  return (
    <SectionShell
      active
      headerProps={{ hideFinish: current !== 2 }}
      footerProps={footerProps}
      timerProps={{
        totalTime: time[current],
        onTimeout: current !== 2 ? handleNext : onTimeout
      }}
    >
      <SpeakerTest active={current === 0} onNext={handleNext} />
      <SectionInstructions
        type="ielts"
        active={current === 1}
        name='Listening'
        time={duration}
        onNext={handleNext}
      />
      {current === 2 && (
        <>
          <PartDescription
            part={partIndex + 1}
            content={part.description + partRange}
          />
          <QuestionsContainer>
            {group?.questionsRange && (
              <Typography fontWeight={600} variant="subtitle1" mb={1}>
                Questions {groupRange?.join("-")}
              </Typography>
            )}

            {group?.instruction && (
              <Typography
                color="#00000099" mb={2} dangerouslySetInnerHTML={{
                  __html: group?.instruction,
                }}
              />
            )}

            {group?.type === 'mcq' && (
              groupQuestions?.map((question, i) => (i % 2 === 0) ? (
                <Box display="flex" width="100%" key={i} columnGap={2}>
                  <MCQ
                    active
                    index={groupRange[0] + i - 1}
                    currentQuestion={currentQuestion}
                    question={question}
                    width="50%"
                  />
                  {(i + 1 < groupQuestions?.length) && (
                    <MCQ
                      active
                      index={groupRange[0] + i}
                      currentQuestion={currentQuestion}
                      question={groupQuestions?.[i + 1]}
                      width="50%"
                    />)}
                </Box>
              ) : null)
            )}

            {group?.type === 'scq' && (
              groupQuestions?.map((question, i) => (i % 2 === 0) ? (
                <Box display="flex" width="100%" key={i} columnGap={2}>
                  <SCQ
                    active
                    alwaysOpen
                    index={groupRange[0] - 1 + i}
                    width="50%"
                    currentQuestion={currentQuestion}
                    question={question}
                  />
                  {(i + 1 < groupQuestions?.length) && (
                    <SCQ
                      active
                      alwaysOpen
                      index={groupRange[0] + i}
                      width="50%"
                      currentQuestion={currentQuestion}
                      question={groupQuestions?.[i + 1]}
                    />)}
                </Box>
              ) : null)
            )}

            {group?.type === 'fib' && (
              <Box display="flex" width="100%" columnGap={2}>
                {(group?.image || group?.content) && (
                  <Box width="50%" border="1px solid grey" m={2}>
                    {group?.content && (
                      <Box
                        width="100%"
                        m={2} dangerouslySetInnerHTML={{
                          __html: group?.content || ""
                        }}
                      />
                    )}

                    {group?.image && (
                      <img
                        alt="Part"
                        src={group?.image}
                        width="100%"
                      />
                    )}
                  </Box>
                )}

                <Box m={2}>
                  {groupQuestions?.map((question, i) => (
                    <FIB
                      key={i}
                      index={groupRange[0] - 1 + i}
                      question={question}
                    />
                  ))}
                </Box>
              </Box>
            )}

            {group?.type === "mark_para" && (
              <MarkParaGroup
                currentQuestion={currentQuestion}
                group={group}
                groupRange={groupRange}
                groupQuestions={groupQuestions}
              />
            )}
          </QuestionsContainer>
        </>
      )}
    </SectionShell>
  );
}

export default ListeningSection;