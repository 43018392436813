import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircleProgressbar from "../CircleProgressbar";
import Title from "../Title";
import { IELTSLevels } from "./constants";

export default function OverallPerformance({ active, score }) {

    return (
        active ? (
            <>
                <Title title={'Overall Band Score'} />

                <Box my={4} display='flex' gap={6}>
                    <Box display="flex"
                        flexDirection="column"
                        alignItems="center"
                        fontSize="12px"
                        width={250}
                        justifyContent='center'
                    >
                        {(score >= 0) &&
                            <CircleProgressbar
                                value={score} size={100}
                                percent={false} color='#02569D'
                            />}
                    </Box>
                    <Box>
                        <Typography
                            fontSize='16px'
                            fontWeight={500}
                            fontFamily='inter'
                            mb={1} mt={1}
                        >
                            Skill Level :
                            <span style={{ color: 'black' }}> {IELTSLevels[score]?.label || 'NA'}</span>
                        </Typography>
                        <Typography
                            fontSize='14px'
                            fontWeight={500}
                            fontFamily='inter'
                            color='rgba(0, 0, 0, 0.6)'
                        >
                            {IELTSLevels[score]?.description || 'NA'}
                        </Typography>
                    </Box>
                </Box>
            </>
        ) : null
    );
}