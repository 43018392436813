import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStore from 'utils/toeflStore';

function Timer({
  hideTimer, timerText = "", totalTime, onTimeout, warningPercentange = 10
}) {
  const streamDuration = React.useRef(0);
  const previousTime = React.useRef(0);
  const requestAnimationFrameId = React.useRef(null);

  const timeLeft = useStore(state => state.timeLeft);
  const playing = useStore(state => state.playing);
  const stopped = useStore(state => state.stopped);
  const section = useStore(state => state.section);

  const start = useStore(state => state.start);
  const setTimeLeft = useStore(state => state.setTimeLeft);
  const stop = useStore(state => state.stop);

  const [warning, setWarning] = React.useState(false);

  const updateTimer = React.useCallback(() => {
    let now = performance.now();
    let dt = now - previousTime.current;

    let _timeLeft;
    if (dt >= 1000) {
      streamDuration.current = streamDuration.current + Math.round(dt / 1000);


      _timeLeft = totalTime - streamDuration.current;

      setTimeLeft(_timeLeft);

      previousTime.current = now;

      if (_timeLeft < 0) return;
    }
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [totalTime, warningPercentange]);

  const startTimer = React.useCallback(() => {
    if (requestAnimationFrameId.current) {
      cancelAnimationFrame(requestAnimationFrameId.current);
      requestAnimationFrameId.current = 0;
    }
    previousTime.current = performance.now();
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [updateTimer]);

  const startHandler = () => {
    streamDuration.current = 0;
    setWarning(false);
    startTimer();
  };

  const pauseHandler = () => {
    cancelAnimationFrame(requestAnimationFrameId.current);
  }

  const stopHandler = React.useCallback(() => {
    setWarning(false);
    cancelAnimationFrame(requestAnimationFrameId.current);
  }, []);

  const resumeHandler = () => {
    startTimer();
  };

  React.useEffect(() => {
    if (section >= 0) stopHandler();
  }, [section]);

  React.useEffect(() => {
    start(totalTime)
    startHandler();

    return () => stopHandler();
  }, [totalTime]);

  React.useEffect(() => {
    if (playing) resumeHandler();
    else pauseHandler();
  }, [playing]);

  React.useEffect(() => {
    if (playing && !stopped) {
      setWarning(Math.round((timeLeft / totalTime) * 100) <= warningPercentange);
      if (timeLeft <= 0) {
        stop();
        stopHandler();
        onTimeout();
      }
    }
  }, [timeLeft, playing, stopped, totalTime]);

  React.useEffect(() => {
    if (stopped && timeLeft > 0) stopHandler();
  }, [stopped, timeLeft, stopHandler]);


  return hideTimer ? null : (
    <Box style={{
      position: 'absolute', top: "-30px", textAlign: "center",
      width: '-webkit-fill-available',
    }}>
      <Typography
        style={{ fontSize: 14, fontWeight: 500, color: "white" }}
      >
        {timerText || "Time remaining for this section:"}
        <Typography
          variant="caption"
          style={{ fontSize: 14, fontWeight: 500, }}
          mx={'1ch'}
          color={warning ? "errorLight.main" : "white.main"}>
          {new Date((timeLeft || 0) * 1000).toISOString().substring(11, 19)}
        </Typography>
        minutes
      </Typography>
    </Box>
  );
}

export default Timer;