import React from 'react';
import shallow from 'zustand/shallow';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStore from 'utils/ieltsStore';
import { SectionColors } from './SectionChip';

function PartDescription({ part = 1, content = "Answer the questions" }) {
  const { type } = useStore((state) => state.sections[state.section] || {}, shallow);

  return (
    <Box
      boxShadow="0px 4px 24px rgba(0, 0, 0, 0.16)" display="flex"
      borderRadius={2} width="calc(100% - 64px)" position="sticky" top={0}
      mx={'auto'} alignItems="center"
    >
      <Typography
        component="div" px={2} py={0} style={{
          borderRadius: '8px 0 0 8px', width: "fit-content",
          fontSize: 14, fontWeight: 600, ...SectionColors[type],
          height: '100%', display: "flex", alignItems: "center"
        }}
      >
        {type}
      </Typography >
      <Typography fontWeight={600} fontSize={'2.2vmin'} py={2} ml={1}>
        Part {part}&nbsp;&nbsp;
      </Typography>
      <Typography color="#00000099" fontWeight={500} py={2} >
        {content}
      </Typography>
    </Box>
  );
}

export default PartDescription;