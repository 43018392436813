import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { MainCarousal } from "./constant";
import makeStyles from '@mui/styles/makeStyles';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  carousal: {
    maxWidth: "300px", minHeight: 320, height: 320, width: "50%",
    [theme.breakpoints.down("md")]: {
      minHeight: 200, height: 'fit-content',
      justifyContent: "space-evenly",
      width: "100%",
      maxWidth: "100%",
    },
  },
  slide: {
    display: "flex", alignItems: "center", justifyContent: "center",
    width: "100%",
  },
  image: {
    height: 300, display: 'block', width: '100%', padding: 16,
    [theme.breakpoints.down("md")]: {
      height: 180
    },
  }
}));

function ImageCarousel() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box className={classes.carousal}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        loop
        slideClassName={classes.slide}
      >
        {MainCarousal.map((step, index) => (
          <Box
            key={index}
            component="img" src={step.imgPath} alt={step.label}
            className={classes.image}
          />
        ))}
      </AutoPlaySwipeableViews>
      <Box sx={{
        width: 'fit-content', mx: 'auto', bgColor: 'transparent',
        color: 'white.main'
      }}>
        <Typography color="white.main">
          {activeStep + 1}. {MainCarousal[activeStep].label}
        </Typography>
        {!!MainCarousal[activeStep].options && (
          <Box ml={2}>
            {MainCarousal[activeStep].options.map((option, i) => (
              <Typography color="white.main" key={i}>&#9679; {option}</Typography>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default ImageCarousel;