const { IconButton } = require("@mui/material");

function BackToTopButton(props) {
  const goToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <IconButton {...props} onClick={goToTop}>
      <img
        src="https://assets.languify.in/images/arrow-blue-up.svg"
        alt="right-arrow"
        width={32}
        height={32}
      />
    </IconButton>
  );
}

export default BackToTopButton;