function QuestionProgress({ current, questions, ...props }) {
  return (
    <div style={{ display: "flex" }} {...props}>
      {questions.map((q, i) => (
        <div
          key={i}
          style={{
            height: 4, flexGrow: 1, marginLeft: 2, marginRight: 2,
            borderRadius: 2,
            backgroundColor:
              i < current ? "#235598" : "#23559830",
          }}
        />
      ))}
    </div>
  );
}

export default QuestionProgress;