import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import MicNoneIcon from '@mui/icons-material/MicNone';

import DialogTransition from 'components/DialogTransition';

const useStyles = makeStyles((theme) => ({
  button: { textTransform: 'none', margin: 6 },
  content: {
    display: 'flex', alignItems: 'center', flexDirection: 'column',
    justifyContent: 'flex-end', height: '80px',
  },
  listItem: {
    fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 500, fontSize: '12px',
    lineHeight: '15px', margin: '4px 0px'
  }, title: {
    fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 600, fontSize: '16px',
  }
}));


function MicrophoneTestErrorDialog({
  open = false, onRetake = () => { },
}) {
  const classes = useStyles();

  return (
    <Dialog
      disableEscapeKeyDown open={open} onClose={(e) => { }}
      TransitionComponent={DialogTransition} maxWidth="md"
    >
      <DialogContent sx={{ px: 8, py: 4 }}>
        <Box display="flex">
          <Box display={"flex"} flexDirection="column">
            <Box display="flex">
              <img
                src='https://assets.languify.in/images/error-icon.svg'
                alt='info'
              />
              <Typography ml={1} className={classes.title} color="error" >
                Something seems wrong!
              </Typography>
            </Box>
            <Box
              backgroundColor="#F4F5F5" borderRadius={1} mt={2} mr={2}
              minWidth={300} px={2} py={1}
            >
              <Typography fontSize={12} color="#00000066" fontWeight={500}>
                What can you do?
              </Typography>
              <ul style={{ marginTop: 0, marginBottom: 0, paddingLeft: 32 }}>
                <li className={classes.listItem}>
                  Sit in the noise free environment.
                </li>
                <li className={classes.listItem}>
                  Speak directly in the microphone.
                </li>
              </ul>
            </Box>
          </Box>
          <Box display={"flex"} justifyContent="center">
            <img
              src="https://assets.languify.in/images/mic-test-1.svg"
              alt="mic-test"
              width={"120px"}
            />
          </Box>
        </Box>
        <br />
        <br />
        <Button
          startIcon={<MicNoneIcon />} onClick={onRetake} variant="contained"
          size="small"
          style={{
            width: "100%", textTransform: "none", fontWeight: "normal"
          }}
        >
          Retake
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default MicrophoneTestErrorDialog;