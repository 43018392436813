import React from 'react';
import SectionInstructions from 'components/TOEFLMock/SectionInstructions';
import SectionShell from 'components/TOEFLMock/SectionShell';
import Passage from 'components/Passage';
import useStore from 'utils/toeflStore';
import AudioPassage from 'components/AudioPassage';
import WritingQuestion from './WritingQuestion';
import Brief from 'components/Brief';

function WritingSection({ onTimeout }, ref) {
  const [current, setCurrent] = React.useState(0);
  const [subSection, setSubSection] = React.useState(0);

  const { questions, time } = useStore(state => state.sections[state.section]);
  const nextQuestion = useStore((state) => state.next);
  const currentQuestion = useStore(state => state.currentQuestion);

  const nextSubSection = () => setSubSection(ss => ss + 1);

  const question = React.useMemo(
    () => questions[currentQuestion] || {}, [questions, currentQuestion]
  );


  const [timerProps, sectionHeadProps] = React.useMemo(() => {
    let _timerProps = {};
    let _sectionHeadProps = {};
    const currentSection = question?.subSections?.[subSection];

    switch (currentSection) {
      case 'BRIEF': {
        _timerProps = {
          totalTime: question?.brief?.time || 60,
          timerText: 'Automatically proceeds in:',
          onTimeout: nextSubSection,
        };
        _sectionHeadProps.hidePrev = true;
        _sectionHeadProps.onNext = nextSubSection;
        _sectionHeadProps.nextAction = "Next";
        _sectionHeadProps.notQuestion = true;
        break;
      }
      case 'AUDIO': {
        _timerProps.hideTimer = true;
        _sectionHeadProps.hideActions = true;
        break;
      }
      case 'PASSAGE': {
        _timerProps = {
          totalTime: question?.passage?.time || 180,
          timerText: 'Time remaining for reading passage:',
          onTimeout: nextSubSection,
        };
        _sectionHeadProps.hidePrev = true;
        _sectionHeadProps.onNext = nextSubSection;
        _sectionHeadProps.nextAction = "Next";
        _sectionHeadProps.notQuestion = true;
        break;
      }
      case 'QUESTION': {
        _timerProps = {
          timerText: 'Time remaining for this question:',
          totalTime: question.question.time,
          onTimeout:
            currentQuestion + 1 === questions.length ? onTimeout : nextQuestion,
        };
        _sectionHeadProps = { hidePrev: true, onNext: nextQuestion, };
        break;
      }
      default: ;
    }

    return [_timerProps, _sectionHeadProps];
  }, [subSection, question, currentQuestion, questions.length]);

  React.useEffect(() => {
    setSubSection(0);
  }, [currentQuestion]);

  return (
    <div ref={ref}>
      <SectionInstructions
        type="toefl"
        active={current === 0}
        name='WRITING' title='Writing Test' section={2} time={time}
        onNext={() => setCurrent(1)}
      />
      <SectionShell
        active={current === 1}
        timerProps={timerProps}
        sectionHeadProps={sectionHeadProps}
      >
        {current === 1 && (question?.subSections?.map((ss, i) => {
          let Component, props = {};
          let active = subSection === i;
          switch (ss) {
            case "BRIEF": {
              Component = Brief;
              props = {
                active,
                onNext: nextSubSection,
                content: question?.brief?.content,
              };
              break;
            }
            case "PASSAGE": {
              Component = Passage;
              props = {
                active,
                title: "Read the passage below and answer the upcoming questions.",
                children: question?.passage?.content
              };
              break;
            }
            case "AUDIO": {
              Component = AudioPassage;
              props = {
                active,
                audioPassage: question.audioPassage,
                playAudio: active,
                onCompleted: nextSubSection
              };

              break;
            }
            case "QUESTION": {
              if (question.subSections.find(ss => ss === 'PASSAGE')) {
                return <React.Fragment key={i}>
                  <Passage active={active} title="Write your answer below">
                    {question.passage.content}
                  </Passage>
                  <WritingQuestion
                    active={active} question={question}
                  />
                </React.Fragment>;
              }

              Component = (props) => <WritingQuestion {...props} minHeight="35vh" />;
              props = {
                active: subSection === i,
                question: question,
              };
              break;
            }
            default: return null;
          }

          return <Component key={i} {...props} />
        }))}
      </SectionShell>
    </div>
  );
}

export default React.forwardRef(WritingSection);