import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import useStore from "utils/reportStore";
import GradeReportButton from "../GradeReportButton";
import Answers from "./Answers";
import CustomAccordion from "./CustomAccordion";

const OVERALL = -1;

export default function Sidebar(props) {

    const section = useStore((state) => state.section);
    const setSection = useStore((state) => state.setSection);
    const answers = useStore((state) => state.analysis?.[section]);
    const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
    const sections = useStore((state) => state.sections);

    const handleSectionChange = (index, state) => {
        (state) ? setSection(index) : setSection(-1);

        setCurrentQuestion(null);
    }

    const handleAnswerClick = (answerIndex) => {
        setCurrentQuestion(answerIndex);
    }

    return (
        <Box
            height={'95%'} borderRadius={1} mx={2}
            boxShadow='0px 0px 12px rgba(0, 0, 0, 0.16)'
            p={2} width={200} minWidth={200} overflow='auto'
            backgroundColor='#ffffff' mb={1} {...props}
        >
            <GradeReportButton
                active={section === OVERALL}
                mb={2}
            />

            <Divider orientation="horizontal" />

            <Typography
                fontFamily='inter' fontSize={16}
                fontWeight={500} textAlign='center' my={1}
            >
                Section Analysis
            </Typography>

            <br />

            {sections.map((sec, index) => (
                <Box key={index}>
                    <CustomAccordion
                        open={section === index} label={sec.name}
                        index={index} onChange={handleSectionChange}
                    >
                        <Answers
                            index={index}
                            questions={sec?.questions}
                            answers={answers}
                            onAnswerClick={handleAnswerClick}
                            key={index}
                            my={1}
                        />
                    </CustomAccordion>
                    <br />
                </Box>
            ))}
        </Box>
    );
}