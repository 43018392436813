import Bowser from 'bowser';
import screenfull from 'screenfull';
import {
  Levels, SectionBaisedDescription, Sections, ToeflLevels
} from 'components/Report/TOEFL/constants';
import { gtag } from 'ga-gtag';

const apiUrl = process.env.REACT_APP_API_URL;

export const subMinutes = function (date, minutes) {
  return new Date(date.getTime() - minutes * 60000);
};

export const getViewportDimensions = () => {

  let width;
  let height;

  // the more standards compliant browsers (mozilla/netscape/opera/IE7) use window.innerWidth and window.innerHeight
  if (typeof window.innerWidth !== 'undefined') {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  // IE6 in standards compliant mode (i.e. with a valid doctype as the first line in the document)
  else if (typeof document.documentElement !== 'undefined'
    && typeof document.documentElement.clientWidth !=
    'undefined' && document.documentElement.clientWidth !== 0) {
    width = document.documentElement.clientWidth;
    height = document.documentElement.clientHeight;
  }

  // older versions of IE
  else {
    width = document.getElementsByTagName('body')[0].clientWidth;
    height = document.getElementsByTagName('body')[0].clientHeight;
  }
  return { width, height };
};

export const isBrowserSupported = async () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  let isValidBrowser = browser.satisfies({
    windows: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    linux: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    macos: {
      'internet explorer': '>9999',
      safari: '>14.1',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
    ios: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>9999',
      firefox: '>9999',
      opera: '>9999',
      edge: '>9999'
    },
    android: {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>9999',
      firefox: '>9999',
      opera: '>9999',
      edge: '>9999'
    },
    'Chrome OS': {
      'internet explorer': '>9999',
      safari: '>9999',
      chrome: '>33',
      firefox: '>9999',
      opera: '>9999',
      edge: '>79'
    },
  });

  isValidBrowser = isValidBrowser && !(navigator.brave && await navigator.brave.isBrave());

  if (!isValidBrowser) console.error("UNSUPPORTED_BROWSER::", browser);

  if (isValidBrowser) {
    const result = await fetch(`${apiUrl}/detect-brave?gclid=3&fbclid=2&sub=marine`);
    isValidBrowser = isValidBrowser && (await result.text());
  }

  if (isValidBrowser) {
    return !(window?.screen && window?.screen?.width < 500)
  }

  return isValidBrowser;
};

const getUserMedia = constraints => {
  // Older browsers might not implement mediaDevices at all, so we set an empty object first
  if (navigator.mediaDevices === undefined) {
    navigator.mediaDevices = {};
  }

  // Some browsers partially implement mediaDevices. We can't just assign an object
  // with getUserMedia as it would overwrite existing properties.
  // Here, we will just add the getUserMedia property if it's missing.
  if (navigator.mediaDevices.getUserMedia === undefined) {
    navigator.mediaDevices.getUserMedia = function (constraints) {
      // First get ahold of the legacy getUserMedia, if present
      var getUserMedia = navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia;

      // Some browsers just don't implement it - return a rejected promise with an error
      // to keep a consistent interface
      if (!getUserMedia) {
        return Promise.reject(
          new Error('getUserMedia is not implemented in this browser')
        );
      }

      // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
      return new Promise(function (resolve, reject) {
        getUserMedia.call(navigator, constraints, resolve, reject);
      });
    };
  } else {
    return navigator.mediaDevices.getUserMedia(constraints);
  }
};

export const checkMicrophone = () => {
  return new Promise((resolve, reject) => {
    getUserMedia({ audio: true })
      .then(function (stream) {
        resolve(true);
      })
      .catch(function (err) {
        resolve(false);
      });
  });
};

export const secondsToMMSS = (seconds) => {
  if (seconds < 0) return "";
  var MM = Math.floor(seconds / 60);
  var SS = seconds - (MM * 60);

  if (MM < 10) MM = `0${MM}`;
  if (SS < 10) SS = `0${SS}`;

  return `${MM}:${SS}`;
}

export const copyToClipboard = (text, textRef) => {
  if (navigator.clipboard?.writeText) {
    navigator?.clipboard?.writeText(text)
      .then(() => { })
      .catch(() => { });
  } else {
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(textRef.current);
      selection.removeAllRanges();
      selection.addRange(range);
      document?.execCommand('copy');
      selection.removeAllRanges();
    }
  }
}

export const getLevelByScore = (score) => {
  if (score >= 70) return Levels.EXPERT;
  if (score >= 40) return Levels.INTERMEDIATE;
  return Levels.BEGINNER;
};

export const getToeflLevelByScore = (score, section) => {
  if (score >= 0 && score <= 30) {
    const { scale } = Sections[section];
    const level = scale[score];
    const sectionDescription = (!section) ?
      SectionBaisedDescription['OVERALL'] :
      SectionBaisedDescription[section];
    const description = sectionDescription[level];
    return { ...ToeflLevels[level], description };
  } else {
    return ToeflLevels.DEFAULT;
  }
}

export const getCefrGradeByScore = (score) => {
  const overallDescription = SectionBaisedDescription['OVERALL'];
  if (score <= 120 && score >= 115) return { grade: 'C2', description: overallDescription.ADVANCED };
  if (score <= 114 && score >= 94) return { grade: 'C1', description: overallDescription.HIGH_INTERMEDIATE };
  if (score <= 93 && score >= 46) return { grade: 'B2', description: overallDescription.LOW_INTERMEDIATE };
  if (score <= 45 && score >= 32) return { grade: 'B1', description: overallDescription.BELOW_LOW_INTERMEDIATE };
  if (score <= 31) return { grade: 'A1-B1', description: overallDescription.BELOW_LOW_INTERMEDIATE };
}

export const elementInsideViewport = (element) => {
  let bounding = element.getBoundingClientRect();

  if (bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.right <= (window.innerWidth || document.documentElement.clientWidth) &&
    bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
    return true;
  }

  return false;
}

export const between = (min, max, value) => {
  return value >= min && value <= max;
};

export function exitFullScreen() {
  try {
    if (screenfull.isEnabled) {
      screenfull.exit(document.body).catch(console.error);
    }
  } catch (error) {
    console.error("FULLSCREEN_ERROR::", error);
  }
}

export function fullScreen() {
  try {
    if (screenfull.isEnabled) {
      screenfull.request(document.body).catch(console.error);
    }
  } catch (error) {
    console.error("FULLSCREEN_ERROR::", error);
  }
}

export function triggerEventForGA(event_name, event_parameters = {}) {
  if (
    process.env.REACT_APP_GA_MEASUREMENT_ID &&
    process.env.NODE_ENV === "production" &&
    process.env.REACT_APP_STAGE === 'prod'
  ) {
    gtag('event', event_name, event_parameters);
  }
}

export * from "./beep";
export * from "./cryptoUtil";
export * from "./OTPAuth";
export * from "./Session";
export * from "./toeflStore";
