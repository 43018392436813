import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

function DragableOption({ options, option, onClick }) {
  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", option);
  };

  return (
    <div style={{
      display: "flex", cursor: 'grab', borderRadius: 4,
      marginBottom: 4, boxShadow: '0px 4px 16px rgba(2, 86, 157, 0.25)'
    }}>
      &rarr;&nbsp;
      <div
        draggable
        dangerouslySetInnerHTML={{ __html: options[option] }}
        onDragStart={handleDragStart}
        style={{ marginBottom: 8 }}
        onClick={() => onClick(option)}
      />
    </div>
  );
}

function DroppableInput({ index, answer, onDrop, question }) {

  const handleDragOver = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
    e.target.style.border = "1px solid green";
  };

  const handleDragExit = (e) => {
    e.preventDefault();
    e.target.style.border = "1px solid transparent";
  };

  const handleDragStart = (e) => {
    if (answer !== -1) {
      e.dataTransfer.setData("text/plain", answer);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.target.style.border = "none";
    const option = event.dataTransfer.getData("text/plain");
    onDrop(option, index)
  };

  return (
    <div
      style={{
        display: "flex", alignItems: "flex-start", width: "100%)",
        margin: 0, padding: "8px 16px", transition: "all 100ms",
        border: "0.5px solid black"
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragExit}
      onDrop={handleDrop}
    >
      {index + 1}. &nbsp;
      <div
        draggable
        onDragStart={handleDragStart}
        dangerouslySetInnerHTML={{ __html: question.options[answer] }}
        style={{ width: "-webkit-fill-available" }}
      />
    </div>
  );
}

function DragAndDrop({ active, currentQuestion, question, ...props }) {

  const [answers, setAnswers] = React.useState([]);

  const onDrop = (option, answer) => {
    setAnswers((answers) => {
      const preAnsIndex = answers.findIndex(preAns => preAns === option);
      const newAnswers = [...answers];
      if (preAnsIndex !== -1) newAnswers[preAnsIndex] = -1;
      newAnswers[answer] = option;
      return newAnswers;
    });
  };

  const handleClick = (option) => {
    setAnswers(answers => {
      const newAnswers = answers.map(answer =>
        +answer === option ? -1 : answer
      );
      return newAnswers;
    })
  };

  React.useEffect(() => {
    const values = question?.answer?.value;
    setAnswers(values === undefined ?
      new Array(question?.options?.length || 0).fill(-1) :
      values
    );
  }, [currentQuestion]);

  React.useEffect(() => {
    question.answer.value = answers;
  }, [answers]);

  return active ? (
    <Box p={4} width="50%" overflow="auto" height="45vh">
      <Box display="flex">
        <Typography fontSize={14} fontWeight={700} pr={1}>
          {currentQuestion + 1}.
        </Typography>
        <Box
          fontWeight={'600'}
          dangerouslySetInnerHTML={{ __html: question?.question || question?.content }}
        />
      </Box>
      <br />
      <Box style={{ border: "1px solid grey" }}>
        {answers?.map((answer, index) => (
          <DroppableInput
            question={question}
            index={index}
            answer={answers?.[index]}
            key={index}
            onDrop={onDrop}
          />
        ))}
      </Box>
      <br />
      {question.options.map((option, index) => (
        <DragableOption
          options={question?.options || []}
          option={index}
          key={index}
          onClick={handleClick}
        />
      ))}
    </Box>
  ) : null;
}

export default DragAndDrop;