import React from 'react';
import { Box, Typography } from '@mui/material';
import SpeakerIcon from '@mui/icons-material/Campaign';
import ProceedIcon from '@mui/icons-material/ArrowRightAlt';
import Button from 'components/Button';
import { useSnackbar } from 'contexts';
import { playAudio } from 'utils';

function SpeakerTest({ active, onNext = () => { } }) {
  const snackbar = useSnackbar();
  const audioRef = React.useRef(null);
  const [proceed, setProceed] = React.useState(false);

  const playSound = () => {
    if (audioRef.current === null) {
      audioRef.current = playAudio(null, (error) => {
        setProceed(false);
        snackbar.error("Unable to play audio! Check your network connection");
        audioRef.current = null;
      });

      setProceed(true);
    } else {
      audioRef.current.currentTime = 0;
      audioRef.current.play();
    }
  }

  return active ? (
    <Box height="-webkit-fill-available" width="100%" textAlign="center">
      <Typography variant="h5" fontWeight="600" textAlign="center">
        Sound Test
      </Typography>
      <Box sx={{ textAlign: "center", my: 2, p: 2, bgcolor: "#2DABFF33", maxWidth: 500, mx: 'auto', borderRadius: 4 }}>
        <img
          src="https://assets.languify.in/images/speaker-test.svg"
          alt="Speaker Test"
          height={140}
        />
      </Box>
      <Button
        variant="contained" startIcon={<SpeakerIcon />} onClick={playSound}
        style={{ minWidth: 250 }}
      >
        Play Sound
      </Button>
      <Box width="fit-content" mx="auto" textAlign="left" maxWidth={580}>
        <ol>
          <li style={{ marginBottom: 8 }}>
            Put on your headphones and click the PLAY SOUND button to play a sample sound.
          </li>
          <li style={{ marginBottom: 8 }}>
            If you cannot hear the sound clearly, make sure to check your headphones and see if they are working properly.
          </li>
          <li style={{ marginBottom: 8 }}>
            If you can hear the sound, click the "Proceed" button. The "Proceed" button will be once the test is successful.
          </li>
        </ol>
      </Box>
      <Button
        variant="contained" onClick={onNext} disabled={!proceed}
        endIcon={<ProceedIcon />} style={{ minWidth: 250 }}
      >
        Proceed
      </Button>
    </Box>
  ) : null;
}

export default SpeakerTest;