import { Sections } from "components/Report/TOEFL/constants";

const Features = {
    'fluency': 'fluency',
    'delivery': 'delivery',
    'vocabulary': 'vocabulary',
    'pauses': 'pauses',
    'phraseRepeatRating': 'phraseRepeatRating',
    'rareWordShare': 'rareWordShare',
    'frequentWordShare': 'frequentWordShare',
    'speechRate': 'speechRate',
    'breakdown': 'breakdown',
    'overUsedWords': 'overUsedWords',
    'grammar': 'grammar',
    'spelling': 'spelling',
    'slangs': 'slangs',
    "fillerPhrases": "fillerPhrases",
    'fillerWords': 'fillerWords',
};


function getParsedString(feature, value) {

    if (feature === Features.rareWordShare) {
        let color = '#737373';
        if (value < 4) color = '#FFCC00';
        if (value >= 4) color = '#41D7A1';
        return {
            color: color,
            value: `Rarely used words share: ${value || 0}%`
        }
    };

    if (feature === Features.frequentWordShare) {
        let color = '#737373';
        if (value > 60) color = '#FFCC00';
        if (value < 60) color = '#41D7A1';
        return {
            color: color,
            value: `Commonly used words share: ${value || 0}%`
        }
    };

    if (feature === Features.breakdown) {
        let color = '#FFCC00';
        return {
            color: color,
            value: `Breakdown: ${value || 0} times`
        }
    };

    if (feature === Features.speechRate) {
        let color = '#737373';
        if (value >= 100 && value <= 150) color = '#41D7A1';
        if (value < 100 || value > 150) color = '#FFCC00';
        return {
            color: color,
            value: `Speech rate: ${value || 0} wpm`
        }
    };

    if (feature === Features.fillerWords) {
        let tagValue = '';
        if (Array.isArray(value)) {
            value.forEach((v) => tagValue += `${v.word} x${v.count},`);
        }
        if (Object.i) {
            Object.keys(value || {}).forEach((key) => tagValue += `${key} x${value[key]}`);
        }

        return {
            color: '#FFCC00',
            value: `Filler words : ${(tagValue.length === 0) ? 'Not found' : tagValue}`
        }
    };

    if (feature === Features.fillerPhrases) {
        let tagValue = '';
        if (Array.isArray(value)) {
            value.forEach((v) => tagValue += `${v.word} x${v.count},`);
        } else {
            Object.keys(value || {}).forEach((key) => tagValue += `${key} x${value[key]},`);
        }

        return {
            color: '#FFCC00',
            value: `Filler phrases : ${(tagValue.length === 0) ? 'Not found' : tagValue}`
        }
    };

    if (feature === Features.overUsedWords) {
        let tagValue = '';
        if (Array.isArray(value)) {
            value.forEach((v) => tagValue += `${v.word} x${v.count},`);
        } else {
            Object.keys(value || {}).forEach((key) => tagValue += `${key} x${value[key]},`);
        }

        return {
            color: '#FFCC00',
            value: `Over used words : ${(tagValue.length === 0) ? 'Not found' : tagValue}`
        }
    };
}

export default function parseFeatures(features = null, section) {
    if (features && section) {
        const tags = Sections[section]?.tags;
        const newFeatures = Object.keys(tags || {}).map(
            (tag) => getParsedString(tag, features[tag])
        );
        return newFeatures.filter((newFeature) => newFeature !== undefined);
    }

    return [];
}