import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { features } from './constant';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  features: {
    display: "flex", flexDirection: "column", alignItems: "center",
    justifyContent: "center", height: "fit-content",
    padding: "32px 0px",
    [theme.breakpoints.down("md")]: {
      height: "fit-content", paddingTop: 32, paddingBottom: 0,
    },
  },
  featureText: {
    flexGrow: 1, padding: "16px", borderRadius: 16, fontWeight: "bolder",
    textAlign: "center",
    fontSize: "2vmin",
    color: "#00000099",
    backgroundColor: "#2DABFF33",
    transition: "all 1000ms ease-in-out", cursor: 'pointer',
    [theme.breakpoints.down("md")]: {
      padding: "8px 16px", borderRadius: 8,
    }
  },
  activeFeatureText: {
    color: "#FFFFFF", backgroundColor: "#2DABFF"
  },
  featureNo: {
    border: "0.8vmin solid", width: "6vmin", height: "6vmin", borderRadius: "50%",
    display: "flex", alignItems: "center", justifyContent: "center",
    fontWeight: "bolder", fontSize: "2vmin", marginRight: 16, color: "#2DABFF",
    transition: "all 1000ms ease-in-out", borderColor: "#2DABFF33",
  },
  activeFeatureNo: {
    borderColor: "#2DABFF", color: "#2DABFF"
  },
  imageContainer: {
    padding: "48px 16px", borderRadius: 12,
    background: "linear-gradient(135deg, rgba(88,255,73,0.2) 0%, rgba(73,182,255,0.2) 100%)",
    [theme.breakpoints.down("md")]: { borderRadius: 0, }
  },
  grow: {
    animation: "$heartbeat 1s",
  },
  "@keyframes heartbeat": {
    "0%": { transform: "scale(0)" },
    "100%": { transform: "scale(1)" },
  }
}));


function Features(props) {
  const classes = useStyles();
  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() =>
      setActive(a => a + 1 === features.length ? 0 : a + 1),
      5000
    );

    return () => clearInterval(interval);
  }, []);

  return (
    <Box className={classes.features}>
      <Typography textAlign="center" fontSize={'5vmin'} fontWeight={600} mx={'5vw'}>
        Increase your TOEFL or IELTS score in 3 easy steps (Completely Free!)
      </Typography>
      <Box
        display="flex"
        alignItems='center'
        justifyContent="space-around"
        width="fit-content"
        mx={"auto"}
        mt={'6vmin'}
        flexWrap="wrap"
      >
        <Box
          display="flex" alignItems="stretch"
          justifyContent="center" flexDirection="column" p={"3vmin"}
        >
          {features.map((feature, i) => (
            <Box
              key={i} display='flex' alignItems="center"
              maxWidth={420} marginBottom={'5vmin'}
            >
              <Typography
                component="div"
                className={classNames({
                  [classes.featureNo]: true,
                  [classes.activeFeatureNo]: active === i
                })}
              >
                {i + 1}
              </Typography>
              <Typography
                className={classNames({
                  [classes.featureText]: true,
                  [classes.activeFeatureText]: active === i
                })}
                onClick={() => setActive(i)}
              >
                {feature.text}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box className={classes.imageContainer}>
          {features.map((feature, i) => (
            <Box
              key={i} alignItems="center"
              display={active === i ? 'flex' : 'none'}
            >
              <img
                src={feature.url} alt={feature.feature} width={'100%'}
                className={active === i ? classes.grow : ''}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box >
  );
}

export default Features;