import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";
import Title from "../Title";
import CommonObservations from "./CommonObservations";
import { InaudibleErrorInfo, MicrophoneInfo } from "./Info";
import ParameterWiseProgress from "./ParameterWiseProgress";

const useStyles = makeStyles({
    selected: {
        width: 15,
        height: 15,
        borderRadius: 15,
        backgroundColor: '#235598'
    },
    unSelected: {
        width: 15,
        height: 15,
        borderRadius: 15,
        border: '1px solid black'
    },
    title: {
        fontFamily: 'inter',
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 5
    },
    answer: {
        fontFamily: 'inter',
        fontSize: 15,
        fontWeight: 600,
        color: 'rgba(0, 0, 0, 0.7)'
    },
});

export default function Analysis({ active }) {
    const classes = useStyles();
    const analyses = useStore((state) => state.analysis[state.section]);
    const {
        questions = [], answers = []
    } = useStore((state) => state.sections?.[state.section] || {});
    const type = useStore((state) => state.sections?.[state.section]?.name?.toUpperCase());

    const currentQuestion = useStore((state) => state.currentQuestion);

    const question = questions?.[currentQuestion];
    const answerObj = analyses?.[currentQuestion]
    let answer = answerObj?.answer;
    let correctAnswer = answers?.[currentQuestion];
    let isCorrect = answerObj?.analysis?.score === 100;

    if (question?.type === 'scq') {
        correctAnswer = [correctAnswer];
        answer = [answer]
    }

    return (
        (active) ? (
            <>
                <Title title='Analysis' /> <br />
                {(['SPEAKING', 'WRITING']?.includes(type)) && (<>
                    {(type === "SPEAKING" && (!answerObj || answerObj?.analysis?.score === 0)) ? (
                        <CenterFlexBox flexDirection='column' alignItems='flex-start'>
                            <InaudibleErrorInfo /> <br />
                            <MicrophoneInfo />
                        </CenterFlexBox>
                    ) : (<>
                        <ParameterWiseProgress
                            parameters={
                                currentQuestion !== null && answerObj?.analysis
                            }
                            title='Paramerter wise progress'
                        /> <br />
                        <CommonObservations
                            commonObservations={
                                currentQuestion !== null && answerObj?.analysis
                            }
                            title='Common Observations'
                        />
                    </>)}
                </>)}
                {(['READING', 'LISTENING']?.includes(type)) && (<>
                    <Box display='flex' flexDirection={'row'} my={4}>
                        <Typography
                            fontFamily='inter'
                            fontSize={18} mr={1}
                            fontWeight={400}
                            dangerouslySetInnerHTML={
                                { __html: `Q${currentQuestion + 1}.` }
                            }
                        />
                        <Typography
                            fontFamily='inter'
                            fontSize={18}
                            fontWeight={400}
                            dangerouslySetInnerHTML={{
                                __html: question?.question || question?.content || 'NA'
                            }}
                        />
                    </Box>

                    {question?.options?.map((option, index) => (
                        <Box
                            my={1} ml={1}
                            display='flex'
                            alignItems='center'
                            gap={1}
                            key={index}
                        >
                            <Box width={20} mr={1}>
                                <Box className={answer?.includes(index) ?
                                    classes.selected :
                                    classes.unSelected}
                                />
                            </Box>
                            <Typography
                                fontFamily='inter'
                                fontSize={14}
                                fontWeight={400}
                                color='rgba(0, 0, 0, 0.7)'
                            >
                                {option}
                            </Typography>

                            {correctAnswer?.includes(index) && <CheckOutlinedIcon color="success" />}
                        </Box>
                    ))}

                    <Typography
                        fontFamily='inter'
                        fontSize={15} ml={1} mt={2}
                        fontWeight={400}
                        color={isCorrect ? '#03CA5E' : '#E98862'}
                    >
                        {isCorrect ?
                            "Your answer matches the correct answer. Good job!" :
                            "Your answer doesn't match with the correct answer. Need improvement!"
                        }
                    </Typography>
                </>)}
            </>
        ) : null
    );
}