import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from 'components/Button';
import SectionChip from 'components/SectionChip';
import QuestionProgress from 'components/QuestionProgress';
import useStore from 'utils/toeflStore';
import shallow from 'zustand/shallow';
import { finishMockAttempt } from 'services';
import PauseTestDialog from 'dialogs/PauseTest';
import Session from 'utils/Session';
import { endTest } from 'screens/IELTSMock';
import { exitFullScreen } from 'utils';

function SectionHeader({
  hideNext, hidePrev, hideActions, hideProgress, hideQuestion, onNext, onPrev,
  nextAction, prevAction, disablePrev, disableNext, notQuestion
}) {
  const navigate = useNavigate();
  const { name = '', questions = [] } = useStore(state => state.sections?.[state.section] || {});
  const currentQuestion = useStore(state => state.currentQuestion);
  const attempt = useStore((state) => state.attempt);
  const next = useStore((state) => state.next);
  const prev = useStore((state) => state.prev);
  const openDialog = useStore((state) => state.openSubmitSectionDialog);
  const playing = useStore((state) => state.playing);
  const [reset, resume, pause] = useStore((state) => [
    state.reset, state.resume, state.pause
  ], shallow);

  const [searchParams,] = useSearchParams();

  const lastQuestion = React.useMemo(
    () => currentQuestion + 1 === questions?.length,
    [currentQuestion, questions]
  );

  const handlePauseAndGetReport = async () => {
    exitFullScreen();
    if (Session.isLoggedIn()) {
      try {
        await finishMockAttempt(attempt, false);
      } catch (error) { console.error(error) }

      endTest(navigate);
    } else {
      navigate(`/register?aid=${attempt}`, { replace: true });
    }

    reset();
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <SectionChip name={name} hideInfoIcon />
        {!hideActions && (
          <Box style={{
            position: 'absolute', top: -60, textAlign: "center",
            width: '-webkit-fill-available', display: "flex", left: 0,
            justifyContent: "space-between", alignItems: "center"
          }}>
            <Button
              variant="contained"
              style={{ padding: "8px 16px" }}
              onClick={pause}
              disabled={searchParams.get('api')}
            >
              {playing ? "Pause" : "Resume"}
            </Button>
            <DialogActions style={{ padding: "8px 0px" }}>
              {!hidePrev && (
                <Button
                  variant="contained" style={{ padding: "8px 16px" }}
                  onClick={onPrev || prev}
                  disabled={disablePrev || currentQuestion === 0}
                >
                  {prevAction || "Previous"}
                </Button>
              )}
              {!hideNext && (
                <Button
                  disabled={disableNext}
                  variant="contained" style={{ padding: "8px 16px" }}
                  onClick={(lastQuestion && !notQuestion) ? openDialog : (onNext || next)}
                >
                  {(lastQuestion && !notQuestion) ? "Submit" : (nextAction || "Next")}
                </Button>
              )}
            </DialogActions>
          </Box>
        )}
      </Box>
      {!hideQuestion && (
        <Typography fontSize={20} fontWeight={500} ml={1}>
          {currentQuestion + 1}/{questions.length}
        </Typography>
      )}
      {!hideProgress && (
        <QuestionProgress
          current={currentQuestion} questions={questions}
        />
      )}
      <PauseTestDialog
        open={!playing}
        onConfirm={handlePauseAndGetReport}
        onCancel={resume}
      />
    </Box>
  );
}

export default SectionHeader;