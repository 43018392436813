export const countries = [
    {
        "id": "af",
        "code": "93",
        "label": "+93 (Afghanistan)"
    },
    {
        "id": "al",
        "code": "355",
        "label": "+355 (Albania)"
    },
    {
        "id": "dz",
        "code": "213",
        "label": "+213 (Algeria)"
    },
    {
        "id": "ad",
        "code": "376",
        "label": "+376 (Andorra)"
    },
    {
        "id": "ao",
        "code": "244",
        "label": "+244 (Angola)"
    },
    {
        "id": "ag",
        "code": "1268",
        "label": "+1268 (Antigua and Barbuda)"
    },
    {
        "id": "ar",
        "code": "54",
        "label": "+54 (Argentina)"
    },
    {
        "id": "am",
        "code": "374",
        "label": "+374 (Armenia)"
    },
    {
        "id": "aw",
        "code": "297",
        "label": "+297 (Aruba)"
    },
    {
        "id": "au",
        "code": "61",
        "label": "+61 (Australia)"
    },
    {
        "id": "at",
        "code": "43",
        "label": "+43 (Austria)"
    },
    {
        "id": "az",
        "code": "994",
        "label": "+994 (Azerbaijan)"
    },
    {
        "id": "bs",
        "code": "1242",
        "label": "+1242 (Bahamas)"
    },
    {
        "id": "bh",
        "code": "973",
        "label": "+973 (Bahrain)"
    },
    {
        "id": "bd",
        "code": "880",
        "label": "+880 (Bangladesh)"
    },
    {
        "id": "bb",
        "code": "1246",
        "label": "+1246 (Barbados)"
    },
    {
        "id": "by",
        "code": "375",
        "label": "+375 (Belarus)"
    },
    {
        "id": "be",
        "code": "32",
        "label": "+32 (Belgium)"
    },
    {
        "id": "bz",
        "code": "501",
        "label": "+501 (Belize)"
    },
    {
        "id": "bj",
        "code": "229",
        "label": "+229 (Benin)"
    },
    {
        "id": "bt",
        "code": "975",
        "label": "+975 (Bhutan)"
    },
    {
        "id": "bo",
        "code": "591",
        "label": "+591 (Bolivia)"
    },
    {
        "id": "ba",
        "code": "387",
        "label": "+387 (Bosnia and Herzegovina)"
    },
    {
        "id": "bw",
        "code": "267",
        "label": "+267 (Botswana)"
    },
    {
        "id": "br",
        "code": "55",
        "label": "+55 (Brazil)"
    },
    {
        "id": "io",
        "code": "246",
        "label": "+246 (British Indian Ocean Territory)"
    },
    {
        "id": "bn",
        "code": "673",
        "label": "+673 (Brunei)"
    },
    {
        "id": "bg",
        "code": "359",
        "label": "+359 (Bulgaria)"
    },
    {
        "id": "bf",
        "code": "226",
        "label": "+226 (Burkina Faso)"
    },
    {
        "id": "bi",
        "code": "257",
        "label": "+257 (Burundi)"
    },
    {
        "id": "kh",
        "code": "855",
        "label": "+855 (Cambodia)"
    },
    {
        "id": "cm",
        "code": "237",
        "label": "+237 (Cameroon)"
    },
    {
        "id": "ca",
        "code": "1",
        "label": "+1 (Canada)"
    },
    {
        "id": "cv",
        "code": "238",
        "label": "+238 (Cape Verde)"
    },
    {
        "id": "bq",
        "code": "599",
        "label": "+599 (Caribbean Netherlands)"
    },
    {
        "id": "cf",
        "code": "236",
        "label": "+236 (Central African Republic)"
    },
    {
        "id": "td",
        "code": "235",
        "label": "+235 (Chad)"
    },
    {
        "id": "cl",
        "code": "56",
        "label": "+56 (Chile)"
    },
    {
        "id": "cn",
        "code": "86",
        "label": "+86 (China)"
    },
    {
        "id": "co",
        "code": "57",
        "label": "+57 (Colombia)"
    },
    {
        "id": "km",
        "code": "269",
        "label": "+269 (Comoros)"
    },
    {
        "id": "cd",
        "code": "243",
        "label": "+243 (Congo)"
    },
    {
        "id": "cg",
        "code": "242",
        "label": "+242 (Congo)"
    },
    {
        "id": "cr",
        "code": "506",
        "label": "+506 (Costa Rica)"
    },
    {
        "id": "ci",
        "code": "225",
        "label": "+225 (Côte d’Ivoire)"
    },
    {
        "id": "hr",
        "code": "385",
        "label": "+385 (Croatia)"
    },
    {
        "id": "cu",
        "code": "53",
        "label": "+53 (Cuba)"
    },
    {
        "id": "cw",
        "code": "599",
        "label": "+599 (Curaçao)"
    },
    {
        "id": "cy",
        "code": "357",
        "label": "+357 (Cyprus)"
    },
    {
        "id": "cz",
        "code": "420",
        "label": "+420 (Czech Republic)"
    },
    {
        "id": "dk",
        "code": "45",
        "label": "+45 (Denmark)"
    },
    {
        "id": "dj",
        "code": "253",
        "label": "+253 (Djibouti)"
    },
    {
        "id": "dm",
        "code": "1767",
        "label": "+1767 (Dominica)"
    },
    {
        "id": "do",
        "code": "1",
        "label": "+1 (Dominican Republic)"
    },
    {
        "id": "ec",
        "code": "593",
        "label": "+593 (Ecuador)"
    },
    {
        "id": "eg",
        "code": "20",
        "label": "+20 (Egypt)"
    },
    {
        "id": "sv",
        "code": "503",
        "label": "+503 (El Salvador)"
    },
    {
        "id": "gq",
        "code": "240",
        "label": "+240 (Equatorial Guinea)"
    },
    {
        "id": "er",
        "code": "291",
        "label": "+291 (Eritrea)"
    },
    {
        "id": "ee",
        "code": "372",
        "label": "+372 (Estonia)"
    },
    {
        "id": "et",
        "code": "251",
        "label": "+251 (Ethiopia)"
    },
    {
        "id": "fj",
        "code": "679",
        "label": "+679 (Fiji)"
    },
    {
        "id": "fi",
        "code": "358",
        "label": "+358 (Finland)"
    },
    {
        "id": "fr",
        "code": "33",
        "label": "+33 (France)"
    },
    {
        "id": "gf",
        "code": "594",
        "label": "+594 (French Guiana)"
    },
    {
        "id": "pf",
        "code": "689",
        "label": "+689 (French Polynesia)"
    },
    {
        "id": "ga",
        "code": "241",
        "label": "+241 (Gabon)"
    },
    {
        "id": "gm",
        "code": "220",
        "label": "+220 (Gambia)"
    },
    {
        "id": "ge",
        "code": "995",
        "label": "+995 (Georgia)"
    },
    {
        "id": "de",
        "code": "49",
        "label": "+49 (Germany)"
    },
    {
        "id": "gh",
        "code": "233",
        "label": "+233 (Ghana)"
    },
    {
        "id": "gr",
        "code": "30",
        "label": "+30 (Greece)"
    },
    {
        "id": "gd",
        "code": "1473",
        "label": "+1473 (Grenada)"
    },
    {
        "id": "gp",
        "code": "590",
        "label": "+590 (Guadeloupe)"
    },
    {
        "id": "gu",
        "code": "1671",
        "label": "+1671 (Guam)"
    },
    {
        "id": "gt",
        "code": "502",
        "label": "+502 (Guatemala)"
    },
    {
        "id": "gn",
        "code": "224",
        "label": "+224 (Guinea)"
    },
    {
        "id": "gw",
        "code": "245",
        "label": "+245 (Guinea-Bissau)"
    },
    {
        "id": "gy",
        "code": "592",
        "label": "+592 (Guyana)"
    },
    {
        "id": "ht",
        "code": "509",
        "label": "+509 (Haiti)"
    },
    {
        "id": "hn",
        "code": "504",
        "label": "+504 (Honduras)"
    },
    {
        "id": "hk",
        "code": "852",
        "label": "+852 (Hong Kong)"
    },
    {
        "id": "hu",
        "code": "36",
        "label": "+36 (Hungary)"
    },
    {
        "id": "is",
        "code": "354",
        "label": "+354 (Iceland)"
    },
    {
        "id": "in",
        "code": "91",
        "label": "+91 (India)"
    },
    {
        "id": "id",
        "code": "62",
        "label": "+62 (Indonesia)"
    },
    {
        "id": "ir",
        "code": "98",
        "label": "+98 (Iran)"
    },
    {
        "id": "iq",
        "code": "964",
        "label": "+964 (Iraq)"
    },
    {
        "id": "ie",
        "code": "353",
        "label": "+353 (Ireland)"
    },
    {
        "id": "il",
        "code": "972",
        "label": "+972 (Israel)"
    },
    {
        "id": "it",
        "code": "39",
        "label": "+39 (Italy)"
    },
    {
        "id": "jm",
        "code": "1876",
        "label": "+1876 (Jamaica)"
    },
    {
        "id": "jp",
        "code": "81",
        "label": "+81 (Japan)"
    },
    {
        "id": "jo",
        "code": "962",
        "label": "+962 (Jordan)"
    },
    {
        "id": "kz",
        "code": "7",
        "label": "+7 (Kazakhstan)"
    },
    {
        "id": "ke",
        "code": "254",
        "label": "+254 (Kenya)"
    },
    {
        "id": "ki",
        "code": "686",
        "label": "+686 (Kiribati)"
    },
    {
        "id": "xk",
        "code": "383",
        "label": "+383 (Kosovo)"
    },
    {
        "id": "kw",
        "code": "965",
        "label": "+965 (Kuwait)"
    },
    {
        "id": "kg",
        "code": "996",
        "label": "+996 (Kyrgyzstan)"
    },
    {
        "id": "la",
        "code": "856",
        "label": "+856 (Laos)"
    },
    {
        "id": "lv",
        "code": "371",
        "label": "+371 (Latvia)"
    },
    {
        "id": "lb",
        "code": "961",
        "label": "+961 (Lebanon)"
    },
    {
        "id": "ls",
        "code": "266",
        "label": "+266 (Lesotho)"
    },
    {
        "id": "lr",
        "code": "231",
        "label": "+231 (Liberia)"
    },
    {
        "id": "ly",
        "code": "218",
        "label": "+218 (Libya)"
    },
    {
        "id": "li",
        "code": "423",
        "label": "+423 (Liechtenstein)"
    },
    {
        "id": "lt",
        "code": "370",
        "label": "+370 (Lithuania)"
    },
    {
        "id": "lu",
        "code": "352",
        "label": "+352 (Luxembourg)"
    },
    {
        "id": "mo",
        "code": "853",
        "label": "+853 (Macau)"
    },
    {
        "id": "mk",
        "code": "389",
        "label": "+389 (Macedonia)"
    },
    {
        "id": "mg",
        "code": "261",
        "label": "+261 (Madagascar)"
    },
    {
        "id": "mw",
        "code": "265",
        "label": "+265 (Malawi)"
    },
    {
        "id": "my",
        "code": "60",
        "label": "+60 (Malaysia)"
    },
    {
        "id": "mv",
        "code": "960",
        "label": "+960 (Maldives)"
    },
    {
        "id": "ml",
        "code": "223",
        "label": "+223 (Mali)"
    },
    {
        "id": "mt",
        "code": "356",
        "label": "+356 (Malta)"
    },
    {
        "id": "mh",
        "code": "692",
        "label": "+692 (Marshall Islands)"
    },
    {
        "id": "mq",
        "code": "596",
        "label": "+596 (Martinique)"
    },
    {
        "id": "mr",
        "code": "222",
        "label": "+222 (Mauritania)"
    },
    {
        "id": "mu",
        "code": "230",
        "label": "+230 (Mauritius)"
    },
    {
        "id": "mx",
        "code": "52",
        "label": "+52 (Mexico)"
    },
    {
        "id": "fm",
        "code": "691",
        "label": "+691 (Micronesia)"
    },
    {
        "id": "md",
        "code": "373",
        "label": "+373 (Moldova)"
    },
    {
        "id": "mc",
        "code": "377",
        "label": "+377 (Monaco)"
    },
    {
        "id": "mn",
        "code": "976",
        "label": "+976 (Mongolia)"
    },
    {
        "id": "me",
        "code": "382",
        "label": "+382 (Montenegro)"
    },
    {
        "id": "ma",
        "code": "212",
        "label": "+212 (Morocco)"
    },
    {
        "id": "mz",
        "code": "258",
        "label": "+258 (Mozambique)"
    },
    {
        "id": "mm",
        "code": "95",
        "label": "+95 (Myanmar)"
    },
    {
        "id": "na",
        "code": "264",
        "label": "+264 (Namibia)"
    },
    {
        "id": "nr",
        "code": "674",
        "label": "+674 (Nauru)"
    },
    {
        "id": "np",
        "code": "977",
        "label": "+977 (Nepal)"
    },
    {
        "id": "nl",
        "code": "31",
        "label": "+31 (Netherlands)"
    },
    {
        "id": "nc",
        "code": "687",
        "label": "+687 (New Caledonia)"
    },
    {
        "id": "nz",
        "code": "64",
        "label": "+64 (New Zealand)"
    },
    {
        "id": "ni",
        "code": "505",
        "label": "+505 (Nicaragua)"
    },
    {
        "id": "ne",
        "code": "227",
        "label": "+227 (Niger)"
    },
    {
        "id": "ng",
        "code": "234",
        "label": "+234 (Nigeria)"
    },
    {
        "id": "kp",
        "code": "850",
        "label": "+850 (North Korea)"
    },
    {
        "id": "no",
        "code": "47",
        "label": "+47 (Norway)"
    },
    {
        "id": "om",
        "code": "968",
        "label": "+968 (Oman)"
    },
    {
        "id": "pk",
        "code": "92",
        "label": "+92 (Pakistan)"
    },
    {
        "id": "pw",
        "code": "680",
        "label": "+680 (Palau)"
    },
    {
        "id": "ps",
        "code": "970",
        "label": "+970 (Palestine)"
    },
    {
        "id": "pa",
        "code": "507",
        "label": "+507 (Panama)"
    },
    {
        "id": "pg",
        "code": "675",
        "label": "+675 (Papua New Guinea)"
    },
    {
        "id": "py",
        "code": "595",
        "label": "+595 (Paraguay)"
    },
    {
        "id": "pe",
        "code": "51",
        "label": "+51 (Peru)"
    },
    {
        "id": "ph",
        "code": "63",
        "label": "+63 (Philippines)"
    },
    {
        "id": "pl",
        "code": "48",
        "label": "+48 (Poland)"
    },
    {
        "id": "pt",
        "code": "351",
        "label": "+351 (Portugal)"
    },
    {
        "id": "pr",
        "code": "1",
        "label": "+1 (Puerto Rico)"
    },
    {
        "id": "qa",
        "code": "974",
        "label": "+974 (Qatar)"
    },
    {
        "id": "re",
        "code": "262",
        "label": "+262 (Réunion)"
    },
    {
        "id": "ro",
        "code": "40",
        "label": "+40 (Romania)"
    },
    {
        "id": "ru",
        "code": "7",
        "label": "+7 (Russia)"
    },
    {
        "id": "rw",
        "code": "250",
        "label": "+250 (Rwanda)"
    },
    {
        "id": "kn",
        "code": "1869",
        "label": "+1869 (Saint Kitts and Nevis)"
    },
    {
        "id": "lc",
        "code": "1758",
        "label": "+1758 (Saint Lucia)"
    },
    {
        "id": "vc",
        "code": "1784",
        "label": "+1784 (Saint Vincent and the Grenadines)"
    },
    {
        "id": "ws",
        "code": "685",
        "label": "+685 (Samoa)"
    },
    {
        "id": "sm",
        "code": "378",
        "label": "+378 (San Marino)"
    },
    {
        "id": "st",
        "code": "239",
        "label": "+239 (São Tomé and Príncipe)"
    },
    {
        "id": "sa",
        "code": "966",
        "label": "+966 (Saudi Arabia)"
    },
    {
        "id": "sn",
        "code": "221",
        "label": "+221 (Senegal)"
    },
    {
        "id": "rs",
        "code": "381",
        "label": "+381 (Serbia)"
    },
    {
        "id": "sc",
        "code": "248",
        "label": "+248 (Seychelles)"
    },
    {
        "id": "sl",
        "code": "232",
        "label": "+232 (Sierra Leone)"
    },
    {
        "id": "sg",
        "code": "65",
        "label": "+65 (Singapore)"
    },
    {
        "id": "sk",
        "code": "421",
        "label": "+421 (Slovakia)"
    },
    {
        "id": "si",
        "code": "386",
        "label": "+386 (Slovenia)"
    },
    {
        "id": "sb",
        "code": "677",
        "label": "+677 (Solomon Islands)"
    },
    {
        "id": "so",
        "code": "252",
        "label": "+252 (Somalia)"
    },
    {
        "id": "za",
        "code": "27",
        "label": "+27 (South Africa)"
    },
    {
        "id": "kr",
        "code": "82",
        "label": "+82 (South Korea)"
    },
    {
        "id": "ss",
        "code": "211",
        "label": "+211 (South Sudan)"
    },
    {
        "id": "es",
        "code": "34",
        "label": "+34 (Spain)"
    },
    {
        "id": "lk",
        "code": "94",
        "label": "+94 (Sri Lanka)"
    },
    {
        "id": "sd",
        "code": "249",
        "label": "+249 (Sudan)"
    },
    {
        "id": "sr",
        "code": "597",
        "label": "+597 (Suriname)"
    },
    {
        "id": "sz",
        "code": "268",
        "label": "+268 (Swaziland)"
    },
    {
        "id": "se",
        "code": "46",
        "label": "+46 (Sweden)"
    },
    {
        "id": "ch",
        "code": "41",
        "label": "+41 (Switzerland)"
    },
    {
        "id": "sy",
        "code": "963",
        "label": "+963 (Syria)"
    },
    {
        "id": "tw",
        "code": "886",
        "label": "+886 (Taiwan)"
    },
    {
        "id": "tj",
        "code": "992",
        "label": "+992 (Tajikistan)"
    },
    {
        "id": "tz",
        "code": "255",
        "label": "+255 (Tanzania)"
    },
    {
        "id": "th",
        "code": "66",
        "label": "+66 (Thailand)"
    },
    {
        "id": "tl",
        "code": "670",
        "label": "+670 (Timor-Leste)"
    },
    {
        "id": "tg",
        "code": "228",
        "label": "+228 (Togo)"
    },
    {
        "id": "to",
        "code": "676",
        "label": "+676 (Tonga)"
    },
    {
        "id": "tt",
        "code": "1868",
        "label": "+1868 (Trinidad and Tobago)"
    },
    {
        "id": "tn",
        "code": "216",
        "label": "+216 (Tunisia)"
    },
    {
        "id": "tr",
        "code": "90",
        "label": "+90 (Turkey)"
    },
    {
        "id": "tm",
        "code": "993",
        "label": "+993 (Turkmenistan)"
    },
    {
        "id": "tv",
        "code": "688",
        "label": "+688 (Tuvalu)"
    },
    {
        "id": "ug",
        "code": "256",
        "label": "+256 (Uganda)"
    },
    {
        "id": "ua",
        "code": "380",
        "label": "+380 (Ukraine)"
    },
    {
        "id": "ae",
        "code": "971",
        "label": "+971 (United Arab Emirates)"
    },
    {
        "id": "gb",
        "code": "44",
        "label": "+44 (United Kingdom)"
    },
    {
        "id": "us",
        "code": "1",
        "label": "+1 (United States)"
    },
    {
        "id": "uy",
        "code": "598",
        "label": "+598 (Uruguay)"
    },
    {
        "id": "uz",
        "code": "998",
        "label": "+998 (Uzbekistan)"
    },
    {
        "id": "vu",
        "code": "678",
        "label": "+678 (Vanuatu)"
    },
    {
        "id": "va",
        "code": "39",
        "label": "+39 (Vatican City)"
    },
    {
        "id": "ve",
        "code": "58",
        "label": "+58 (Venezuela)"
    },
    {
        "id": "vn",
        "code": "84",
        "label": "+84 (Vietnam)"
    },
    {
        "id": "ye",
        "code": "967",
        "label": "+967 (Yemen)"
    },
    {
        "id": "zm",
        "code": "260",
        "label": "+260 (Zambia)"
    },
    {
        "id": "zw",
        "code": "263",
        "label": "+263 (Zimbabwe)"
    }
];

export default countries;