import React from 'react';
import classNames from 'classnames';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  circle: {
    background: "#F55446", height: "10px", width: "10px",
    borderRadius: "50%", position: "absolute",
  },
  static: { zIndex: 4, },
  first: { animation: "$ripple-first 2s infinite", zIndex: 2, opacity: 0.5 },
  second: {
    opacity: 0.3, animation: "$ripple-second 2s infinite", zIndex: 1,
  },
  "@keyframes ripple-first": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(2)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes ripple-second": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(3)" },
    "100%": { transform: "scale(1)" },
  }
});

const sx = {
  root: {
    position: 'relative', overflow: "hidden", width: "fit-content",
    margin: 'auto', borderRadius: 2, mx: "auto", height: 48, px: 3,
    display: "flex", alignItems: "center", justifyContent: "center",
    backgroundColor: "transparent",
  },
  text: {
    fontFamily: "Inter", fontStyle: "normal", fontWeight: 500,
    fontSize: "12px",
  },
};

function RecordingIndicator({ state }) {
  const classes = useStyles();

  return (
    <Box
      sx={sx.root}
      style={{ visibility: "RECORDING" === state ? "visible" : "hidden" }}
    >
      <Box position="relative" width={24} height={12}>
        <div className={classNames(classes.circle, classes.static)}></div>
        <div className={classNames(classes.circle, classes.first)}></div>
        <div className={classNames(classes.circle, classes.second)}></div>
      </Box>
      <Typography sx={sx.text}> Listening...</Typography>
    </Box>
  );
}

export default RecordingIndicator;