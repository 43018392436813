import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import SpeechRecorder from 'languify-speech-recorder';
import React from 'react';

import Button from 'components/Button';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import SectionShell from 'components/IELTSMock/SectionShell';
import PartDescription from 'components/PartDescription';
import MicrophoneTest from './MicrophoneTest';
import PreSpeaking from './PreSpeaking';
import RecordQuestion from './RecordQuestion';

import useStore from 'utils/ieltsStore';


const PartsNavigation = () => {
    const { questions = [] } = useStore(
        state => state.sections?.[state.section] || {}
    );
    const currentQuestion = useStore(state => state.currentQuestion);
    const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
    const setPartIndex = useStore((state) => state.setPartIndex);
    const isRecording = useStore((state) => state.isRecording);


    const onSelect = (pIndex, qIndex) => {
        setPartIndex(pIndex);
        setCurrentQuestion(qIndex);
    };

    return (
        <Stack direction='row' alignItems="center" flexWrap="wrap">
            {questions?.map((q, j) => (
                <React.Fragment key={j}>
                    {q.part !== questions?.[j - 1]?.part && (
                        <Typography
                            component="span" fontWeight={600}
                            fontSize={'1.6vmin'} flexShrink={0}
                            ml={2}
                        >
                            Part {q.part + 1}
                        </Typography>
                    )}
                    <Button
                        variant="contained"
                        color={currentQuestion === j ? 'primary' : 'default'}
                        disableElevation
                        key={j}
                        style={{
                            padding: "1px 14px", minWidth: 'fit-content',
                            fontSize: '1.3vmin', flexShrink: 0
                        }}
                        disabled={isRecording}
                        title={q.answer.submitted ? "Already recorded" : ""}
                        onClick={() =>
                            q.answer.submitted ? {} : onSelect(q.part, j)
                        }
                    >
                        {j + 1}
                    </Button>
                </React.Fragment>
            ))}
        </Stack>
    );
};

function SpeakingSection({ analyseAnswer, canFinish }) {
    const [current, setCurrent] = React.useState(0);

    const { name, time, parts, questions, duration } = useStore(
        state => state.sections[state.section]
    );
    const currentQuestion = useStore(state => state.currentQuestion);

    const question = React.useMemo(
        () => questions?.[currentQuestion], [currentQuestion, questions]
    );
    const partIndex = React.useMemo(() => question.part, [question]);
    const part = React.useMemo(() => parts?.[question.part], [question, parts]);

    const speechRecorder = React.useRef(null);
    const isRecording = useStore((state) => state.isRecording);

    const handleNext = () => {
        if (current < 3) {
            setCurrent((x) => x + 1);
        }
    }

    /**
     * Effect to initialize SpeechRecorder
     */
    React.useEffect(() => {
        if (name.toUpperCase() === 'SPEAKING') {
            speechRecorder.current = new SpeechRecorder({ isMobile: false });

            return () => speechRecorder.current?.destructor();
        }
    }, [name]);

    return (
        <SectionShell
            active
            timerProps={{
                hide: [1, 3, 4].includes(current),
                totalTime: time[current],
                onTimeout: handleNext
            }}
            headerProps={{
                hideFinish: ((!canFinish && isRecording) || current !== 3),
                disableEnd: isRecording || current === 4,
            }}
            footerProps={{
                hide: current !== 3,
                disableNext: true,
                disablePrev: true,
                children: (current === 3) ? <PartsNavigation /> : null,
                hideNav: true,
            }}
        >
            <PreSpeaking
                active={current === 0}
                onNext={handleNext}
            />

            <MicrophoneTest
                active={current === 1}
                onSuccess={handleNext}
            />

            <SectionInstructions
                type="ielts"
                active={current === 2}
                name="Speaking"
                onNext={handleNext}
                time={duration}
            />

            {current === 3 && (
                <>
                    <PartDescription
                        part={partIndex + 1}
                        content={part.description}
                    />
                    <RecordQuestion
                        active={current === 3}
                        speechRecorder={speechRecorder}
                        analyseAnswer={analyseAnswer}
                        question={question}
                    />
                </>
            )}
        </SectionShell>
    );
}

export default SpeakingSection;