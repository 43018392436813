import React from 'react';
import Typography from '@mui/material/Typography';
import ImageInfo from './ImageInfo';
import { styled } from '@mui/material/styles';

const FlexBox = styled('div')(({ theme, width }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    margin: '0.5em auto 5em auto',
    width: width,
    [theme.breakpoints.down('sm')]: {
        width: '98%'
    },
    [theme.breakpoints.down('md')]: {
        width: '90%'
    },
}));

function ImagesFlexbox({ flexboxTitle, imagesInfo, width }) {
    return (
        <>
            <Typography variant='h1' textAlign='center' gutterBottom>
                {flexboxTitle}
            </Typography>
            <FlexBox width={width} >
                {
                    imagesInfo.map((image, index) => (
                        <ImageInfo
                            key={index}
                            src={image.url}
                            name={image.name}
                            title={image.title}
                        />
                    ))
                }
            </FlexBox>
        </>
    );
}

export default ImagesFlexbox;
