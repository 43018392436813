import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { getToeflLevelByScore } from "utils";
import useStore from "utils/reportStore";
import CircleProgressbar from "../CircleProgressbar";
import Title from "../Title";
import { Sections } from "./constants";

export default function SectionShell({ active, score, children, }) {

    const section = useStore((state) => state.section);
    const sectionName = useStore((state) => state.sections?.[section]?.name?.toUpperCase());

    const SECTION = Sections?.[sectionName];

    const {
        color, description, label
    } = getToeflLevelByScore(score, sectionName) || {};

    return (
        (active) ? <>
            <Title title={SECTION?.title} />

            <Box mt={4} mb={2} display='flex' gap={6}>
                <Box display="flex"
                    flexDirection="column"
                    alignItems="center"
                    fontSize="12px"
                >
                    {(score >= 0) &&
                        <CircleProgressbar
                            value={score} size={100}
                            percent={false} color={color}
                        />}
                    <Typography mt={1}>Score</Typography>
                </Box>
                <Box>
                    <Typography
                        fontSize='16px'
                        fontWeight={500}
                        fontFamily='inter'
                        mb={2} mt={1}
                    >
                        {SECTION?.subTitle} : &nbsp;
                        <span style={{ color: color }}>
                            {label || 'NA'}
                        </span>
                    </Typography>
                    <Typography
                        fontSize='14px'
                        fontWeight={500}
                        fontFamily='inter'
                        color='rgba(0, 0, 0, 0.6)'
                    >
                        {description || 'NA'}
                    </Typography> <br />
                </Box>
            </Box>

            <Divider /><br />

            {children}

        </> : null
    );
}