import React from 'react';
import { Link, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SuccessIcon from '@mui/icons-material/CheckCircleOutline';
import Button, { CopyButton } from 'components/Button';
import ContentShell from 'components/ContentShell';
import Session from 'utils/Session';

const message = {
  toefl: 'Hi, take free unlimited TOEFL tests on Languify. Practice now and improve your chances to score above 100 and get into your dream university. https://teofl.languify.in',
  ielts: 'Hi, take free unlimited IELTS tests on Languify. Practice now and improve your chances to score higher and get into your dream university. https://ielts.languify.in'
}

function ThankYou(props) {
  const { type } = useParams();

  return (
    <ContentShell>
      <Box backgroundColor="white.main" borderRadius={2} p={3} maxWidth={650}>
        <Box
          width="100%" height="fit-content"
          justifyContent="center" position="relative" mx="auto"
          flexDirection="column" backgroundColor="white.main"
          display="flex" mb={1}
        >
          <Box display="flex">
            <SuccessIcon style={{ fontSize: 56 }} color="success" />
            <Box ml={2}>
              <Typography variant="h4" fontWeight={700}>
                Successfully registered
              </Typography>
              <Typography variant="subtitle2" mb={1} color="grey">
                We have sent Detailed Grade Report of test to your e-mail.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Divider />

        <ul>
          {Session.isLoggedIn() && (
            <>
              <Typography my={4} component="li">
                This test was brought to you for FREE by our partner’s:
              </Typography>
              <Typography my={4} component="li">
                {type.toUpperCase()} expert will reach out to you for a free 1-on-1 counselling based on your report
              </Typography>
            </>
          )}
          <Typography my={4} component="li">
            If you do not receive the report within a few minutes of test completion, please check your Promotions/Spam e-mail folder.<br />
            Plus, if you want to make sure you get everything ➝ drag this email into your primary inbox
          </Typography>
        </ul>

        <Divider />

        <Typography variant='body1' mt={2} mb={1} fontWeight="bold">
          Please share among your network
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton
            component="a" target="_blank" rel="noreferrer"
            href={`https://web.whatsapp.com://send?text=${encodeURIComponent(message[type.toLowerCase()])}`}
          >
            <WhatsAppIcon color="success" />
          </IconButton>
          <CopyButton content={message[type.toLowerCase()]} title="Copy Link" />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/" style={{ textDecoration: 'none' }}>
            <Button style={{ padding: '4px 16px', }} variant="outlined">
              Back to Languify
            </Button>
          </Link>
        </Box>
      </Box>
    </ContentShell>
  );
}

export default ThankYou;