import Session from "utils/Session";
import Client from "./_client";

export const Countries = [
  { _id: "US", name: "US" },
  { _id: "Canada", name: "Canada" },
  { _id: "China", name: "China" },
  { _id: "Dubai", name: "Dubai" },
  { _id: "England", name: "England" }
];

export const Boards = [
  { _id: "CBSE", name: "CBSE Board" },
  { _id: "ICSE", name: "ICSE Board" },
  { _id: "State", name: "State Board" },
];

export const WorkExperiences = [
  { _id: "none", name: "No experience" },
  { _id: "fresher", name: "Fresher (0-1 year)" },
  { _id: "learning", name: "Learning (1-2 year)" },
  { _id: "master", name: "Master (2+ year)" },
];

export const ExamTimes = [
  { _id: "none", name: "Not sure" },
  { _id: "3", name: "Within 3 months" },
  { _id: "6", name: "Within 6 months" },
  { _id: "12", name: "Within 12 months" },
];

export const updatedEducationInfo = async (educationInfo) => {

  const result = await new Client({
    path: `/user/${Session.userId}/education-info`,
    payload: educationInfo
  }).put();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! fetch the test. Please try again!"
    );
  }

  return result?.data;
};

export const postFeedback = async (rating, comment, feature) => {
  const result = await new Client({
    path: "/feedback",
    payload: {
      rating, comment, user: Session.userId,
      feature: feature || ""
    },
  }).post();

  if (!(result.ok || result.code === 200)) {
    throw new Error(
      result.message || "Uh Oh! Unable to send feedback. Please try again."
    );
  }

  return result.data;
};