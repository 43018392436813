import Client from "./_client";

export const generateMock = async ({ type, sections = [], attemptId, short }) => {
    let url = "/mockSection/generate-mock?";

    if (attemptId) url += `&attempt=${attemptId}`;
    if (type) url += `&type=${type}`;
    if (sections) url += `&sections=${sections}`;
    if (short) url += `&short=${short}`;

    const result = await new Client({ path: url }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! cannot generate the mock. Please try again!"
        );
    }

    return result?.data;
};