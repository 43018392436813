import Client from "./_client";

export const getCities = async (name) => {
    const result = await new Client({
        path: `/geodb/cities?name=${name}`,
    }).get();

    if (!(result.ok || result.code === 200)) {
        throw new Error(
            result.message || "Uh Oh! Unable to get cities"
        );
    }

    return result?.data;
};