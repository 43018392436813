import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { LightButton } from "components/Button";
import ImageCarousel from "./ImageCarousel";
import UniversitiesCarousal from "./UniversitiesCarousal";
import MockSelectDialog from "dialogs/MockSelectDialog";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "#001929",
        minHeight: "calc(100vh - 120px)",
        height: "fit-content",
        display: "flex",
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        position: "relative",
        padding: "16px 0",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-evenly", minHeight: "fit-content",
        }
    },
    middleContainer: {
        display: "flex",
        justifyContent: "space-around",
        minHeight: "calc(100vh - 280px)",
        alignItems: "stretch",
        width: "100%",
        position: "relative",
        marginBottom: 8,
        [theme.breakpoints.down("md")]: {
            flexDirection: 'column',
            alignItems: "stretch",
            height: "fit-content",
            minHeight: "fit-content",
        }
    },
    innerContainer: {
        minWidth: "80ch", margin: 32, alignItems: "center",
        [theme.breakpoints.down("md")]: {
            alignSelf: "start", margin: 0, padding: "16px 0px",
        },
    },
    buttonContainer: {
        display: "flex", alignItems: "center", marginTop: 32,
        [theme.breakpoints.down("md")]: {
            width: "100vw", padding: "0px 8px"
        },
    },
    buttonStyle: {
        borderRadius: "8px",
        textTransform: "none",
        display: "flex",
        padding: "12px 3vw",
        width: "50%",
        maxWidth: 230,
        textDecoration: 'none',
        [theme.breakpoints.down("md")]: {
            padding: "10px 20px",
            borderRadius: "4px",
        },
    },
    logo: {
        width: "40%",
        height: "auto",
        objectFit: "cover",
        [theme.breakpoints.down('md')]: {
            width: "60%",
            height: "auto",
            maxHeight: 350,
        }
    },
    heading: {
        fontSize: "6vmin !important", textAlign: "left", marginBottom: 16,
        minHeight: 100, fontWeight: 800, lineHeight: "150%",
        [theme.breakpoints.down("md")]: {
            marginLeft: 16, marginBottom: 0, minHeight: 80,
        }
    },
    subheading: {
        fontSize: "2.8vmin !important", textAlign: "left", marginBottom: 16,
        fontWeight: 500,
        [theme.breakpoints.down("md")]: {
            marginLeft: 16, fontSize: "4vmin !important",
        }
    },
}));

export default function Main() {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);

    const rc = searchParams.get('rc');

    return (
        <Box className={classes.container}>
            <Box className={classes.middleContainer}>
                <Box className={classes.innerContainer}>
                    <Typography className={classes.heading} color="white.main">
                        Preparing for<br />
                        <span style={{ color: "#2DABFF", }}>
                            FREE TOEFL&#174;&nbsp;or IELTS&#8482;
                        </span>
                        &nbsp;Test?
                    </Typography>
                    <Typography className={classes.subheading} color="white.main">
                        Try a 30 Minutes Diagnostic, Full 3 Hour or Sectional<br />
                        TOEFL&#174; or IELTS&#8482; Mock
                    </Typography>
                    <Box className={classes.buttonContainer}>
                        <LightButton
                            LinkComponent="a"
                            href={`/choose-test?t=toefl&rc=${rc}`}
                            variant="contained"
                            className={classes.buttonStyle}
                            style={{
                                marginRight: "2vw", textDecoration: 'none'
                            }}
                        >
                            Free TOEFL Test
                        </LightButton>
                        <LightButton
                            LinkComponent="a"
                            href={`/choose-test?t=ielts&rc=${rc}`}
                            variant="contained"
                            className={classes.buttonStyle}
                            style={{
                                background: "#3FD39E",
                            }}
                        >
                            Free IELTS Test
                        </LightButton>
                    </Box>
                </Box >
                <ImageCarousel />
            </Box>
            <Typography variant="subtitle1" color="white.main">
                Know where you stand&nbsp;
                <span
                    onClick={() => setOpen(o => !o)}
                    style={{ cursor: "pointer", color: "#2DABFF" }}
                >
                    Try Now!
                </span>
            </Typography>
            <UniversitiesCarousal />
            <MockSelectDialog open={open} onClose={handleClose} />
        </Box >
    );
}