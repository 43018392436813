import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import AppShell from 'components/AppShell';
import { LightButton } from 'components/Button';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: "100%",
  },
  button: { width: 245 }
});

function NotFound() {
  const classes = useStyles();

  return (
    <AppShell hideAnnouncement>
      <Box className={classes.container}
      >
        <Typography
          variant='body1'
          color='#2C3840'
          fontWeight={600}
          mb='3em'
        >
          The page you are looking for is not found
        </Typography>
        <Box textAlign='center'>
          <Typography
            variant='h5'
            color='#D8D8D8'
            fontWeight={600}
            mb={-5}
          >
            Error
          </Typography>
          <Typography
            variant='h1'
            color='#D8D8D8'
            fontFamily='Sora'
            fontSize={300}
          >
            404
          </Typography>
        </Box>
        <Link style={{ textDecoration: 'none' }} to="/">
          <LightButton
            variant='contained'
            className={classes.button}
          >
            Go Back to Homepage
          </LightButton>
        </Link>
      </Box>
    </AppShell>
  );
}

export default NotFound;
