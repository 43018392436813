export const ToeflLevels = {
    ADVANCED: {
        id: 'ADVANCED',
        label: 'Advanced',
        color: '#41D7A1',
        backgroundColor: '#EDFFF7',
    },
    HIGH_INTERMEDIATE: {
        id: 'HIGH_INTERMEDIATE',
        label: 'High-Intermediate',
        color: '#367CFF',
        backgroundColor: '#EDFFF7',
    },
    LOW_INTERMEDIATE: {
        id: 'LOW_INTERMEDIATE',
        label: 'Low-Intermediate',
        color: '#FFCC00',
        backgroundColor: '#FFF9ED',
    },
    BELOW_LOW_INTERMEDIATE: {
        id: 'BELOW_LOW_INTERMEDIATE',
        label: 'Below Low-Intermediate',
        color: '#EE2D2D',
        backgroundColor: '#FFF0F0',
    },
    DEFAULT: {
        id: 'DEFAULT',
        label: 'NA',
        color: '#737373',
        backgroundColor: '#737373',
    },
};

export const SectionBaisedDescription = {
    "OVERALL": {
        "ADVANCED": "You have obtained a a very good Advance score which is considered Advanced skills. You need to slightly improve your performance, try to improve your marks with every test.  Whenever you attempt the mock test, try to find out your weaknesses and score more in every mock. Focus on the section you feel the tough. All the best…",
        "HIGH_INTERMEDIATE": "You have obtained a good Intermediate score which is considered High-Intermediate skills. You need to improve your performance to reach the advance skills set, try to improve your marks with every test.  Whenever you attempt the mock test, try to find out your weaknesses and score more in every mock. Focus on the weak section and be pledged to improve. All the best…",
        "LOW_INTERMEDIATE": "You have obtained a Intermediate score which is considered Low-Intermediate skills. You need to improve your performance to get a better score, try to improve your marks with every test.  Whenever you attempt the mock test, try to find out your weaknesses and score more in every mock. Focus on the weak section and be pledged to improve. All the best…",
        "BELOW_LOW_INTERMEDIATE": "You have obtained a very low score which is considered Below Low-Intermediate skills. You need to improve your performance, try to improve your marks with every test.  Whenever you attempt the mock test, try to find out your weaknesses and score more in every mock. Focus on the weaker section and be pledged to improve. All the best…"
    },
    "READING": {
        "ADVANCED": "Understand a range of academic and low-frequency vocabulary as well as less common meanings of words. Understand explicit connections among pieces of information and make appropriate inferences, even when the passage is conceptually dense and the language is complex. Recognize the expository organization of a passage and the purpose that specific information serves within the larger context, even when the purpose of the information is not marked, and the passage is conceptually dense.",
        "HIGH_INTERMEDIATE": "Understand common academic vocabulary, but sometimes have difficulty with low-frequency words or less common meanings of words. Understand explicit connections among pieces of information and make appropriate inferences, but may have difficulty in parts of a passage that contain low-frequency vocabulary or that are conceptually dense, rhetorically complex, or abstract.",
        "LOW_INTERMEDIATE": "Understand texts with basic grammar, but have inconsistent understanding of texts with complex grammatical structures. Understand high-frequency academic vocabulary, but often have difficulty with lower-frequency words. Locate information in a passage by matching words or relying on high-frequency vocabulary, but their limited ability to recognize paraphrases results in incomplete understanding of the connections among ideas and information. Identify an author’s purpose when that purpose is explicitly stated or easy to infer from the context.",
        "BELOW_LOW_INTERMEDIATE": "Test takers with a Reading section score below 4 have not yet demonstrated proficiency at the Low-Intermediate level"
    },
    "LISTENING": {
        "ADVANCED": "Understand main ideas and explicitly stated important details, even if not reinforced. Distinguish important ideas from less important points. Keep track of conceptually complex, sometimes conflicting, information over extended portions of a lecture. Understand how information or examples are being used (for example, to provide evidence for or against a claim, to make comparisons or draw contrasts, or to express an opinion or a value judgment) and how pieces of information are connected (for example, in a cause-effect relationship).",
        "HIGH_INTERMEDIATE": "Understand main ideas and explicitly stated important details that are reinforced (by repetition, paraphrase, or indirect reference). Distinguish main ideas from other information. Keep track of information over an extended portion of an information-rich lecture or conversation, and recognize multiple, possibly conflicting, points of view. Understand how information or examples are being used (for example, to provide support for a claim), and how pieces of information are connected (for example, in a narrative explanation, a compare-and-contrast relationship, or a causeeffect chain).",
        "LOW_INTERMEDIATE": "Understand main ideas, even in complex discussions, when the ideas are repeatedly referred to, extensively elaborated on, or illustrated with multiple examples. Understand explicitly stated important details, but may have difficulty understanding details if they are not reinforced (such as through repetition or with an example) or marked as important, or if they are conveyed over several exchanges among different speakers.",
        "BELOW_LOW_INTERMEDIATE": "Test takers with a Listening section score below 9 have not yet demonstrated proficiency at the Low-Intermediate level."
    },
    "SPEAKING": {
        "ADVANCED": "Speak clearly and use intonation to support meaning so that speech is generally easy to understand and follow; any minor lapses do not obscure meaning. Speak with relative ease on a range of general and academic topics, demonstrating control of an appropriate range of grammatical structures and vocabulary; any minor errors may be noticeable, but do not obscure meaning. ",
        "HIGH_INTERMEDIATE": "Speak clearly and without hesitancy on general or familiar topics, with overall good intelligibility; pauses and hesitations (to recall or plan information) are sometimes noticeable when more demanding content is produced, and any mispronunciations or intonation errors only occasionally cause problems for the listener. Produce stretches of speech that demonstrate control of some complex structures and a range of vocabulary, although occasional lapses in precision and accuracy may obscure meaning at times.",
        "LOW_INTERMEDIATE": "Speak clearly with minor hesitancies about general or familiar topics; longer pauses are noticeable when speaking about more complex or academic topics, and mispronunciations may obscure meaning at times. Produce short stretches of speech consisting of basic grammatical structures connected with “and”, “because” and “so”; attempts at longer utterances requiring more complex grammatical structures may be marked by errors and pauses for grammatical planning or repair; use vocabulary that is sufficient to discuss general or familiar topics, but limitations in range of vocabulary sometimes result in vague or unclear expression of ideas. ",
        "BELOW_LOW_INTERMEDIATE": "Speak slowly and carefully so that they make themselves understood, but pronunciation may be strongly influenced by the speaker’s first language and at times be unintelligible; speech may be marked by frequent pauses, reformulations, and false starts. Produce mostly short utterances, connecting phrases with simple linking words (such as “and”) to make themselves understood; grammar and vocabulary are limited, and frequent pauses may occur while searching for words. "
    },
    "WRITING": {
        "ADVANCED": "Express an opinion on a controversial issue, and support that opinion with appropriate details and explanations in writing, demonstrating variety and range of vocabulary and grammatical structures. Select important information from multiple sources, integrate it, and present it coherently and clearly in writing, with only occasional minor imprecision in the summary of the source information.",
        "HIGH_INTERMEDIATE": "Produce summaries of multiple sources that include most of the main ideas; some important ideas from the sources may be missing, unclear, or inaccurate. Express an opinion on an issue clearly; some ideas and explanations may not be fully developed and lapses in cohesion may at times affect a clear progression of ideas.",
        "LOW_INTERMEDIATE": "Produce a simple text that expresses some ideas on an issue, but the development of ideas is limited because of insufficient or inappropriate details and explanations. Summarize some relevant information from multiple sources, but important ideas from the sources are omitted or significantly misrepresented, especially ideas that require unfamiliar vocabulary or are complex.",
        "BELOW_LOW_INTERMEDIATE": "Produce some text that is related to the topic, but with little detail and/or lack of organization. Convey some information from the sources or some ideas on an issue, but grammatical errors, unclear expressions, and/or poor sentence structure make their writing difficult to comprehend."
    },
}

export const Levels = {
    EXPERT: {
        label: 'Expert',
        color: '#41D7A1',
        backgroundColor: '#EDFFF7',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',
        cefrLevel: 'CEFR Level C1',
        img: 'https://assets.languify.in/images/good-emoji.svg',
        grade: 'C1',
    },
    INTERMEDIATE: {
        label: 'Intermediate',
        color: '#FFCC00',
        backgroundColor: '#FFF9ED',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',
        cefrLevel: 'CEFR Level B1',
        img: 'https://assets.languify.in/images/avg-emoji.svg',
        grade: 'B1',
    },
    BEGINNER: {
        label: 'Beginner',
        color: '#EE2D2D',
        backgroundColor: '#FFF0F0',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',
        cefrLevel: '-',
        img: 'https://assets.languify.in/images/bad-emoji.svg',
        grade: '-',
    },
};

export const Sections = {
    "READING": {
        label: 'Reading',
        title: 'Detailed Analysis for the Reading comprehension',
        subTitle: 'Reading comprehension skills',
        tipTitle: 'Customised tips for the reading comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "You can skip questions and go back to them as long as there is time remaining.",
            "Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor details in the passage.",
            "Use Scanning and Skimming techniques to reduce the time required to complete the session.",
            "Focus on keywords",
        ],
        info: 'reading info',
        description: 'Improving your vocabulary skills is one of the most critical aspects of clearing the section. Try to learn words from various subjects, including biology, social sciences, and business arts, along with suffices, prefixes, and common root words.',
        scale: {
            0: "BELOW_LOW_INTERMEDIATE",
            1: "BELOW_LOW_INTERMEDIATE",
            2: "BELOW_LOW_INTERMEDIATE",
            3: "BELOW_LOW_INTERMEDIATE",
            4: "LOW_INTERMEDIATE",
            5: "LOW_INTERMEDIATE",
            6: "LOW_INTERMEDIATE",
            7: "LOW_INTERMEDIATE",
            8: "LOW_INTERMEDIATE",
            9: "LOW_INTERMEDIATE",
            10: "LOW_INTERMEDIATE",
            11: "LOW_INTERMEDIATE",
            12: "LOW_INTERMEDIATE",
            13: "LOW_INTERMEDIATE",
            14: "LOW_INTERMEDIATE",
            15: "LOW_INTERMEDIATE",
            16: "LOW_INTERMEDIATE",
            17: "LOW_INTERMEDIATE",
            18: "HIGH_INTERMEDIATE",
            19: "HIGH_INTERMEDIATE",
            20: "HIGH_INTERMEDIATE",
            21: "HIGH_INTERMEDIATE",
            22: "HIGH_INTERMEDIATE",
            23: "HIGH_INTERMEDIATE",
            24: "ADVANCED",
            25: "ADVANCED",
            26: "ADVANCED",
            27: "ADVANCED",
            28: "ADVANCED",
            29: "ADVANCED",
            30: "ADVANCED",
        }
    },
    "LISTENING": {
        label: 'Listening',
        title: 'Detailed Analysis for the Listening comprehension',
        subTitle: 'Listening comprehension skills',
        tipTitle: 'Customised tips for the listening comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "You may take notes while you listen and use the notes to help you answer the questions.",
            "For questions where you need to fill in missing words, make sure the resulting sentence is grammatically accurate.",
            "Be particularly careful about singular and plural forms. ",
            "By and large, you’re expected to give the exact word (s) you heard. ",
            "Make sure you listen to the questions or read them carefully and answer all the points needed."
        ],
        info: 'listening info',
        description: 'Note the speaker’s style, hope, and degree of certainty, intonation, and repetition of ideas to achieve the purpose of the lecture or conversion. Explore other sources as it is not only about listening, but you need to process the emotion in the lecture or conversation. So, try to listen to debates, broadcasts, and documentaries to get the best out of your TOEFL preparation.',
        scale: {
            0: "BELOW_LOW_INTERMEDIATE",
            1: "BELOW_LOW_INTERMEDIATE",
            2: "BELOW_LOW_INTERMEDIATE",
            3: "BELOW_LOW_INTERMEDIATE",
            4: "BELOW_LOW_INTERMEDIATE",
            5: "BELOW_LOW_INTERMEDIATE",
            6: "BELOW_LOW_INTERMEDIATE",
            7: "BELOW_LOW_INTERMEDIATE",
            8: "BELOW_LOW_INTERMEDIATE",
            9: "LOW_INTERMEDIATE",
            10: "LOW_INTERMEDIATE",
            11: "LOW_INTERMEDIATE",
            12: "LOW_INTERMEDIATE",
            13: "LOW_INTERMEDIATE",
            14: "LOW_INTERMEDIATE",
            15: "LOW_INTERMEDIATE",
            16: "LOW_INTERMEDIATE",
            17: "HIGH_INTERMEDIATE",
            18: "HIGH_INTERMEDIATE",
            19: "HIGH_INTERMEDIATE",
            20: "HIGH_INTERMEDIATE",
            21: "HIGH_INTERMEDIATE",
            22: "ADVANCED",
            23: "ADVANCED",
            24: "ADVANCED",
            25: "ADVANCED",
            26: "ADVANCED",
            27: "ADVANCED",
            28: "ADVANCED",
            29: "ADVANCED",
            30: "ADVANCED",
        }
    },
    "SPEAKING": {
        label: 'Speaking',
        title: 'Detailed Analysis for Speaking assesment',
        subTitle: 'Communication Skills',
        tipTitle: 'Customised tips for the speaking comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "Listen to the text, it will help you understand the context in a better way",
            "Write what you hear, and make a habit to take down the important points you feel",
            "Be clear and loud don't fumble. ",
            "Follow a simple accent while speaking."
        ],
        info: 'speaking info',
        description: 'Make a note on crucial points while listening and focus on what is being conveyed and jot down a brief note for better understating. Preparing and Listening to your own recorded conversation is one of the best TOEFL preparations as it will help you to identify your mistakes and improve your enunciation skills.',
        scale: {
            0: "BELOW_LOW_INTERMEDIATE",
            1: "BELOW_LOW_INTERMEDIATE",
            2: "BELOW_LOW_INTERMEDIATE",
            3: "BELOW_LOW_INTERMEDIATE",
            4: "BELOW_LOW_INTERMEDIATE",
            5: "BELOW_LOW_INTERMEDIATE",
            6: "BELOW_LOW_INTERMEDIATE",
            7: "BELOW_LOW_INTERMEDIATE",
            8: "BELOW_LOW_INTERMEDIATE",
            9: "BELOW_LOW_INTERMEDIATE",
            10: "BELOW_LOW_INTERMEDIATE",
            11: "BELOW_LOW_INTERMEDIATE",
            12: "LOW_INTERMEDIATE",
            13: "LOW_INTERMEDIATE",
            14: "LOW_INTERMEDIATE",
            15: "LOW_INTERMEDIATE",
            16: "LOW_INTERMEDIATE",
            17: "HIGH_INTERMEDIATE",
            18: "HIGH_INTERMEDIATE",
            19: "HIGH_INTERMEDIATE",
            20: "HIGH_INTERMEDIATE",
            21: "HIGH_INTERMEDIATE",
            22: "HIGH_INTERMEDIATE",
            23: "HIGH_INTERMEDIATE",
            24: "ADVANCED",
            25: "ADVANCED",
            26: "ADVANCED",
            27: "ADVANCED",
            28: "ADVANCED",
            29: "ADVANCED",
            30: "ADVANCED",
        },
        parameters: {
            'fluency': 'Fluency',
            'delivery': 'Delivery',
            'vocabulary': 'Vocabulary',
            'pauses': 'Pauses',
            'phraseRepeatRating': 'Phrase Repetition',
        },
        tags: {
            "fillerPhrases": "Filler Phrases",
            'fillerWords': 'Filler Words',
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'speechRate': 'Speech rate',
            'breakdown': 'Breakdown'
        }
    },
    "WRITING": {
        label: 'Writing',
        title: 'Detailed Analysis for Writing assesment',
        subTitle: 'Writing Skills',
        tipTitle: 'Customised tips for the writing comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "You may look back at the passage when answering the question, so you can use it to optimize and write the expected answer.",
            "You may take notes while you read and listen.",
            "Typically, an effective response will contain a minimum of 300 words",
        ],
        info: 'writing info',
        description: 'Ensure to structure the answer that will help you score a high grade. Follow the general step-by-step process of reading, drafting, and writing to build a well-documented passage, with the key details mentioned in a precise way',
        scale: {
            0: "BELOW_LOW_INTERMEDIATE",
            1: "BELOW_LOW_INTERMEDIATE",
            2: "BELOW_LOW_INTERMEDIATE",
            3: "BELOW_LOW_INTERMEDIATE",
            4: "BELOW_LOW_INTERMEDIATE",
            5: "BELOW_LOW_INTERMEDIATE",
            6: "BELOW_LOW_INTERMEDIATE",
            7: "BELOW_LOW_INTERMEDIATE",
            8: "BELOW_LOW_INTERMEDIATE",
            9: "BELOW_LOW_INTERMEDIATE",
            10: "BELOW_LOW_INTERMEDIATE",
            11: "BELOW_LOW_INTERMEDIATE",
            12: "BELOW_LOW_INTERMEDIATE",
            13: "BELOW_LOW_INTERMEDIATE",
            14: "BELOW_LOW_INTERMEDIATE",
            15: "BELOW_LOW_INTERMEDIATE",
            16: "LOW_INTERMEDIATE",
            17: "LOW_INTERMEDIATE",
            18: "LOW_INTERMEDIATE",
            19: "LOW_INTERMEDIATE",
            20: "HIGH_INTERMEDIATE",
            21: "HIGH_INTERMEDIATE",
            22: "HIGH_INTERMEDIATE",
            23: "HIGH_INTERMEDIATE",
            24: "HIGH_INTERMEDIATE",
            25: "ADVANCED",
            26: "ADVANCED",
            27: "ADVANCED",
            28: "ADVANCED",
            29: "ADVANCED",
            30: "ADVANCED",
        },
        parameters: {
            'grammar': 'Grammar',
            'vocabulary': 'Vocabulary',
            'spelling': 'Spelling',
            'phraseRepetition': 'Phrase Repetition',
            'slangs': 'Slangs',
        },
        tags: {
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'overUsedWords': 'Over used words'
        }
    },
}