import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import ContentShell from 'components/ContentShell';
import Select, { MultipleSelect } from 'components/Select';
import { useLoading, useSnackbar } from 'contexts';
import { EducationInfoSchema } from 'schemas';
import {
  updatedEducationInfo, Boards, Countries, WorkExperiences, ExamTimes
} from 'services/user';
import { FormHelperText } from '@mui/material';
import { isBrowserSupported } from 'utils';


function RadioOptions({ error, name, label, options = [] }) {
  return (
    <FormControl size="small" sx={{ my: 1 }} error={!!error}>
      <Typography fontSize={14} fontWeight={700} color="#00000099">
        {label}
      </Typography>
      <RadioGroup name={name} row sx={{ pl: 1 }} required>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            value={option}
            control={<Radio color="primary" size="small" sx={{ p: 0.5 }} />}
            label={option}
            componentsProps={{ typography: { fontSize: 12 } }}
          />
        ))}
      </RadioGroup>
      {error && (<FormHelperText error={!!error}>
        Please select an option
      </FormHelperText>)}
    </FormControl>
  );
}

function EducationInfo(props) {
  const navigate = useNavigate();
  const { type } = useParams();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const [selectedCountries, setSelectedCountries] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    const _newCountries = typeof value === 'string' ? value.split(',') : value;

    setSelectedCountries(_newCountries.slice(0, 3));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      setErrors({});

      loading.show();

      const formData = new FormData(e.target);

      const values = {};

      formData.forEach((v, k) => values[k] = v);

      if (values.targetedCountries)
        values.targetedCountries = values.targetedCountries.split(",");

      const { value, error } = EducationInfoSchema.validate(values);

      if (error) {
        setErrors(error.details.reduce((acc, curr) => {
          acc[curr.path.toString()] = true;
          return acc;
        }, {}));

        console.error(error)
      } else {
        await updatedEducationInfo(value);

        if (await isBrowserSupported()) {
          navigate(`/home`);
        } else {
          navigate("/unsupported");
        }
      }
    } catch (error) {
      console.error(error);
      snackbar.error("Uh Oh! Unable to save your details")
    } finally {
      loading.hide();
    }
  };

  React.useEffect(() => {
    if (!type) navigate("/")
  }, [type, navigate]);

  return type ? (
    <ContentShell>
      <Paper sx={{ width: 'fit-content', mx: 'auto', maxWidth: 550 }}>
        <Box
          display="flex" mx='auto' py={2} px={'5vw'}
          flexDirection="column" component="form" onSubmit={handleSubmit}
        >
          <Typography fontSize={18} fontWeight={700} textAlign="center" mb={1}>
            We need some info about your goals to give better Questions and
            Feedback
          </Typography>
          <Divider light />
          <br />
          <RadioOptions
            name="course"
            error={errors['course']}
            label="Program/Course Targeted*"
            options={["Bachelors", "Masters", "MBA", "PhD"]}
          />
          <MultipleSelect
            name="targetedCountries" options={Countries} onChange={handleChange}
            value={selectedCountries} label={"Targeted Country*"}
            error={errors['targetedCountries']}
          />
          <Typography fontSize={10} alignSelf="right" mt={-1} marginLeft="auto">
            Pick upto 3 countries
          </Typography>
          <Box display="flex" alignItems="center">
            <Select
              name="academics" options={Boards} label="Academics*"
              error={errors['academics']}
            />
            &nbsp;&nbsp;
            <Select
              name="workExperience" options={WorkExperiences}
              label="Select Work Experience*"
              error={errors['workExperience']}
            />
          </Box>
          <Select
            name={`examTime`} options={ExamTimes}
            label={`When your are planning to give your ${type.toUpperCase()} exam?*`}
            error={errors[`examTime`]}
          />
          <RadioOptions
            name="targetIntake" label="Target Intake*"
            options={["Fall 2023", "Spring 2023", "Summer 2023", "2024"]}
            error={errors[`${type}Registered`]}
          />
          <br />
          <Button
            variant="contained" type="submit"
            sx={{ width: 'fit-content', alignSelf: 'center' }}
          >
            Submit
          </Button>
          <Typography
            variant='caption' color="#00000099" mt={4} textAlign="center"
          >
            By continuing, you agree to our&nbsp;
            <Typography
              component="a" variant='caption' color="#02569D" target="_blank"
              href="https://languify.in" style={{ textDecoration: 'none' }}
              rel="noreferrer"
            >
              Terms & Conditions
            </Typography>
          </Typography>
        </Box >
      </Paper>
    </ContentShell >
  ) : null;
}

export default EducationInfo;