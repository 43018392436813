import React from 'react';

import TimeIcon from '@mui/icons-material/AccessTime';
import SpeakerIcon from '@mui/icons-material/VolumeUp';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import CountDown from 'components/CountDown';

export const getInstructions = (totalQuestions) => ({
  "LISTENING": [
    "This section measures your ability to understand conversations and academic lectures in English.",
    "Before clicking on the next button, make sure you are wearing good-quality headphones.",
    <>
      By clicking on this icon, you can adjust the audio volume:
      <IconButton sx={{ p: 0, m: 0, ml: 0.5 }}>
        <SpeakerIcon style={{ fontSize: "0.8em" }} color="primary" />
      </IconButton>
    </>,
    "For each listening passage, you will have limited time to answer MCQs; indicated by a timer on the top right corner of the screen.",
    "In response to each reading or listening passage, you will be asked several questions. Answer each question based on what is stated or implied by the speakers. Answer each question before moving on to the next.",
    <><b>Note: The listening passage will be played only once. It cannot be replayed. It is suggested that while listening to the questions, you may take notes and use them to answer the corresponding questions.</b></>,
  ],
  "WRITING": [
    "This section measures your ability to write in English to communicate in an academic environment.",
    <>
      There are two types of writing questions in the writing section:
      <li>For question 1, you will read a passage and listen to a lecture about the same topic. You may take notes while you read and listen. Then you will write a response to a question based on what you have read and heard. You may look back at the passage when answering the question. You may use your notes to help you answer the question. Typically, an effective response will be 150 to 225 words.</li>
    </>,
  ],
  "READING": [
    "You will be tested on your ability to understand academic passages in English in this section.",
    "Reading passages are excerpts from university-level textbooks that would be used in introductions to a topic.",
    "You will be able to view the reading passage alongside the questions for that particular passage.",
    "Use time wisely and complete the reading assessment on time.",
    "Stay on this screen at all times. You cannot pause the timer for your next session.",
  ],
  "SPEAKING": [
    "This section measures your ability to speak on independent topics and summarize the content in the listening passage provided in the English language",
    "Before we start, please ensure you are seated in a noise-free environment with good-quality headphones to ensure proper recording of your speaking task answer.",
    <><b>Note: Your answer cannot be recorded again, so speak loudly and make the most of the time you have.</b></>,
    <><b>You have 30 seconds to prepare your response </b></>,
    `There are ${totalQuestions} questions in this section. For each question, you will be given a short time to prepare your response. When the preparation time is up, a beep sound will be made to indicate that the recording has begun. Answer the question as per the limited time indicated for that question.`,
    "While preparing your responses, you may take notes. If you need help preparing your response, you may refer to your notes.",
  ],
});

export function ListItem(props) {
  return (
    <Typography variant="body2" mt={1} color="#535353" {...props} display="inline-flex">
      <span
        style={{
          marginTop: 6, marginRight: 16, backgroundColor: "#02569d",
          padding: 4, height: 8, borderRadius: "50%"
        }}
      />
      <span style={{ display: "inline-block" }}>{props.children}</span>
    </Typography>
  );
}

function SectionInstructions(
  { active, section = 1, name = "", title = "", time, onNext, type, totalQuestions }
) {
  const totalTime = React.useMemo(() => 300, []);
  const instructions = React.useMemo(
    () => getInstructions(totalQuestions), [totalQuestions]
  );

  return active ? (
    <Box
      display="flex" width="100%" height="fit-content" py={2} px={6}
      justifyContent="center" position="relative" mx="auto" maxWidth={650}
      flexDirection="column" backgroundColor="white.main" borderRadius={2}
    >
      <Box style={{
        position: 'absolute', top: "-24px", textAlign: "center",
        width: '-webkit-fill-available',
      }}>
        <Typography style={{
          fontSize: 14, fontWeight: 500, color: "white",
        }}
        >
          Test will start in:
          <CountDown
            totalTime={totalTime} variant="caption"
            style={{ fontSize: 14, fontWeight: 500, }} onTimeout={onNext}
          />
          minutes
        </Typography>
      </Box>
      <Typography variant="overline" fontWeight="bold" color="#838383">
        Section&nbsp;{section}
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        {title}
      </Typography>
      <Box display="flex" alignItems="center" mt={1}>
        <TimeIcon fontSize="small" color="disabled" />
        <Typography
          variant="caption" component="p" ml={1} color="dimgrey"
          fontWeight={700}
        >
          Test Time:&nbsp;
          <span style={{ color: "#02569d" }}>
            {Math.round(time / 60)} minutes
          </span>
        </Typography>
      </Box>
      {instructions?.[name.toUpperCase()]?.map((instruction, i) => (
        <ListItem key={i}>{instruction}</ListItem>
      ))}
      {time && (
        <ListItem>
          In total, you have <b>{Math.round(time / 60)} minutes</b> to complete this section.
        </ListItem>
      )}
      <br /><br />
      <Button variant="contained" onClick={onNext}>Next</Button>
    </Box>
  ) : null;
}

export default SectionInstructions;