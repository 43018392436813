import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";

const sx = {
    profileContainer: {
        border: "0.5px solid #02569D",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px",
        margin: "40px 0px"
    },
    label: {
        fontSize: "12px",
        fontWeight: 500,
        color: '#626060'
    }
};

export default function Details({ active }) {

    const details = useStore((state) => state.details);

    return (
        active ? (
            <Box sx={sx.profileContainer}>
                <Typography variant="fontFamily"
                    sx={{
                        fontSize: "18px",
                        fontWeight: 500,
                    }}>
                    {details?.name}<br />
                    <span style={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: '#626060'
                    }}>{details?.email}</span>
                </Typography>
                <CenterFlexBox flexDirection='column' alignItems='flex-start' gap={1}>
                    <Typography variant="fontFamily" sx={sx.label}>
                        Test type : {details?.testType}
                    </Typography>
                    <Typography variant="fontFamily" sx={sx.label}>
                        Date : {details?.date}
                    </Typography>
                    <Typography variant="fontFamily" sx={sx.label}>
                        Attempt Id : {details?.attemptId}
                    </Typography>
                </CenterFlexBox>
            </Box>
        ) : null
    );
}