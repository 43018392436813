import Box from "@mui/material/Box";
import IELTSSidebar from "./IELTS/Sidebar";
import TOEFLSidebar from "./TOEFL/Sidebar";

export default function ReportShell({ children, ielts, SidebarProps = {} }) {
    return (
        <Box
            display='flex'
            height={'calc(100vh - 80px)'}
            width="-webkit-fill-available"
        >
            {ielts ?
                <IELTSSidebar {...SidebarProps} /> :
                <TOEFLSidebar {...SidebarProps} />
            }

            <Box width='-webkit-fill-available'>
                {children}
            </Box>
        </Box>
    );
}