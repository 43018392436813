import React from 'react';
import { styled } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const DarkTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#235598", fontSize: 10
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontWeight: 500, fontSize: 12, backgroundColor: "#235598", color: "white"
  }
}));

export const LightTooltip = styled(({ className, title, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={
      <Typography
        sx={{
          fontFamily: "Inter", fontStyle: "normal", fontWeight: 400,
          fontSize: "13px", textAlign: "center", py: 1
        }}
      >
        {title}
      </Typography>
    }
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#FFFFFF",
    color: 'black',
    maxWidth: 215,
    fontSize: 10,
    borderRadius: '4px',
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.25)",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#FFFFFF",
  }
}));

export const WarningTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.errorLight.main,
    fontSize: 8
  },
  [`& .${tooltipClasses.tooltip}`]: {
    fontWeight: 500,
    fontSize: 12,
    backgroundColor: theme.palette.errorLight.main,
    color: "white"
  }
}));