import React, { useEffect } from 'react';

import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from "@mui/material/Typography";

import Button from 'components/Button';
import CenterFlexBox from "components/CenterFlexBox";
import { DarkTooltip, LightTooltip, WarningTooltip } from 'components/Tooltip';
import beep from 'utils/beep';
import RecordingIndicator from './RecordingIndicator';

export function LargeIconButton({
  children, showProgress, showTooltip, progress, allottedTime, isPercentage,
  ...props
}) {
  const value = Math.round((progress / allottedTime) * 100);

  let tip;

  if (isPercentage) {
    tip = `${progress}%`;
  } else {
    const min = Math.floor(progress / 60);
    const sec = progress % 60;
    tip = progress >= 60 ? `${min}m ${sec}s` : `${sec}s`;
  }

  const warningState = progress < 10;

  const Tooltip = React.useMemo(
    () => warningState ? WarningTooltip : DarkTooltip, [warningState]
  );

  return (
    <Tooltip
      arrow title={tip} open={!!showTooltip} placement="right"
    >
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {!!showProgress && (
          <CircularProgress
            variant={progress >= 0 ? "determinate" : "indeterminate"}
            size={72} thickness={2} value={value}
            color={warningState ? "errorLight" : "primary"}
          />
        )}
        <Box
          sx={{
            top: 0, left: 0, bottom: 0, right: 0, width: 60, margin: '6px',
            background: '#2355982E', borderRadius: '50%', position: "absolute",
            display: "flex", alignItems: "center", justifyContent: "center",
            fontSize: 12, textAlign: 'center', color: "#235598",
            fontWeight: 500, lineHeight: '12px',
          }}
        >
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
}

const PreparationTime = 15;
const SpeakingTime = 15;

function RecordQuestion({ active, question, onFinished, speechRecorder, start }) {
  const questionTimeTakenRef = React.useRef(0);
  const [questionState, setQuestionState] = React.useState("LOADED");
  const [timeLeft, setTimeLeft] = React.useState(0);

  const preparationTime = question?.question?.preparationTime || PreparationTime;
  const speakingTime = question?.question?.time || SpeakingTime;

  const submitQuestion = () => setQuestionState("SUBMITTED");

  const handleSpeechSuccess = React.useCallback((result) => {
    const answer = {
      ...question.answer,
      attempt: 'attemptId',
      questionType: question.type,
      timeTaken: question.answer.timeTaken,
      question: question.question,
      audioBlob: result.audioBlob,
      answer: result.transcript,
    };

    question.answer = answer;

    onFinished();
  }, [question, onFinished]);

  const handleRecordAnswer = React.useCallback(() => {
    setQuestionState('RECORDING');

    questionTimeTakenRef.current = Date.now();

    speechRecorder.current?.start();

    beep();
  }, [speechRecorder]);

  useEffect(() => {
    if (questionState === "WAITING") {
      setTimeLeft(question?.question?.preparationTime || PreparationTime);
    }
    if (questionState === "SUBMITTED") {
      const timeTaken = Math.round(
        (Date.now() - questionTimeTakenRef.current) / 1000
      );

      question.answer.submitted = true;
      question.answer.timeTaken = timeTaken;

      speechRecorder.current?.stop(handleSpeechSuccess);
    }
    if (questionState === "RECORDING") {
      setTimeLeft(speakingTime);
    }
  }, [questionState])


  useEffect(() => {
    let id;
    if (timeLeft >= 0 && ["RECORDING", "WAITING"].includes(questionState)) {
      id = setTimeout(() => setTimeLeft((timeLeft) => timeLeft - 1), 1000);
    } else {
      if (questionState === "RECORDING") submitQuestion();
      if (questionState === "WAITING") handleRecordAnswer();
    }

    return () => clearTimeout(id);
  }, [timeLeft])

  useEffect(() => {
    if (start) setQuestionState("WAITING");
  }, [start, question]);

  return active ? (
    <Box width="100%">
      <Typography mt={1} fontSize={16} fontWeight={700}
        dangerouslySetInnerHTML={{ __html: question?.question?.content }}
      />
      <Typography mt={1} fontSize={13} color="#11000099" fontWeight={500}>
        &#x2022;&nbsp;&nbsp;Preparation Time:&nbsp;{preparationTime}&nbsp;seconds
        <br />
        &#x2022;&nbsp;&nbsp;Speaking Time:&nbsp;{speakingTime}&nbsp;seconds
      </Typography>
      <br /><br /><br /><br /><br />

      {["WAITING", "RECORDING"].includes(questionState) && (
        <LightTooltip
          title={`Start speaking after the beep sound played in ${preparationTime} secs`}
          open={questionState === "WAITING"}
          placement="top"
        >
          <CenterFlexBox column>
            {"WAITING" === questionState && (
              <>
                <LargeIconButton
                  showTooltip
                  showProgress
                  progress={timeLeft}
                  allottedTime={preparationTime}
                  style={{ color: "white", backgroundColor: "#235598" }}
                >
                  Prepare Answer
                </LargeIconButton>
                <Button
                  onClick={handleRecordAnswer}
                  style={{ marginTop: 16 }}
                  variant="contained"
                >
                  Start Now
                </Button>
              </>
            )}

            {"RECORDING" === questionState && (
              <>
                <LargeIconButton
                  showTooltip
                  showProgress
                  progress={timeLeft}
                  allottedTime={speakingTime}
                  title="Submit Answer"
                  onClick={submitQuestion}
                  style={{ color: "white", backgroundColor: "#235598" }}
                >
                  Speak!
                </LargeIconButton>
                <Button
                  onClick={submitQuestion}
                  style={{ marginTop: 16 }}
                  variant="contained"
                >
                  I'm done
                </Button>
              </>
            )}
          </CenterFlexBox>
        </LightTooltip>
      )}
      <RecordingIndicator state={questionState} />
    </Box>
  ) : null;
};

export default RecordQuestion;