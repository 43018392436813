import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles({
  paragraph: {
    overflow: 'auto', maxHeight: '40vh', padding: "0px 8px", fontSize: 13,
    height: '-webkit-fill-available'
  },
});

function Passage({ active, noScroll, children, ...props }) {
  const classes = useStyles();

  return active ? (
    <Box {...props}>
      <Box sx={{
        p: 3, background: '#F1F3F4', border: '1px solid #00000099',
        borderRadius: 2,
      }}>
        <Typography
          variant="body2" className={classes.paragraph}
          maxHeight={(noScroll) ? '100% !important' : ''}
          dangerouslySetInnerHTML={{ __html: children }}
        />
      </Box>
    </Box>
  ) : null;
}

export default Passage;