import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  textArea: {
    fontSize: 12, fontFamily: 'Inter', fontWeight: "normal",
    letterSpacing: "inherit",
    borderRadius: "6px",
    outlineColor: "#02569D",
    boxSizing: "content-box",
    background: "#F1F2F6",
    height: "100%",
    display: "block",
    minWidth: "200px",
    width: "-webkit-fill-available",
    resize: "none",
    padding: "8px 16px",
    borderWidth: 0,
  },
}));

function TextArea(props) {
  const classes = useStyles();

  return (
    <textarea className={classes.textArea} {...props}></textarea>
  );
}

export default TextArea;