import React from 'react';
import shallow from 'zustand/shallow';

import NextIcon from '@mui/icons-material/NextPlan';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import Button from 'components/Button';
import { analyseSection, analyseSpokenAnswer } from 'services/analysisService';
import useStore from 'utils/toeflStore';

function SavingAnswers({
  active, questions, onSaved
}) {
  const [progress, setProgress] = React.useState(0);
  const [errors, setErrors] = React.useState([]);
  const [retry, setRetry] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [analysed, setAnalysed] = React.useState(-1);

  const [section, attempt, sections, mockType] = useStore(state => [
    state.section, state.attempt, state.sections, state.mockType
  ], shallow);


  const sectionName = sections?.[section]?.name?.toUpperCase();

  React.useEffect(() => {
    if (active) {
      setAnalysed(0);
      setProgress(0);

      if (sectionName !== "SPEAKING") {
        setErrors([false]);
        setTotal(1);

        const answers = questions.map(q => ({
          questionType: q.type,
          answer: q.answer.value,
        }));

        const progressInterval = setInterval(() => {
          setProgress((p) => {
            if (p >= 90) {
              clearInterval(progressInterval);
              return p;
            } else return p + Math.round(Math.random() * 10);
          });
        }, 1000);

        analyseSection(
          {
            section, attempt, mockType, answers,
            mockSection: sections?.[section]?._id,
          },
          (event, data) => {
            if (event === 'analysed') {
              setProgress(100);
              onSaved();
            }
            if (event === 'error') {
              setProgress(100);
              console.error("ERROR_SAVING_ANSWER:", data);
              setErrors([data]);
            }
          }
        );
      } else {
        const answers = questions.map(q => q.answer);

        const totalAnswers = answers.length;

        setErrors(new Array(answers.length).fill(false));

        setAnalysed(0);

        setTotal(totalAnswers);

        answers.forEach((answer, i) => {
          let progress = 0;

          answer.section = section;
          answer.attempt = attempt;
          answer.mockType = mockType;
          answer.mockSection = sections?.[section]?._id;
          answer.serialNumber = i;

          analyseSpokenAnswer(answer, (event, data) => {
            if (event === 'progress') {
              const inc = data - progress;

              setProgress(p => p + ((inc) / totalAnswers));

              progress += inc;
            }
            if (event === 'analysed') {
              setProgress(p => p + ((100 - progress) / totalAnswers));

              setAnalysed(a => a + 1);
            }

            if (event === 'error') {
              console.error("ERROR_SAVING_ANSWER:", data);
              setErrors((errs) => {
                const newErrors = [...errs];
                newErrors[i] = data;

                return newErrors;
              });
            }
          });
        });
      }
    }
  }, [active, retry]);

  React.useEffect(() => {
    if (analysed === total && !!!errors.find(e => e !== false)) {
      setProgress(100);
      onSaved();
    }
  }, [analysed, total, errors, onSaved]);

  return active ? (
    <Box
      width="100%" height="fit-content"
      justifyContent="center" position="relative" mx="auto" maxWidth={550}
      flexDirection="column" backgroundColor="white.main" borderRadius={2}
      py={3} px={6} alignItems="center" display="flex"
    >
      {(!!errors.find(e => e !== false)) ? (<>
        <Typography fontWeight={600} mb={1}>Upload Failed</Typography>
        <Typography variant="caption" color="#00000099">
          There was an error while uploading your answers. Kindly check your
          network connection and try again, otherwise you will not be evaluated
          for this section.
        </Typography>
        <Box display="flex" alignItems="center" mt={4} width="100%">
          <Button
            variant="outlined"
            color="error"
            onClick={onSaved}
            startIcon={<NextIcon />}
            style={{ padding: "4px 12px", width: "50%" }}
          >
            Proceed to Next Section
          </Button>
          <Button
            style={{ padding: "5px 12px", width: "50%" }}
            variant="contained"
            color="primary"
            onClick={() => {
              setRetry(r => r + 1);
              setErrors([]);
              setAnalysed()
            }}
            startIcon={<RefreshIcon />}
          >
            Try Again
          </Button>
        </Box>
      </>) : (<>
        <Typography fontWeight={600} mb={1}>You answers are being saved!</Typography>
        <Typography variant="caption" color="#00000099">
          If you leave or refresh this page now, your answer will not be saved.
          Hold on until the save is complete. Thank you for your patience!
        </Typography>
        <br /><br />
        <Typography
          variant="caption" color="primary" alignSelf="start" fontWeight={600}
        >
          {`${Math.round(progress)}`}<span style={{ fontSize: "70%" }}>%</span>
        </Typography>
        <Box flexGrow={1} width="100%">
          <LinearProgress
            color="primary"
            variant={progress === 100 ? "indeterminate" : "determinate"}
            value={progress < 100 ? progress : undefined}
            sx={{
              height: 32, borderRadius: 0, backgroundColor: "#D9D9D9",
            }}
          />
        </Box>
      </>)}
    </Box>
  ) : null;
}

export default SavingAnswers;