import React from 'react';
import Box from '@mui/material/Box';
import SectionHeader from 'components/IELTSMock/SectionHeader';
import SectionFooter from 'components/IELTSMock/SectionFooter';
import Timer from 'components/IELTSMock/Timer';

function SectionShell(
  { active, timerProps = {}, headerProps = {}, footerProps = {}, ...props }
) {
  return active ? (
    <Box sx={{
      mx: 'auto', width: "100%", minWidth: 800, height: "100vh",
      display: "flex", flexDirection: "column"
    }}
    >
      <SectionHeader {...headerProps} >
        <Timer {...timerProps} />
      </SectionHeader>
      <Box
        display="flex" pt={2} alignItems="center"
        flexDirection="column"
        justifyContent={"center"}
        flex="1 1 auto"
        overflow="auto"
        minHeight="0px"
      >
        {props.children}
      </Box>
      {!footerProps.hide && <SectionFooter {...footerProps} />}
    </Box>
  ) : null;
}

export default SectionShell;