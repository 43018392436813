import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import parseFeatures from "utils/parser";
import useStore from "utils/reportStore";

export default function CommonObservations(
    { title, commonObservations = null, ...props }
) {
    const section = useStore((state) => state.section);
    const sectionName = useStore((state) => state.sections[section]?.name?.toUpperCase());

    let features = [];

    if (section !== -1) features = parseFeatures(commonObservations, sectionName);

    return (
        (commonObservations) ?
            <Box {...props} >
                <Typography
                    fontFamily='inter'
                    fontSize={20} fontWeight={600} mb={1}
                >
                    {title}
                </Typography>
                <Box display={'flex'} flexWrap='wrap' rowGap={1} columnGap={2}>
                    {
                        features.map((feature, index) => (
                            <CenterFlexBox
                                p={1} my={1} key={index}
                                border='2px solid #02569D'
                                borderRadius='4px'
                                fontSize='14px'
                                fontFamily='inter'
                                color="#02569D"
                                width="fit-content"
                                fontWeight={500}
                            >
                                <Box
                                    width={10} height={10} borderRadius={10}
                                    backgroundColor={feature?.color}
                                >
                                </Box> &nbsp;&nbsp;
                                {feature?.value}
                            </CenterFlexBox>
                        ))
                    }
                </Box>
            </Box>
            : null
    );
}