import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import SectionHeader from 'components/TOEFLMock/SectionHeader';
import Timer from 'components/TOEFLMock/Timer';

function SectionShell(
  { active, timerProps = {}, sectionHeadProps = {}, ...props }
) {
  return (
    <Box sx={{
      width: 'fit-content', mx: 'auto', maxWidth: "90%", minWidth: 1024,
      display: active ? 'block' : 'none', maxHeight: "70vh",
    }}
    >
      <Paper sx={{
        minHeight: "60vh", maxHeight: "70vh", pt: 2, pb: 3, px: 4,
        position: 'relative'
      }}
      >
        <Timer {...timerProps} />
        <SectionHeader {...sectionHeadProps} />
        <Box display="flex" flexGrow={1} py={2} alignItems="stretch" overflow='hidden'>
          {props.children}
        </Box>
      </Paper>
    </Box>
  );
}

export default SectionShell;