import React from "react";
import { useLocation } from "react-router-dom";


const BADGES = {
    'inprep': {
        imgUrl: "https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=419506&theme=light",
        url: "https://www.producthunt.com/posts/inprepai?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-inprepai"
    },
    'teachAndTrain': {
        imgUrl: "https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=409722&theme=light&period=daily",
        url: "https://www.producthunt.com/posts/languify?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-languify"
    },
};

export default function ProductHuntBadge() {
    const location = useLocation();

    const Badge = React.useMemo(() => (
        location.pathname === '/inprep' ?
            BADGES['inprep'] :
            BADGES['teachAndTrain'])
        , [location]
    );

    return (
        <>
            <a
                href={Badge.url} target="_blank" rel="noreferrer">
                <img
                    src={Badge.imgUrl}
                    alt="Languify - Make&#0032;every&#0032;student&#0032;&#0038;&#0032;teacher&#0032;feel&#0032;like&#0032;a&#0032;superhero | Product Hunt"
                    width="250" height="54"
                />
            </a>
        </>
    );
}