
import Button from "@mui/material/Button";
import CenterFlexBox from "components/CenterFlexBox";
import useStore from "utils/reportStore";

function GradeReportButton({ active, ielts, ...props }) {

  const setSection = useStore((state) => state.setSection);

  const handleClick = () => {
    if (!active) setSection(-1);
  }

  return (
    <CenterFlexBox {...props}>
      <Button
        variant={(active) ? "outlined" : "contained"}
        style={{
          padding: '6px 8px', fontFamily: 'inter',
          fontSize: 15, fontWeight: 500, textTransform: 'none'
        }}
        onClick={handleClick}
      >
        {ielts ? "IELTS" : "TOEFL"}&nbsp;Grade Report
      </Button>
    </CenterFlexBox>
  );
}

export default GradeReportButton;