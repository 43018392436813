import React from 'react';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import AudioPassage from 'components/AudioPassage';
import Button from 'components/Button';
import QuestionProgress from 'components/QuestionProgress';
import SectionChip from 'components/SectionChip';
import MCQ from 'components/MCQ';
import SCQ from 'components/SCQ';
import SectionInstructions from 'components/TOEFLMock/SectionInstructions';
import Timer from 'components/TOEFLMock/Timer';
import useStore from 'utils/toeflStore';

function ListeningSection({ onTimeout }) {
  const [current, setCurrent] = React.useState(0);
  const [subSection, setSubSection] = React.useState(0);
  const [timeForQuestions, setTimeForQuestions] = React.useState(0);

  const passagesPlayed = React.useRef(new Set());

  const {
    passages, questions, time, name
  } = useStore(state => state.sections[state.section]);
  const sectionName = name?.toUpperCase();
  const currentQuestion = useStore(state => state.currentQuestion);
  const setCurrentQuestion = useStore(state => state.setCurrentQuestion);
  const nextQuestion = useStore((state) => state.next);
  const prev = useStore((state) => state.prev);
  const openDialog = useStore((state) => state.openSubmitSectionDialog);

  const lastQuestion = React.useMemo(
    () => currentQuestion + 1 === questions.length, [currentQuestion, questions]
  );

  const question = questions[currentQuestion];

  const passageQuestions = React.useMemo(() => {
    return questions.filter(q => q.passage === question.passage);
  }, [question.passage, questions]);

  const currentPassageQuestion = React.useMemo(() => {
    const currentPassage = questions[currentQuestion].passage;

    if (currentPassage === 0) return currentQuestion;

    return currentQuestion - questions.slice(0, currentQuestion).filter(
      q => q.passage !== currentPassage
    ).length;
  }, [currentQuestion, questions]);

  const onAudioCompleted = () => setSubSection(1);

  const handleNext = () => setCurrent(curr => curr + 1);

  const handleNextQuestion = React.useCallback(() => {
    nextQuestion();

    const nextQuesAudio = questions[currentQuestion + 1]?.passage;

    if (questions[currentQuestion].passage !== nextQuesAudio &&
      !passagesPlayed.current.has[+nextQuesAudio]
    ) {
      passagesPlayed.current.add(nextQuesAudio);
      setSubSection(0);
    }
  }, [currentQuestion, questions, nextQuestion]);

  const handlePrevQuestion = React.useCallback(() => {
    const prevQuesAudio = questions[currentQuestion - 1]?.passage;

    if (questions[currentQuestion].passage !== prevQuesAudio &&
      !passagesPlayed.current.has[+prevQuesAudio]
    ) {
      passagesPlayed.current.add(prevQuesAudio);
      setSubSection(0);
    }
    prev();
  }, [currentQuestion, questions, prev]);

  const handleTimeout = () => {
    if (question.passage + 1 >= passages.length) onTimeout();
    else {
      const questionIndex = questions.findIndex(q => q.passage === question.passage);

      setCurrentQuestion(questionIndex + passageQuestions.length);

      handleNextQuestion();
    }
  };

  React.useEffect(() => {
    if (passageQuestions.length > 0) {
      setTimeForQuestions(
        passages[question.passage].timeForQuestions
      );
    }
  }, [passageQuestions]);

  return (
    <div>
      <SectionInstructions
        type="toefl"
        active={current === 0}
        name='LISTENING' title='Listening Comprehension' section={3} time={time}
        onNext={handleNext}
      />
      {current === 1 && (
        <Box sx={{
          width: 'fit-content', mx: 'auto', maxWidth: "90%", minWidth: 1024,
          maxHeight: "70vh",
        }}
        >
          <Paper sx={{
            minHeight: "60vh", maxHeight: "70vh", pt: 2, pb: 3, px: 4,
            position: 'relative'
          }}
          >
            <Timer
              timerText="Time remaining:"
              hideTimer={subSection !== 1}
              onTimeout={handleTimeout}
              totalTime={timeForQuestions}
            />
            <Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <SectionChip name={sectionName} hideInfoIcon />
                {subSection === 1 && (
                  <DialogActions>
                    {question?.passage === questions?.[currentQuestion - 1]?.passage && (
                      <Button
                        variant="contained" style={{ padding: "8px 16px" }}
                        onClick={handlePrevQuestion}
                        disabled={subSection !== 1 || currentQuestion === 0}
                      >
                        Previous
                      </Button>
                    )}
                    <Button
                      variant="contained" style={{ padding: "8px 16px" }}
                      onClick={lastQuestion ? openDialog : handleNextQuestion}
                    >
                      {lastQuestion
                        ? "Submit"
                        :
                        question?.passage !== questions?.[currentQuestion + 1]?.passage
                          ?
                          "Next Passage" :
                          "Next"
                      }
                    </Button>

                  </DialogActions>
                )}
              </Box>
              <Typography fontSize={20} fontWeight={500} ml={1}>
                {currentPassageQuestion + 1}/{passageQuestions.length}
              </Typography>

              <QuestionProgress
                current={currentPassageQuestion} questions={passageQuestions}
              />
            </Box>

            <Box py={2}>
              <AudioPassage
                audioPassage={passages[question.passage]}
                active={current === 1 && subSection === 0}
                playAudio={sectionName === 'LISTENING' && current === 1 && subSection === 0}
                onCompleted={onAudioCompleted}
              />
              {(current === 1 && subSection === 1 && question.type === 'scq') && (
                <SCQ
                  active
                  index={currentQuestion}
                  currentQuestion={currentQuestion}
                  question={question}
                />
              )}
              {(current === 1 && subSection === 1 && question.type === 'mcq') && (
                <MCQ
                  active
                  currentQuestion={currentQuestion}
                  question={question}
                />
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </div >
  );
}

export default ListeningSection;