import React from 'react';
import SpeechRecorder from "languify-speech-recorder";
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MicNoneIcon from '@mui/icons-material/MicNone';
import Typography from "@mui/material/Typography";
import CenterFlexBox from "components/CenterFlexBox";
import MicrophoneTestErrorDialog from "dialogs/MicrophoneTestError";
import { LightTooltip } from 'components/Tooltip';
import { LargeIconButton } from './RecordQuestion';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "column",
    display: "flex",
    height: "100vh",
    // backgroundColor: "#202124",
    position: "relative",
  },
  assessmentContainer: {
    padding: 32,
    margin: "auto",
    zIndex: 100,
    minHeight: 420,
    width: "90vw",
    minWidth: 300,
    maxWidth: 600,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 7px rgba(0,0,0,0.25)",
    position: "relative",
    backgroundColor: "white",
  },
  button: {
    marginTop: 8,
    textTransform: "none"
  },
  active: {
    background: "#9FE2BF !important",
  },
  tag: {
    borderRadius: '4px',
    padding: 8,
    fontSize: 13,
    fontFamily: "Inter",
    fontWeight: 500,
    margin: 0,
    background: "#D9D9D9",
    textTransform: "capitalize",
    transition: "all 500ms ease-in-out",
  },
  iconButton: {
    width: 60,
    height: 60,
    padding: '12px',
    boxShadow: '0 0 7px rgba(0,0,0,0.25)',
    marginLeft: '16px',
    marginRight: '16px',
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    fontSize: "3em",
    border: "none",
    transition: "all 300ms linear",
    "&:disabled": {
      boxShadow: '0 0 2px rgba(0,0,0,0.25)',
      cursor: "not-allowed",
      opacity: 0.7,
    },
    "&:hover:enabled": {
      backgroundColor: theme.palette.primary.dark,
    },
    "&:active:enabled": {
      backgroundColor: theme.palette.primary.dark,
      boxShadow: "0 0 15px 0px rgba(0,0,0,0.35)",
    },
  },
  uploadIcon: {
    animationName: "$blink",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    transformOrigin: "50% 100%",
  },
  "@keyframes blink": {
    "0%": { opacity: 1 },
    "50%": { opacity: 1 },
    "100%": { opacity: 0 },
  }
}));

const DefaultMatches = [
  { "word": "monday", "matched": false },
  { "word": "tuesday", "matched": false },
  { "word": "wednesday", "matched": false },
  { "word": "thursday", "matched": false },
  { "word": "friday", "matched": false },
  { "word": "saturday", "matched": false },
  { "word": "sunday", "matched": false }
];

function MicrophoneTest({ active, onSuccess }) {
  const classes = useStyles();

  const [state, setState] = React.useState();
  const [matches, setMatches] = React.useState(DefaultMatches);
  const [retake, setRetake] = React.useState(false);
  const [unMatched, setUnMatched] = React.useState(true);
  const [timeLeft, setTimeLeft] = React.useState(0);
  const speechRecorder = React.useRef(null);

  const [tooltipShowed, setTooltipShowed] = React.useState({
    retake: false,
    submit: false,
    timer: false,
    answer: false,
  });

  React.useEffect(() => {
    if (speechRecorder.current === null) {
      const isMobile = false;
      speechRecorder.current = new SpeechRecorder(
        { isMobile, onInterimTranscript: onTranscriptAvailable }
      );
    }

    return () => speechRecorder?.current?.destructor();
  }, []);

  const onTranscriptAvailable = (transcript) => {
    if (transcript.trim().length) {
      const t = transcript.toString().toLowerCase();

      const tempMatches = matches.map(match => {
        return {
          word: match.word,
          matched: t.search(match.word) !== -1,
        }
      });
      setMatches(tempMatches);
    }
  };

  const handleRecordAnswer = async () => {
    setTimeLeft(30);
    setRetake(false);
    setMatches(DefaultMatches);
    setState("RECORDING");
    await speechRecorder.current?.start();
  };

  const handleSubmit = (alreadyMatched) => {
    setState("SUBMITTED");
    speechRecorder.current?.stop();
    if (unMatched && !alreadyMatched) {
      setRetake(true);
      return;
    }

    speechRecorder?.current?.destructor();

    setTimeout(() => {
      onSuccess && onSuccess();
    }, 500);
  };

  React.useEffect(() => {
    let toUpdate;
    switch (state) {
      case "RECORDING": {
        toUpdate = "answer";
        setTimeLeft(30);
        break;
      }
      case "RETAKE": {
        toUpdate = "retake";
        setTimeLeft(30);
        break;
      }
      case "SUBMITTED": {
        toUpdate = "submit";
        break;
      }
      default:
    }

    if (toUpdate) {
      setTooltipShowed((tooltipShowed) =>
        ({ ...tooltipShowed, [toUpdate]: true })
      );
    }
  }, [state]);

  React.useEffect(() => {
    let id;

    if (["RECORDING", "RETAKE"].includes(state)) {

      if (timeLeft > 0)
        id = setTimeout(() => setTimeLeft(timeLeft => timeLeft - 1), 1000);
      else handleSubmit();

      return () => clearTimeout(id);
    }
  }, [timeLeft]);

  React.useEffect(() => {
    if (["RECORDING", "RETAKE"].includes(state)) {
      for (const match of matches) {
        if (!match.matched) {
          setUnMatched(true);
          return;
        }
      }

      setUnMatched(false);
      handleSubmit(true);
    }
  }, [matches]);

  React.useEffect(() => {
    if (active) setTimeout(() => setState("LOADED"), 400);
  }, [active]);

  return active ? (
    <Box className={classes.root}>
      <Box className={classes.assessmentContainer}>
        <Typography fontSize={18} fontWeight={600}>
          Microphone Test
        </Typography>
        <Typography fontSize={13} color="#00000099" fontWeight={500} mb={1}>
          Name all the days in a week
        </Typography>
        <Divider sx={{ borderBottomWidth: 2 }} />
        <Box flexGrow={1} px={4} pt={2}>
          <div
            style={{
              display: "flex", gap: 16, flexWrap: "wrap",
              justifyContent: "center", width: "100%", marginTop: 16,
            }}
          >
            {matches.map((match, index) => (
              <p
                size="small"
                key={index}
                className={classNames({
                  [classes.active]: !!match.matched,
                  [classes.tag]: true,
                })}
              >
                {match.word}
              </p>
            ))}
          </div>
        </Box>

        {/* Controls */}
        <CenterFlexBox
          column
          my={1}
          px={4}
        >
          {state === "LOADED" && (
            <LightTooltip
              title={"Press the microphone icon to start speaking."}
              open={state === "LOADED" && !tooltipShowed.answer}
              placement="top"
            >
              <IconButton
                className={classes.iconButton}
                color="primary"
                size="large"
                style={{ color: "white", backgroundColor: "#235598" }}
                onClick={handleRecordAnswer}
              >
                <MicNoneIcon />
              </IconButton>
            </LightTooltip>
          )}

          {["RECORDING", "RETAKE"].includes(state) && (
            <LightTooltip
              title={"Auto proceeds once the words turn green"}
              open={!tooltipShowed.submit}
              placement="top"
            >
              <Box>
                <LargeIconButton
                  showTooltip
                  showProgress
                  progress={timeLeft}
                  allottedTime={30}
                  title="Speak!"
                  style={{ color: "white", backgroundColor: "#D9D9D9" }}
                >
                  <MicNoneIcon color="primary" />
                </LargeIconButton>
              </Box>
            </LightTooltip>
          )}
        </CenterFlexBox>
      </Box>
      <MicrophoneTestErrorDialog open={retake} onRetake={handleRecordAnswer} />
    </Box>
  ) : null;
};

export default MicrophoneTest;