import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import { SectionColors } from "./SectionChip";

const useStyles = makeStyles({
    container: {
        height: 250, minWidth: 500, display: 'flex', gap: 4, alignItems: 'center'
    },
    bar: {
        width: '100%', height: 30, borderRadius: 4, position: 'relative'
    },
    contentContainer: {
        position: 'absolute', left: 0, alignItems: 'center', flexDirection: 'column',
        display: 'flex', justifyContent: 'center', width: '100%',
    },
    details: {
        width: 130, height: 'fit-content', display: 'flex',
        flexDirection: 'column', alignItems: 'center', gap: 2
    }
});

function Description({ name, detail, time, color }) {
    const classes = useStyles();

    return (
        <Box className={classes.details} >
            <Typography
                fontFamily='inter' fontSize={15}
                fontWeight={600} color={color}
            >
                {name}
            </Typography>
            <Typography
                fontFamily='inter' fontSize={12}
                fontWeight={500} color='black'
            >
                ({detail})
            </Typography>
            <Typography
                fontFamily='inter' fontSize={12}
                fontWeight={400} color='#535353'
            >
                Duration : {Math.round((time?.[(time?.length - 1) || 0] || 0) / 60)}mins
            </Typography>
        </Box>
    );
}

const getDetail = ({ mockType, name, passages = [], parts = [], questions = [] }) => {
    return mockType === "ielts" ?
        `${parts?.length || 0} Parts, ${questions.length || 0} Ques` :
        ["READING", "LISTENING"].includes(name.toUpperCase()) ?
            `${passages?.length || 0} Passage, ${questions?.length || 0} Ques`
            :
            `${questions?.length || 0} Questions`;
};


export default function SectionDetails({ sections = [], ...props }) {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.container} {...props}>
                {
                    sections.map((section, index) => {
                        const placeInTop = (index % 2 === 0);
                        const sectionName = section.name.toUpperCase();
                        const detail = getDetail(section);
                        const { color, backgroundColor } = SectionColors[sectionName];

                        return sectionName !== "BREAK" ? (

                            <Box
                                className={classes.bar} key={index}
                                backgroundColor={backgroundColor}
                            >

                                {
                                    (placeInTop) ?
                                        <Box
                                            className={classes.contentContainer}
                                            color={backgroundColor}
                                            sx={{ top: -82 }}
                                        >
                                            <Description
                                                name={section?.name}
                                                detail={detail}
                                                time={section?.time}
                                                color={color}
                                            />
                                            <ArrowDropUpOutlinedIcon color='inherit' fontSize="large" />
                                        </Box> :
                                        <Box
                                            className={classes.contentContainer}
                                            color={backgroundColor}
                                            sx={{ bottom: -82 }}
                                        >
                                            <ArrowDropDownOutlinedIcon color="inherit" fontSize="large" />
                                            <Description
                                                name={section?.name}
                                                detail={detail}
                                                time={section?.time}
                                                color={color}
                                            />
                                        </Box>

                                }

                            </Box>
                        ) : null
                    })
                }
            </Box>
        </>
    );
}