import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

import Button from 'components/Button';
import ContentShell from 'components/ContentShell';
import useIELTSStore from 'utils/ieltsStore';
import useTOEFLStore from 'utils/toeflStore';
import { createMockAttempt, getMockAttemptById } from 'services/attemptService';
import { useLoading, useSnackbar } from 'contexts';
import SectionDetails from 'components/SectionDetails';
import Session from 'utils/Session';

const PrivacyPolicy = "https://assets.languify.in/api-docs/Languify-Privacy-Policy.pdf";

const getInstructions = (type, isApiRequest, parts = []) => [
  {
    content: <>The purpose of the <b>{type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Mock </b> is to evaluate the English proficiency of people whose native language is not English.</>,
  },
  {
    content: <>The purpose of the <b>{type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Speaking test</b> is to evaluate English proficiency in terms of summarizing listening and reading passages in speech format along with independent speech topics.</>,
    section: "SPEAKING",
  },
  {
    content: <>The purpose of the <b>{type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Writing test</b> is to evaluate English proficiency in terms of independent and integrated writing tasks.</>,
    section: "WRITING",
  },
  {
    content: <>The <b>{type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Reading section</b> assesses how well you can read and understand materials used in an academic environment.</>,
    section: "READING",
  },
  {
    content: <>The <b>{type.toUpperCase()} {(isApiRequest) ? '' : 'Languify'} Listening section</b> is designed to measure your ability to understand conversations and lectures in English.</>,
    section: "LISTENING",
  }
];

function Text(props) {
  return (
    <Typography variant="body2" mt={2} color="#535353" {...props} />
  );
}

function Intro(props) {
  const navigate = useNavigate();
  const loading = useLoading();
  const snackbar = useSnackbar();
  const { type } = useParams();
  const [searchParams] = useSearchParams();

  const setTOEFLMock = useTOEFLStore(state => state.setMock);
  const toeflSections = useTOEFLStore(state => state.sections);
  const toeflTime = useTOEFLStore(state => state.totalTime);
  const toeflReferralCode = useTOEFLStore(state => state.referralCode);
  const toeflInitTest = useTOEFLStore(state => state.initTest);

  const setIELTSMock = useIELTSStore(state => state.setMock);
  const ieltsSections = useIELTSStore(state => state.sections);
  const ieltsTime = useIELTSStore(state => state.totalTime);
  const ieltsReferralCode = useIELTSStore(state => state.referralCode);
  const ieltsInitTest = useIELTSStore(state => state.initTest);

  const sections = type === "ielts" ? ieltsSections : toeflSections;
  const time = type === "ielts" ? ieltsTime : toeflTime;
  const referralCode = type === "ielts" ? ieltsReferralCode : toeflReferralCode;
  const initTest = type === "ielts" ? ieltsInitTest : toeflInitTest;

  const isApiRequest = searchParams.get('api');

  const handleClick = async () => {
    try {
      loading.show();

      const attemptId = searchParams.get('aid');

      if (!type) {
        navigate("/", { replace: true });
        return;
      }

      if (!attemptId) {
        const attempt = await createMockAttempt({
          mockSections: sections.map((section) => section._id),
          type, referralCode
        });

        initTest({ attempt: attempt._id });
      }

      navigate(`/${type}/assessment${(isApiRequest) ? '?api=true' : ''}`, { replace: true });
    } catch (error) {
      console.error(error);
      snackbar.error(error.message);
    } finally {
      loading.hide();
    }
  };

  React.useEffect(() => {
    (async () => {
      try {
        loading.show();

        const attemptId = searchParams.get('aid');

        if (!type)
          navigate("/", { replace: true });
        else if (sections.length === 0 && !attemptId)
          navigate("/choose-test?t=".concat(type), { replace: true });

        if (attemptId) {
          const { _id, mockSections, user } = await getMockAttemptById(attemptId, false);

          localStorage.setItem('guestId', user.toString());

          const mock = {
            mockType: type,
            sections: mockSections,
            duration: mockSections.reduce((acc, section) => acc + section.duration, 0),
            completedSections: 0
          };

          if (type === 'toefl') setTOEFLMock(mock);
          else if (type === 'ielts') setIELTSMock(mock);

          initTest({ attempt: _id });
        }

      } catch (error) {
        console.error(error);
        snackbar.error(error.message);
      } finally {
        loading.hide();
      }
    })();
  }, [type]);


  return type ? (
    <ContentShell
      light
      hideLogo={isApiRequest}
    >
      <Box
        display="flex" width="100%" alignItems="center" height="fit-content"
        justifyContent="center" position="relative" mx="auto" maxWidth={1200}
        flexDirection="column"
      >
        <Box
          display="flex" width="100%" alignItems="flex-start" p={2}
        >
          <Box width={560} >
            <Typography variant="body1" fontWeight="bold">
              A few things before you start :
            </Typography>
            <br />
            <ul style={{ margin: 0, padding: 0, marginLeft: 10 }}>
              {getInstructions(type, isApiRequest, sections).map((instruction, i) => (
                <li key={i} style={{ marginBottom: 16, color: "#02569D" }}>
                  <Text key={i} component="span">
                    {instruction.content}
                  </Text>
                </li>
              ))}
            </ul>
            <Text>You will get <b>{Math.floor(time / 60)}mins</b> to complete the test.</Text>
            <Text><b>Note: Please open this test in the Chrome browser only.</b></Text>
          </Box>
          <Box width={600} pl={8}>
            <Typography variant="h5">Hi there,</Typography>
            <Text>
              Welcome to the online {type.toUpperCase()} Diagnostic test

              {
                !isApiRequest && <> powered by&nbsp;
                  <span style={{ color: "#02569D", fontWeight: 'bold' }}>
                    Languify!
                  </span></>
              }
            </Text>
            <Text>
              The assessment asses your &nbsp;
              {sections.map((sec, i) => (i === sections.length - 1) ? `${sec?.name} ` : `${sec?.name}, `)}
              skills. Give your best!
            </Text>
            <SectionDetails sections={sections} />
            <br />
            <Box textAlign="center">
              <Button
                variant="contained" onClick={handleClick}
                style={{ padding: "8px 64px", fontWeight: 600, fontSize: 16 }}
                endIcon={<ArrowRightAltIcon color="white" />}
              >
                Let's get started
              </Button>
              <br /> <br />
              {!isApiRequest &&
                <Typography variant='caption' color="#00000099" fontWeight={500}>
                  By clicking Let's get started you accept&nbsp;
                  <Typography
                    component="a" variant='caption' color="#02569D" target="_blank"
                    href={PrivacyPolicy} style={{ textDecoration: 'none' }}
                    rel="noreferrer"
                  >
                    terms and conditions
                  </Typography>
                </Typography>}
            </Box>
          </Box>
        </Box>

      </Box >
    </ContentShell >
  ) : null;
}

export default Intro;