import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Break from 'components/Break';
import ContentShell from 'components/ContentShell';
import SavingAnswers from 'components/TOEFLMock/SavingAnswers';
import ListeningSection from "components/TOEFLMock/Listening";
import ReadingSection from "components/TOEFLMock/Reading";
import SpeakingSection from "components/TOEFLMock/Speaking";
import WritingSection from "components/TOEFLMock/Writing";
import SubmitSectionDialog from 'dialogs/TOEFLSubmitSection';
import useStore from 'utils/toeflStore';
import Session from 'utils/Session';
import { finishMockAttempt } from 'services';
import { endTest } from './IELTSMock';
import { exitFullScreen, fullScreen } from 'utils';

const getQuestionDefaultAnswer = (type, options = []) => {
  if (type === 'mcq') return [];
  if (type === 'scq') return -1;
  if (type === 'drag_and_drop') return new Array(options.length).fill(-1);
  return '';
};

function TOEFLMock(props) {
  const navigate = useNavigate();
  const [savingAnswers, setSavingAnswers] = React.useState(false);
  const [searchParams] = useSearchParams();

  const mockType = useStore((state) => state.mockType);
  const attempt = useStore((state) => state.attempt);
  const section = useStore((state) => state.section);
  const sections = useStore(state => state.sections);
  const nextSection = useStore(state => state.nextSection);
  const closeDialog = useStore(state => state.closeSubmitSectionDialog);
  const reset = useStore((state) => state.reset);

  const { questions } = sections?.[section] || {};
  const name = useStore(state => state.sections[state.section]?.name?.toUpperCase());

  React.useEffect(() => {
    if (questions && questions.length) {
      questions.forEach(q => {
        q.answer = {
          questionType: q.type,
          value: getQuestionDefaultAnswer(q.type, q.options)
        };
      });
    } else if ((name !== 'BREAK') && !sections.length) {
      if (Session.isLoggedIn()) navigate("/home");
      else navigate("/choose-test?t=toefl");
    }
  }, [questions, name, section, sections]);

  React.useEffect(() => {
    window.onbeforeunload = () => "All progress will be lost. Are you sure?";

    return () => window.onbeforeunload = null;
  }, []);

  React.useLayoutEffect(() => fullScreen(), []);

  const onSaved = async () => {
    if (section + 1 < sections.length) {
      nextSection();
      setSavingAnswers(false);
    } else {
      try {
        if (attempt) await finishMockAttempt(attempt);
      } catch (error) { console.error(error) }

      exitFullScreen();

      const isApiRequest = searchParams.get('api');
      if (isApiRequest) {
        navigate("/end-assessment?&api=true", { replace: true });
      } else {

        if (Session.isLoggedIn()) {
          endTest(navigate);
        } else {
          navigate(`/toefl/registration-info?aid=${attempt}`, { replace: true });
        }

      }

      reset();
    }
  };

  const handleSubmitSection = () => {
    setSavingAnswers(true);
    closeDialog();
  };

  const sectionProps = { onTimeout: handleSubmitSection };

  return (
    <ContentShell hideLogo>
      {(!savingAnswers && name === 'BREAK') && (<Break />)}
      {questions?.length > 0 && (
        <>
          {(!savingAnswers && name === 'READING') && (<ReadingSection {...sectionProps} />)}
          {(!savingAnswers && name === 'LISTENING') && (<ListeningSection {...sectionProps} />)}
          {(!savingAnswers && name === 'SPEAKING') && (<SpeakingSection {...sectionProps} />)}
          {(!savingAnswers && name === 'WRITING') && (<WritingSection {...sectionProps} />)}
        </>
      )}
      {savingAnswers && (
        <SavingAnswers
          active questions={questions} onSaved={onSaved} section={section}
          attempt={attempt} mockType={mockType} sections={sections}
        />
      )}
      <SubmitSectionDialog
        section={name} onSubmit={handleSubmitSection}
      />
    </ContentShell >
  );
}

export default TOEFLMock;