import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AudioPlayer from 'components/AudioPlayer';

function AudioPassage({ audioPassage, active, playAudio, onCompleted }) {
  const imageUrl = audioPassage.imageUrl || 'https://assets.languify.in/images/audio_passage_image.png';
  const audioUrl = audioPassage.audioUrl;

  return active ? (
    <Box
      display="flex" flexDirection="column" alignItems="center" width="100%"
    >
      <Typography>
        Tip: One can note down important points while listening to this audio passage
      </Typography>
      <br />
      <img src={imageUrl} alt="Listen-To-Audio" height={220} />
      <br />
      {(audioUrl && playAudio) && (
        <AudioPlayer
          audioUrl={audioUrl} play={playAudio} onCompleted={onCompleted}
        />)}
    </Box>
  ) : null;
}

export default AudioPassage;