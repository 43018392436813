import React from 'react';
import MCQ from 'components/MCQ';
import SCQ from 'components/SCQ';
import Passage from 'components/Passage';
import SectionInstructions from 'components/TOEFLMock/SectionInstructions';
import SectionShell from 'components/TOEFLMock/SectionShell';
import useStore from 'utils/toeflStore';
import DragAndDrop from 'components/DragAndDrop';

function ReadingSection({ onTimeout }, ref) {
  const [current, setCurrent] = React.useState(0);

  const { questions = [], passages = [], time = 0 } =
    useStore(state => state.sections[state.section] || {});
  const currentQuestion = useStore(state => state.currentQuestion);

  const question = questions?.[currentQuestion] || {};

  const handleNext = () => setCurrent(1);

  return (
    <div ref={ref}>
      <SectionInstructions
        type="toefl"
        active={current === 0}
        name='READING' title='Reading Comprehension' section={4} time={time}
        onNext={handleNext}
      />
      <SectionShell
        active={current === 1}
        timerProps={{ totalTime: time, onTimeout }}
      >
        <Passage
          active
          title="Read the passage below and answer the following question:"
          width="50%" mr={2}
        >
          {passages[question.passage]?.content}
        </Passage>
        {question.type === 'scq' && (
          <SCQ
            active
            ml={2}
            width="50%"
            index={currentQuestion}
            currentQuestion={currentQuestion}
            question={question}
          />
        )}
        {question.type === 'drag_and_drop' && (
          <DragAndDrop
            active
            width="50%" ml={2}
            currentQuestion={currentQuestion}
            question={question}
          />
        )}
        {question.type === 'mcq' && (
          <MCQ
            active
            width="50%" ml={2}
            currentQuestion={currentQuestion}
            question={question}
          />
        )}
      </SectionShell>
    </div>
  );
}

export default React.forwardRef(ReadingSection);