import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';

const useStyles = makeStyles({
  circle: {
    background: "#0099ff", borderRadius: "50%", position: "absolute",
    left: 10, top: 10
  },
  static: { zIndex: 4, position: 'absolute' },
  first: { animation: "$ripple-first 2s infinite", zIndex: 3, opacity: 0.5 },
  second: {
    opacity: 0.3, animation: "$ripple-second 2s infinite", zIndex: 2,
  },
  third: {
    opacity: 0.1, animation: "$ripple-third 2s infinite", zIndex: 1,
  },
  "@keyframes ripple-first": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.2)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes ripple-second": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.35)" },
    "100%": { transform: "scale(1)" },
  },
  "@keyframes ripple-third": {
    "0%": { transform: "scale(1)" },
    "50%": { transform: "scale(1.50)" },
    "100%": { transform: "scale(1)" },
  }
});

function Blinker({ on, children, ...props }) {
  const classes = useStyles();
  const childContainerRef = React.useRef();
  const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
  const [styles, setStyles] = React.useState({ width: 0, height: 0 });

  React.useLayoutEffect(() => {
    const { width, height } = window.getComputedStyle(childContainerRef.current);
    setDimensions({ width, height })
    setStyles({ width: `calc(${width} - 20px)`, height: `calc(${height} - 20px)` });
  }, [children]);

  return (
    <Box {...props} position="relative" style={dimensions}>
      {on && (
        <>
          <div className={classNames(classes.circle, classes.first)} style={styles} />
          <div className={classNames(classes.circle, classes.second)} style={styles} />
          <div className={classNames(classes.circle, classes.third)} style={styles} />
        </>
      )}
      <div className={classes.static} ref={childContainerRef}>
        {children}
      </div>
    </Box>
  );
}

export default Blinker;