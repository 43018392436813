import create from "zustand";

const defaults = {
  sections: [],
  section: 0,
  submitSectionDialog: false,
  currentQuestion: 0,
  attempt: null,

  //  For timer
  timeLeft: 0,
  totalTime: 0,
  playing: false,
  stopped: false,

  isTimeout: false,
  uploadProgress: 0,
  referralCode: null,
  mockType: null,
};

const useStore = create((set, get) => ({
  ...defaults,
  setQuestions: (questions) => set({ questions }),
  setSection: (section) => set({ section }),
  nextSection: () => set((state) => {
    const totalSections = state.sections.length;

    if ((state.section + 1) === totalSections) state.endTest();

    return {
      section: Math.min(state.section + 1, totalSections), submitSectionDialog: false,
      currentQuestion: 0,
    };
  }),
  setMock: (mock) => set({
    mockType: mock.mockType,
    sections: mock.sections,
    totalTime: mock.duration,
    section: mock?.completedSections ? mock.completedSections.length : 0,
  }),
  setReferralCode: (referralCode) => set({ referralCode }),
  setCurrentQuestion: (currentQuestion) => set({ currentQuestion }),
  openSubmitSectionDialog: () => set({ submitSectionDialog: true }),
  closeSubmitSectionDialog: () => set({ submitSectionDialog: false }),
  setAttempt: (attempt) => set({ attempt }),
  setQuestionResults: (questionResults) => set({ questionResults }),

  // For timer
  setTotalTime: (totalTime) => set({ totalTime }),
  setTimeLeft: (timeLeft) => set({ timeLeft }),
  start: (totalTime) => set({
    timeLeft: totalTime,
    totalTime,
    isTimeout: false,
    playing: true,
    stopped: false,
  }),
  pause: () => set({ playing: false }),
  resume: () => set({ playing: true }),
  stop: () => set({ stopped: true }),

  setIsTimeout: (isTimeout) => set({ isTimeout }),
  prev: () => set(state => ({
    currentQuestion: Math.max(state.currentQuestion - 1, 0),
  })),
  next: () => set(state => ({
    currentQuestion: Math.min(state.currentQuestion + 1, state.sections[state.section].questions.length),
  })),
  initTest: ({ attempt }) => set({ attempt }),
  endTest: () => { },
  reset: () => set(defaults),
}),
);

export default useStore;