import React from 'react';
import Button from 'components/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTransition from 'components/DialogTransition';
import useStore from 'utils/toeflStore';

export default function SubmitSectionDialog({ section, onSubmit }) {
  const isOpen = useStore(state => state.submitSectionDialog);
  const close = useStore(state => state.closeSubmitSectionDialog);

  return (
    <Dialog
      keepMounted open={isOpen} TransitionComponent={DialogTransition}
      onClose={close} maxWidth="lg"
    >
      <DialogContent sx={{ px: 6 }}>
        Do you want to submit the {section} section?<br />
        You will not be able to attempt this section again.
      </DialogContent>
      <DialogActions sx={{ px: 6 }}>
        <Button
          variant="contained" onClick={onSubmit} sx={{ flexGrow: 1 }}
        >
          Submit
        </Button>
        <Button variant="outlined" onClick={close} sx={{ flexGrow: 1 }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}