import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MCQ from 'components/MCQ';
import SCQ from 'components/SCQ';
import Button from 'components/Button';
import Passage from 'components/Passage';
import SectionInstructions from 'components/IELTSMock/SectionInstructions';
import SectionShell from 'components/IELTSMock/SectionShell';
import useStore from 'utils/ieltsStore';
import PartDescription from 'components/PartDescription';
import QuestionsContainer from 'components/IELTSMock/QuestionsContainer';
import FIB from 'components/FIB';

export const PartsNavigation = () => {
  const { questions = [] } = useStore(
    state => state.sections?.[state.section] || {}
  );
  const currentQuestion = useStore(state => state.currentQuestion);
  const setCurrentQuestion = useStore((state) => state.setCurrentQuestion);
  const setPartIndex = useStore((state) => state.setPartIndex);

  const onSelect = (pIndex, qIndex) => {
    setPartIndex(pIndex);
    setCurrentQuestion(qIndex);
  };

  return (
    <Stack direction='row' alignItems="center" flexWrap="wrap">
      {questions?.map((q, j) => (
        <React.Fragment key={j}>
          {q.part !== questions?.[j - 1]?.part && (
            <Typography
              component="span" fontWeight={600} fontSize={'1.6vmin'} flexShrink={0}
              ml={2}
            >
              Part {q.part + 1}
            </Typography>
          )}
          <Button
            variant="contained"
            color={currentQuestion === j ? 'primary' : 'default'
            }
            disableElevation
            key={j}
            style={{
              padding: "1px 10px", minWidth: 'fit-content',
              fontSize: '1.3vmin', flexShrink: 0
            }}
            onClick={() => onSelect(q.part, j)}
          >
            {j + 1}
          </Button>
        </React.Fragment>
      ))}
    </Stack>
  );
};

function ReadingSection({ onTimeout }) {
  const [current, setCurrent] = React.useState(0);

  const { time, parts, questions, groups, duration } = useStore(
    state => state.sections[state.section]
  );
  const currentQuestion = useStore(state => state.currentQuestion);

  const question = React.useMemo(
    () => questions?.[currentQuestion], [currentQuestion, questions]
  );

  const partIndex = React.useMemo(() => question.part, [question]);
  const part = React.useMemo(() => parts?.[question.part], [question, parts]);
  const group = React.useMemo(() => groups?.[question.group], [question, groups]);

  const handleNext = () => setCurrent((x) => x + 1);

  const footerProps = React.useMemo(() => {
    return {
      hide: current === 0,
      children: <PartsNavigation />,
      disableNext: currentQuestion + 1 === questions?.length,
      disablePrev: currentQuestion === 0,
    }
  }, [current, currentQuestion, questions]);

  const groupRange = group?.questionsRange;

  const groupQuestions = questions.slice(groupRange[0] - 1, groupRange[1]);

  return (
    <SectionShell
      active
      timerProps={{
        totalTime: time[current],
        onTimeout: current === 0 ? handleNext : onTimeout
      }}
      headerProps={{ hideFinish: current !== 1 }}
      footerProps={footerProps}
    >
      <SectionInstructions
        type="ielts"
        active={current === 0}
        onNext={() => setCurrent(1)}
        name={"Reading"}
        time={duration}
      />
      {current === 1 && (
        <>
          <PartDescription
            part={partIndex + 1}
            content={part.description}
          />
          <QuestionsContainer>
            <Box
              p={2}
              display="flex" flex="1 1 auto" minHeight="56vh"
              justifyContent="space-between"
            >
              <Passage
                active
                title="Read the passage below and answer the following question:"
                width="50%" mr={2}
              >
                {part?.passage || ""}
              </Passage>
              <Box width="50%" pr={1}>
                {group?.questionsRange && (
                  <Typography fontWeight={600} variant="subtitle1" mb={1}>
                    Questions {groupRange?.join("-")}
                  </Typography>
                )}

                {group?.instruction && (
                  <Typography
                    color="#00000099" mb={2} dangerouslySetInnerHTML={{
                      __html: group?.instruction
                    }}
                  />
                )}

                {(group?.image || group?.content) && (
                  <Box width="100%" border="1px solid grey" m={2}>
                    {group?.content && (
                      <Box
                        width="100%"
                        p={2}
                        dangerouslySetInnerHTML={{
                          __html: group?.content || ""
                        }}
                      />
                    )}

                    {group?.image && (
                      <img
                        alt="Part"
                        src={group?.image}
                        width="100%"
                      />
                    )}
                  </Box>
                )}

                {groupQuestions?.map((question, i) => (
                  <React.Fragment key={i}>
                    {question.type === 'scq' && (
                      <SCQ
                        index={groupRange[0] + i - 1}
                        active width="-webkit-fill-available"
                        currentQuestion={currentQuestion}
                        question={question}
                      />
                    )}
                    {question.type === 'mcq' && (
                      <MCQ
                        index={groupRange[0] + i - 1}
                        active width="-webkit-fill-available"
                        currentQuestion={currentQuestion}
                        question={question}
                      />
                    )}
                    {question.type === 'fib' && (
                      <FIB
                        index={groupRange[0] + i - 1}
                        width="-webkit-fill-available" ml={2}
                        question={question}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Box>
            </Box>
          </QuestionsContainer>
        </>
      )}
    </SectionShell >
  );
}

export default ReadingSection;