import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AppShell from "components/AppShell";
import CenterFlexBox from "components/CenterFlexBox";
import Session from "utils/Session";
import Faq from "components/Dashboard/Faq";
import Performance from "components/Dashboard/Performance";
import { exitFullScreen } from "utils";

const useStyles = makeStyles((theme) => ({
    heading: {
        display: 'flex', justifyContent: 'space-between',
        [theme.breakpoints.down("md")]: {
            flexDirection: "column", alignItems: "baseline",
        }
    },
    welcome: {
        fontFamily: 'inter',
        color: '#ffffff',
        fontSize: "min(3vw,30px)",
        fontWeight: 700,
    },
    emailContainer: {
        fontFamily: 'inter', wordBreak: "break-word", fontSize: "min(2.5vw,18px)",
        fontWeight: 500, color: 'rgba(255, 255, 255, 0.6)'
    }
}));

const MenuItems = [
    { title: "Home", link: "/home" },
    { title: "FAQs", link: "/home/faq" },
];

export default function Dashboard() {
    React.useLayoutEffect(() => exitFullScreen(), []);

    return (
        <Routes>
            <Route path="/" element={<DashboardLayout />}>
                <Route index element={<Performance />} />
                <Route
                    path="faq"
                    element={
                        <React.Suspense fallback={<>...</>}>
                            <Faq />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
}

function DashboardLayout() {
    const classes = useStyles();

    return (
        <AppShell
            hideFooter menuItems={MenuItems} hideAnnouncement
            sx={{ backgroundColor: '#001929' }} placeAtTop
        >
            <Box width={'100%'} px={5}>
                <Box my={2} >
                    <Box className={classes.heading}>
                        <Typography className={classes.welcome} component="h1">
                            Welcome &nbsp;
                            <span style={{ color: '#2DABFF' }}>
                                {Session?.user?.name?.split(" ")?.[0]},
                            </span>
                        </Typography>
                        <CenterFlexBox className={classes.emailContainer}>
                            <EmailOutlinedIcon /> &nbsp;
                            {Session?.user?.email}
                        </CenterFlexBox>
                    </Box> <br />
                    <Divider />
                </Box>
                <br />
                <Outlet />
                <br /><br />
            </Box>
        </AppShell>
    );
}