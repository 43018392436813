import { Box, Typography } from "@mui/material";
import CenterFlexBox from "components/CenterFlexBox";

export function MicrophoneInfo() {

    return (
        <Box
            width={400} height={160}
            borderRadius={6} backgroundColor='#2475B3'
            mt={5}
            position='relative'
            style={{
                backgroundImage: "url('https://assets.languify.in/images/blue-bubbles.svg')",
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'left',
                backgroundPositionY: 'bottom',
            }}
        >
            <img
                src="https://assets.languify.in/images/question-info.svg"
                alt="?"
                width={50}
                style={{ position: 'absolute', top: -30, left: 10 }}
            />

            <CenterFlexBox flexDirection='column' ml={12} pt={3}>
                <Typography
                    fontFamily='inter'
                    fontSize={20} mb={1}
                    fontWeight={600}
                    color='white.main'
                    alignSelf='flex-start'
                >
                    What can you do ?
                </Typography>
                <Typography
                    fontFamily='inter'
                    fontSize={14}
                    fontWeight={400}
                    color='white.main'
                    alignSelf='flex-start'
                >
                    From next time, please ensure to:
                    <ul>
                        <li>Sit in a noise free environment</li>
                        <li>Speak directly in the microphone</li>
                    </ul>
                </Typography>
            </CenterFlexBox>
        </Box>
    );
}

export function InaudibleErrorInfo() {

    return (
        <Box
            width={400} height={160}
            borderRadius={6} backgroundColor='#F55446'
            mt={5}
            position='relative'
            style={{
                backgroundImage: "url('https://assets.languify.in/images/red-bubble.svg')",
                backgroundRepeat: 'no-repeat',
                backgroundPositionX: 'left',
                backgroundPositionY: 'bottom',
            }}
        >
            <img
                src="https://assets.languify.in/images/error-info.svg"
                alt="?"
                width={50}
                style={{ position: 'absolute', top: -30, left: 10 }}
            />

            <CenterFlexBox flexDirection='column' ml={12} pt={2}>
                <Typography
                    fontFamily='inter'
                    fontSize={20}
                    fontWeight={600}
                    color='white.main'
                    alignSelf='flex-start'
                >
                    Oh snap!
                </Typography>
                <br />
                <Typography
                    fontFamily='inter'
                    fontSize={14}
                    fontWeight={400}
                    color='white.main'
                    alignSelf='flex-start'
                >
                    Your response was not recorded properly.
                    Hence you are not evaluated for this question.
                </Typography>
            </CenterFlexBox>
        </Box>
    );
}