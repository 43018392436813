export const IELTSLevels = {
    9: {
        label: 'Expert user',
        description: 'The test taker has fully operational command of the language. Their use of English is appropriate, accurate and fluent, and shows complete understanding.'
    },
    8: {
        label: 'Very good user',
        description: 'The test taker has fully operational command of the language with only occasional unsystematic inaccuracies and inappropriate usage. They may misunderstand some things in unfamiliar situations. They handle complex and detailed argumentation well.'
    },
    7: {
        label: 'Good user',
        description: 'The test taker has operational command of the language, though with occasional inaccuracies, inappropriate usage and misunderstandings in some situations. They generally handle complex language well and understand detailed reasoning.'
    },
    6: {
        label: 'Competent user',
        description: 'The test taker has an effective command of the language despite some inaccuracies, inappropriate usage and misunderstandings. They can use and understand fairly complex language, particularly in familiar situations.'
    },
    5: {
        label: 'Modest user',
        description: 'The test taker has a partial command of the language and copes with overall meaning in most situations, although they are likely to make many mistakes. They should be able to handle basic communication in their own field.'
    },
    4: {
        label: 'Limited user',
        description: "The test taker's basic competence is limited to familiar situations. They frequently show problems in understanding and expression. They are not able to use complex language."
    },
    3: {
        label: 'Extremely limited user',
        description: 'The test taker conveys and understands only general meaning in very familiar situations. There are frequent breakdowns in communication.'
    },
    2: {
        label: 'Intermittent user',
        description: 'The test taker has great difficulty understanding spoken and written English.'
    },
    1: {
        label: 'Non-user',
        description: 'The test taker has no ability to use the language except a few isolated words.'
    },
    0: {
        label: 'Did not attempt the test',
        description: 'The test taker did not answer the questions.'
    },
};

export const Levels = {
    EXPERT: {
        label: 'Expert',
        color: '#41D7A1',
        backgroundColor: '#EDFFF7',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',

    },
    INTERMEDIATE: {
        label: 'Intermediate',
        color: '#FFCC00',
        backgroundColor: '#FFF9ED',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',

    },
    BEGINNER: {
        label: 'Beginner',
        color: '#EE2D2D',
        backgroundColor: '#FFF0F0',
        comment: 'Great work done in the reading, listening and speaking section. However, there is scope of improvement in the writing section. However, there is scope of improvement in the writing section.',
    },
};

export const Sections = {
    "LISTENING": {
        label: 'Listening',
        title: 'Detailed Analysis for the Listening comprehension',
        tipTitle: 'Customised tips for the listening comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "You may take notes while you listen and use the notes to help you answer the questions.",
            "For questions where you need to fill in missing words, make sure the resulting sentence is grammatically accurate.",
            "Be particularly careful about singular and plural forms. ",
            "By and large, you’re expected to give the exact word (s) you heard. ",
            "Make sure you listen to the questions or read them carefully and answer all the points needed."
        ],
    },
    "READING": {
        label: 'Reading',
        title: 'Detailed Analysis for the Reading comprehension',
        tipTitle: 'Customised tips for the reading comprehension test',
        performanceColumns: ['Passage', 'Question Type', 'Status'],
        tips: [
            "You can skip questions and go back to them as long as there is time remaining.",
            "Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor details in the passage.",
            "Use Scanning and Skimming techniques to reduce the time required to complete the session.",
            "Focus on keywords",
        ],
    },
    "WRITING": {
        label: 'Writing',
        title: 'Detailed Analysis for Writing assesment',
        tipTitle: 'Customised tips for the writing comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "You may look back at the passage when answering the question, so you can use it to optimize and write the expected answer.",
            "You may take notes while you read and listen.",
            "Typically, an effective response will contain a minimum of 300 words",
        ],
        parameters: {
            'grammar': 'Grammar',
            'vocabulary': 'Vocabulary',
            'spelling': 'Spelling',
            'phraseRepetition': 'Phrase Repetition',
            'slangs': 'Slangs',
        },
        tags: {
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'overUsedWords': 'Over used words'
        }
    },
    "SPEAKING": {
        label: 'Speaking',
        title: 'Detailed Analysis for Speaking assesment',
        tipTitle: 'Customised tips for the speaking comprehension test',
        performanceColumns: ['Question', 'Question Type', 'Score'],
        tips: [
            "Listen to the text, it will help you understand the context in a better way",
            "Write what you hear, and make a habit to take down the important points you feel",
            "Be clear and loud don't fumble. ",
            "Follow a simple accent while speaking."
        ],
        parameters: {
            'fluency': 'Fluency',
            'delivery': 'Delivery',
            'vocabulary': 'Vocabulary',
            'pauses': 'Pauses',
            'phraseRepeatRating': 'Phrase Repetition',
        },
        tags: {
            "fillerPhrases": "Filler Phrases",
            'fillerWords': 'Filler Words',
            'rareWordShare': 'Rarely used words Share',
            'frequentWordShare': 'Commonly used words share',
            'speechRate': 'Speech rate',
            'breakdown': 'Breakdown'
        }
    }
}

export const Glossaries = [
    {
        word: 'Dylexic Ability',
        meaning: 'The ability to produce and understand the words of a language. Lexical competence is an aspect of both linguistic competence and communicative competence.'
    },
    {
        word: 'Conciseness',
        meaning: 'The quality of being short and clear, and expressing what needs to be said without unnecessary words'
    },
    {
        word: 'Punctuation',
        meaning: 'In simple terms, punctuation marks are a symbol to create and support meaning within a sentence or to break it up.'
    },
    {
        word: 'Grammar',
        meaning: 'the whole system and structure of a language or of languages in general, usually taken as consisting of syntax and morphology (including inflections) and sometimes also phonology and semantics.'
    },
    {
        word: 'Vocablary',
        meaning: 'Vocabulary is the all the language and words either used or understood by a person or group of people.'
    },
    {
        word: 'Coherence',
        meaning: 'The definition of coherence is something logical or consistent and something that makes sense as a whole.'
    },
];