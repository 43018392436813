import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ArrowRight from '@mui/icons-material/East';
import { LightButton } from 'components/Button';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", alignItems: "center", maxWidth: "min(1000px, 100vw)",
    padding: "64px 0px",
    [theme.breakpoints.down("md")]: {
      flexDirection: 'column', alignItems: "center", justifyContent: "center",
    }
  },
  left: {
    position: "relative", width: "50%", marginRight: "2.5vw", height: 320,
    [theme.breakpoints.down("md")]: {
      margin: 32, width: "calc(100% - 32px)",
    }
  },
  right: {
    width: "50%", flexShrink: 0, marginLeft: "2.5vw",
    [theme.breakpoints.down("md")]: {
      padding: 32, width: "100%", margin: 0
    }
  },
}));

function NGO(props) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.left}>
        <Box
          position="absolute" top={16} zIndex={1} bgcolor="subprimary.main"
          width={320} height={320} borderRadius={4}
        />
        <Box
          position="absolute" top={4} zIndex={2} bgcolor="white.main"
          width={320} height={320} borderRadius={4} left={16}
        />
        <img
          src="https://assets.languify.in/images/ngo-1.jpg"
          alt="NGO"
          width={320}
          height={320}
          style={{
            borderRadius: 16,
            position: "absolute",
            left: 20,
            zIndex: 3
          }}
        />
      </Box>
      <Box className={classes.right}>
        <Typography variant="h4" fontWeight={600}>
          Helping Today, Helping Tomorrow
        </Typography>
        <Typography variant="body1" textAlign="justify">
          <Typography
            component="span" color="subprimary.main" fontWeight="bold"
          >
            Languify
          </Typography>
          &nbsp;is proud to support Abhikshamta, a nonprofit organization
          that empowers underprivileged children like Mohini Gupta to succeed
          in their education. Despite facing financial challenges,
          Mohini is a determined 5th-grade student with a passion for learning.
          With your support, we are able to donate to
          <Typography component="span" color="error" fontWeight="bold">
            &nbsp;Abhikshamta
          </Typography>
          , helping children from low-income families access education and
          opportunities. Thank you for being a part of our mission to make
          a difference in the lives of these children.
        </Typography>
        <br />
        <LightButton
          variant="contained" endIcon={<ArrowRight />}
          LinkComponent="a"
          href="http://www.abhikshamta.org/"
          target="_blank"
          rel="noreferrer"
        >
          Learn More
        </LightButton>
      </Box>
    </Box >
  );
}

export default NGO;