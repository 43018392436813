import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomFaqs from "components/CustomFaqs";

const Faqs = [
    {
        question: "Is languify open toefl mock test a full time internship or part time oppurtunity?",
        answers: [" This is a part time oppurtunity where you need to spend a minimum of 30 minutes daily."],
    },
    {
        question: "What is my primary responsibility or work on day to day basis?",
        answers: ["Your primary responsibility is send the referal links in all groups and chats of the respective platforms and youtube channels list that is attached HERE or share it in the places where final year students who wants to go to study abroad are present."],
    },
    {
        question: "How much time will i need to spend for this program ?",
        answers: ["You can spend an average of 30 minutes daily but if you give more time and reffered more students who have taken completed the tests your chances to earn more than 10000INR plus increases gradually."]
    },
    {
        question: "Is it compulsary to bring 2 more campus ambassdors or students who are also part of the affliate program ?",
        answers: ["Yes it is absolutely compulsary for all the campus ambassdors to refer atleast 2 more students for this program. The refer should be from all the existing participants of Languify open mock TOEFL program."]
    },
    {
        question: "when will i get my incentives & can i avail them before ?",
        answers: ["As soon as you qulaify for the incentives the incentives will be shared with you in the end of the financial quater (3 Months)."]
    },
    {
        question: "The platforms mentioned in the JD are the only platforms that i can send the links ",
        answers: ["No thier is no limit of social media platforms or channels you want to send even it is good for you to share it on more platforms as it will increase your chances to earn more."]
    },
    {
        question: "What happens if i am able to get students below 50 ?",
        answers: ["If a ambassdors is unable to get students above 50 he or she will not be eligible for Intersnhip certificate as well as LOR’s."]
    },
    {
        question: "If i have touched more than 100 students and can i get the incentives before ?",
        answers: ["If you have referred more than 100 students and leave the program in the middle after our data analysis of all completed tests for your refered students you will get the benefits at end of the quater only."]
    },
    {
        question: "If the students that i have referred claim that i have completed the tests and they send me some proofs of screenshots or text will they be counted as students who have taken tests ?",
        answers: ["No proofs of screenshots or texts will work as we will track all the students who are coming to take tests from the refered links by the students."]
    },
    {
        question: "Is the program and mock tests only for those who have are going to take TOEFL or are planning to take it ?",
        answers: ["Yes it is primarily for TOEFL aspirants but it is open to everybody who are in the planning phase of thier foreign university prepration."]
    },
    {
        question: " If any dispute arises regrading circulation of links whether it can be for incentives, LORS or certidicates in issuing to the participant who can i claim them ?",
        answers: ["Even if any disputes arises in incentives due to some errors from the student side or Languify’s end the decision of the Languify administration will be considered final."]
    },

];

export default function Faq() {
    return (
        <Box>
            <Typography
                fontFamily='inter'
                fontSize={22}
                fontWeight={500}
                color='white.main'
            >
                ?&nbsp; FAQs
            </Typography>
            <br />
            <CustomFaqs
                faqs={Faqs}
                style={{
                    borderRadius: 8,
                    border: '1px solid white',
                    padding: '0px 40px',
                    color: 'white.main'
                }}
                backgroundColor='#001929 !important'
                color='white.main'
            />
        </Box>
    );
}