import { useCallback, useMemo } from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  otpGroup: {
    display: "flex", width: "100%", maxWidth: 300, columnGap: "10px"
  },
  otpInput: {
    width: "100%",
    border: "none",
    borderRadius: 0,
    height: 32,
    margin: 8,
    fontSize: 12,
    borderBottom: "2px solid #ccc",
    textAlign: "center",
    fontWeight: "bold",
    lineHeight: "1"
  }
});

export const RE_DIGIT = new RegExp(/^\d+$/);

export default function OtpInput({
  value,
  onChange,
  valueLength = 6,
  containerStyles = {}
}) {
  const classes = useStyles();
  const valueItems = useMemo(() => {
    const valueArray = value.split("");
    const items = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push("");
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target) => {
    const nextElementSibling = target.nextElementSibling;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target) => {
    const previousElementSibling = target.previousElementSibling;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  const inputOnChange = useCallback((e, idx) => {
    const target = e.target;
    let targetValue = target.value.trim();

    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== "") {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : " ";

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue);

      if (!isTargetValueDigit) {
        return;
      }

      const nextElementSibling = target.nextElementSibling;

      if (nextElementSibling) {
        nextElementSibling.focus();
      }
      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(value.trim() + targetValue.trim());

      target.blur();
    }
  }, [value, onChange, valueLength]);

  const inputOnKeyDown = (e) => {
    const { key } = e;
    const target = e.target;

    if (key === "ArrowRight" || key === "ArrowDown") {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === "ArrowLeft" || key === "ArrowUp") {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    if (e.key !== "Backspace" || targetValue !== "") {
      return;
    }

    focusToPrevInput(target);
  };

  const inputOnFocus = (e) => {
    const { target } = e;

    const prevInputEl = target.previousElementSibling;

    if (prevInputEl && prevInputEl.value === "") {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <div className={classes.otpGroup} style={containerStyles}>
      {valueItems.map((digit, idx) => (
        <input
          key={idx}
          type="text"
          inputMode="numeric"
          autoComplete="one-time-code"
          pattern="\d{1}"
          maxLength={valueLength}
          className={classes.otpInput}
          onKeyDown={inputOnKeyDown}
          onChange={(e) => inputOnChange(e, idx)}
          onFocus={inputOnFocus}
          value={digit}
        />
      ))}
    </div>
  );
}