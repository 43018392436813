import React from 'react';

import SelectedIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import CenterFlexBox from 'components/CenterFlexBox';
import Passage from './Passage';

function MarkParaQuestion({ options = [], question, index, currentQuestion }) {
  const [selected, setSelected] = React.useState('');

  React.useEffect(() => {
    const v = question?.answer?.value;
    setSelected(v === undefined ? '' : v);
  }, []);

  const toggleSelected = (section) => {
    setSelected(s => {
      const newSelection = section === s ? '' : section;
      question.answer.value = section;

      return newSelection;
    });
  };

  return (
    <div style={{ borderTop: "1px solid black", display: "flex" }}>
      <Box
        display="flex" flexGrow={1} justifyContent="flex-start" p={1}
        flexShrink={1} bgcolor={(currentQuestion + 1) === index ? 'lightgrey' : 'transparent'}
      >
        <div style={{ fontSize: 12 }}>{index}.&nbsp;</div>
        <div
          style={{ fontSize: 12 }}
          dangerouslySetInnerHTML={{ __html: question?.content || "" }}
        />
      </Box>
      {options.map((section, i) => (
        <CenterFlexBox
          key={i}
          onClick={() => toggleSelected(section)}
          flexShrink={0}
          style={{
            width: "48px",
            minHeight: "48px",
            borderLeft: "1px solid black",
            cursor: "pointer"
          }}
        >
          {selected === section ? <SelectedIcon color="success" /> : null}
        </CenterFlexBox>
      ))}
    </div>
  );
}

function MarkParaGroup({ currentQuestion, group = {}, groupRange, groupQuestions = [] }) {
  return (
    <Box display="flex" width="100%" columnGap={2}>
      {(group?.paragraph) && (
        <Passage active width="35%" noScroll>
          {group?.paragraph || ""}
        </Passage>
      )}

      <Box border="1px solid black" mt={1} width="65%" height="fit-content">
        <div style={{ display: "flex" }}>
          <CenterFlexBox flexGrow={1} flexShrink={1} />
          {group.options.map((section, i) => (
            <CenterFlexBox
              key={i}
              flexShrink={0}
              style={{
                width: "48px",
                height: "48px",
                borderLeft: "1px solid black",
              }}
            >
              {section}
            </CenterFlexBox>))
          }
        </div>
        {groupQuestions.map((question, i) => (
          <MarkParaQuestion
            key={i}
            question={question}
            index={groupRange[0] + i}
            options={group.options}
            currentQuestion={currentQuestion}
          />
        ))}
      </Box>
    </Box>
  );
}

export default MarkParaGroup;